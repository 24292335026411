import React from "react";
import { useSelector } from "react-redux";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import BuildIcon from "@mui/icons-material/Build";

import moment from "moment";

const RecipeAuthInfo = ({ date, author, manual }: any) => {
  const user = useSelector((state: any) => state.auth.user);
  return (
    <div className="flex flex-row items-center mt-5">
      <p className="font-normal text-primary text-sm">
        <strong>Created date: </strong>
        {moment(date, "YYYY-MM-DD").format("MMM DD, YYYY").toUpperCase()} |{" "}
        <strong>Author: </strong>{" "}
      </p>
      {/* <img src={user?.picture} alt="" className="w-6 mx-2 rounded-full" /> */}
      <div className="w-6 mx-2 rounded-full bg-slate-600 flex font-bold text-white justify-center items-center"> {author.substring(0, 1)} </div>
      <p className="font-normal text-primary text-sm">{author} | </p>
      {manual ? (
        <BuildIcon
          className="text-[#40506a]/40 mx-2"
          style={{ fontSize: "1.5rem" }}
        />
      ) : (
        <WatchLaterIcon
          className="text-[#40506a]/40 mx-2"
          style={{ fontSize: "1.6rem" }}
        />
      )}
      <p className="font-normal text-primary text-sm">
        {" "}
        {manual ? "Manual" : "Scheduled"} Recipe
      </p>
    </div>
  );
};

export default RecipeAuthInfo;
