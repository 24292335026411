import { CheckboxChecked, Checkbox } from "assets/icons/Icons";
import React, { useState } from "react";

const BooleanField = ({ label, fieldData }: any) => {
  return (
    <div
      className="flex items-center gap-2 my-1"
      onClick={() => {
        fieldData.fieldValues[fieldData.index][fieldData.fieldName] =
          !fieldData.fieldValues[fieldData.index][fieldData.fieldName];
        fieldData.setFieldValues(fieldData.fieldValues);
      }}
    >
      {fieldData?.fieldValues[fieldData.index][fieldData.fieldName] ? (
        <CheckboxChecked />
      ) : (
        <Checkbox />
      )}
      <div className="flex items-center gap-2">
        <label className={"font-normal text-sm cursor-pointer"}>{label}</label>
      </div>
    </div>
  );
};

export default BooleanField;
