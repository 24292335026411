import React from "react";
import { useDispatch } from "react-redux";
import { login } from "../features/auth/authSlice";
import { AppDispatch } from "../app/store";
import GoogleIcon from "@mui/icons-material/Google";
import "react-toastify/dist/ReactToastify.css";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router";
import background from "assets/images/login_background.png";
import productLogoContainer from "assets/images/productLogoContainer.png";
import DocumentTitle from "components/PageHeader/DocumentTitle";

const LoginView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      dispatch(
        login({
          code: codeResponse.code,
          isIncrementalPermissions: false,
          recipe_id: 0,
          scopes: "",
        })
      ).then(() => {
        navigate("/auth");
      });
    },
    flow: "auth-code",
    redirect_uri: "https://developers.google.com/oauthplayground",
  });

  return (
    <div
      className="flex flex-col justify-center max-w-full h-[70vh]"
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <DocumentTitle pageTitle="Login" />
      <div className="h-full w-1/2 flex flex-col items-left justify-center px-20">
        <img src={productLogoContainer} alt="" className="w-[20%] mb-5" />
        <p className="font-bold mb-10 text-5xl">
          Log into your Yuniter Cloud account to connect with your team
        </p>
        <p className="mb-2 text-base">Sign in with your social network</p>
        <div
          onClick={handleLogin}
          className="flex flex-row text-center transition ease-in-out delay-150 bg-[#4C82F7] cursor-pointer hover:bg-secondary text-white font-bold py-3 mt-3.5 w-1/3 px-4 rounded"
        >
          <GoogleIcon className="basis-[10%]" />
          <p className="basis-[90%] mr-[10%]"> Login with Google </p>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
