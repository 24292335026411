import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeader from "components/PageHeader/PageHeader";
import OptionSelector from "components/Common/OptionSelector";
import { DafaultButton } from "components/Common/Button";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UserControl from "components/Roles/UserControl";
import RecipeSetting from "components/Roles/RecipeSetting";
import { getRole, setRoleDetail, updateRole } from "features/roles/roleSlice";
import {
  adminRole,
  managerRole,
  viewerRole,
} from "components/Common/staticData";
import _ from "lodash";
import setLoading from "features/helper";

const RoleSummary = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const role = useSelector((state: any) => state.roles.role);
  const currentUser = useSelector((state: any) => state.auth.user);

  let location: any = useLocation();
  const roleId = location?.state?.id;
  const predefinedRole = location?.state?.predefined;
  const predefinedRoleImg = location?.state?.predefinedImg;

  const [currentRole, setCurrentRole] = useState({
    name: role.name,
    resources: role.resources,
  });

  const nextClick = () => {
    setLoading(true);
    if (predefinedRole) {
      dispatch(setRoleDetail(currentRole.resources)).then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'role_summary',
          'user_id': currentUser.userID,
          'tenant_id': currentUser.tenant
        });
        setLoading(false);
        navigate("/role_create");
      });
    } else {
      dispatch(updateRole({ id: role.id, payload: currentRole })).then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'update_edit_role',
          'role_id': roleId,
          'user_id': currentUser.userID,
          'tenant_id': currentUser.tenant,
          'role_name': currentRole.name
        });
        setLoading(false);
        navigate("/roles");
      });
    }
  };

  useEffect(() => {
    let tempRole = _.cloneDeep(currentRole);
    if (predefinedRole === "Viewer") {
      tempRole.resources = viewerRole;
    } else if (predefinedRole === "Manager") {
      tempRole.resources = managerRole;
    } else if (predefinedRole === "Admin") {
      tempRole.resources = adminRole;
    }
    setCurrentRole(tempRole);
  }, [predefinedRole]);

  return (
    <div className="p-[0_2.5%] w-[82%] ml-[18%] text-primary">
      <PageHeader title="Role Summary" />
      {predefinedRole ? (
        <div className="flex items-center justify-center mt-10">
          <div
            className="flex flex-col items-center justify-center shadow-ingredientCardBoxshadowGray border-ingredientCardBorder border-[0px] 
          rounded-[10px] p-6 w-40 cursor-pointer"
          >
            <img style={{ height: "5.5rem" }} src={predefinedRoleImg} alt="" />
            <p className="font-bold text-lg mt-3">{predefinedRole}</p>
          </div>
        </div>
      ) : (
        <div className="mt-5 mb-10">
          <label className="block text-base font-bold text-primary">
            Name:
          </label>
          <input
            type="text"
            id="first_name"
            className={
              "border-[1px] border-[#667080] rounded-[10px] my-4 w-1/3"
            }
            placeholder="Untitled"
            value={currentRole.name}
            onChange={(e) => {
              let tempRole = _.cloneDeep(currentRole);
              tempRole.name = e.target.value;
              setCurrentRole(tempRole);
            }}
            required
          />
        </div>
      )}
      <UserControl
        currentRole={_.cloneDeep(currentRole)}
        setCurrentRole={setCurrentRole}
        disabled={roleId === undefined}
      />
      <RecipeSetting
        currentRole={_.cloneDeep(currentRole)}
        setCurrentRole={setCurrentRole}
        disabled={roleId === undefined}
      />
      <div className="flex flex-row justify-between w-[72%] my-16">
        <DafaultButton
          text={"Back"}
          outlined
          startIcon={<ArrowBackIcon />}
          radius={"6px"}
          onClick={() => {
            // setFullRecipeDetails();
            navigate(-1);
          }}
        />
        <DafaultButton
          text={predefinedRole ? "Next Step" : "Update Role"}
          endIcon={<ArrowForwardIcon />}
          color="success"
          textColor={"#FFFFFF"}
          width={"12rem"}
          radius={"6px"}
          onClick={() => nextClick()}
          //   disabled={edit}
        />
      </div>
    </div>
  );
};

export default RoleSummary;
