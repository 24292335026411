import React, { useEffect, useState } from "react";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ArticleIcon from '@mui/icons-material/Article';
import RefreshIcon from '@mui/icons-material/Refresh';
import Popup from "components/Common/Popup";
import LogsPopup from "components/Common/LogsPopup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { getSelectedRecipe, getStatus, runRecipe } from "features/recipe/recipeSlice";
import setLoading from "features/helper";
import _ from "lodash";
import { checkRolePermission } from "components/Common/config";

const RecipeInfo = ({
  IscurrentUser,
  name,
  group,
  description,
  onCopy,
  onDelete,
  onEdit,
  recipeID,
  status,
  setFilteredRecipe,
  filteredRecipes,
  recipeLogs,
  showLogsModal,
  logText,
  setShowLogsModal,
  is_rt_tool,
  tasks,
  recipe
}: any) => {
  const dispatch = useDispatch<AppDispatch>();

  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const currentUser = useSelector((state: any) => state.auth.user);
  const recipeFilters = useSelector((state: any) => state?.recipe?.recipeFilters);

  const roleResources = currentUser.role.resources;

  const canEditRecipe = roleResources.Create
    ? roleResources.Create.includes("Edit")
    : false;
  const canDuplicateRecipe = roleResources.Create
    ? roleResources.Create.includes("Duplicate")
    : false;
  const recipeDeletePermissions = roleResources["Delete Recipes"];

  let canDeleteRecipe = checkRolePermission(recipeDeletePermissions, group, IscurrentUser, currentUser)  

  return (
    <div className="flex flex-row items-center justify-between mb-7">
      <div className="flex flex-col">
        <p className="text-primary text-xl font-bold mb-2">
          {/* <strong>Name: </strong> */}
          {name}
        </p>
        <div className="flex flex-row font-normal text-primary text-sm">
          <strong>Group: </strong> &nbsp;
          {group.map((value, index) => (
            <p key={index}>
              {value.name}
              {index + 1 !== group.length ? ", " : " "}
              &nbsp;
            </p>
          ))}
          &nbsp; | &nbsp; <strong>Description: </strong>
          {description}
        </div>
      </div>
      <div>
      <button onClick={() => {        
        setLoading(true);
        dispatch(getSelectedRecipe(recipeID)).then((res) => {
          // let ingredientTitleArr = [];
          // for (let index = 0; index < recipe.ingredients.length; index++) {
          //   ingredientTitleArr.push(recipe.ingredients[index].title);
          // }
          // if((res.payload.status === "FINISHED" && res.payload.percent === 100) || res.payload.status === "ERROR") {
          //   window.dataLayer = window.dataLayer || [];
          //   window.dataLayer.push({
          //     'event': 'run_recipe_ends',
          //     "recipe_id": recipe.id,
          //     "recipe_name": recipe.name,
          //     "run_status": res.payload.status,
          //     "group_name": recipe.group,
          //     "ingredient": ingredientTitleArr,        
          //     "recipe_type": recipe.manual ? 'manual' : 'schedule',
          //     'user_id': currentUser.userID,
          //     'tenant_id': currentUser.tenant
          //   }); 
          // }

          var elementPos = filteredRecipes.map(function(x: any) {return x.id; }).indexOf(recipeID);
          var tempArray = _.cloneDeep(filteredRecipes);
          tempArray[elementPos].percent = res.payload.percent;
          tempArray[elementPos].active = res.payload.active;
          tempArray[elementPos].ago = res.payload.ago;
          tempArray[elementPos].status = res.payload.status;
          setFilteredRecipe(tempArray)
          setLoading(false);
        });
      }}>
        <RefreshIcon 
          className="mr-3 text-primary"
          style={{ fontSize: "1.9rem" }}
        />
      </button>
      <button onClick={() => recipeLogs()} >          
          <ArticleIcon
            className="mr-3 text-primary"
            style={{ fontSize: "1.9rem" }}
          />
        </button>
        <button
          onClick={() => {
            let ingredientTitleArr = [];
            for (let index = 0; index < recipe.ingredients.length; index++) {
              ingredientTitleArr.push(recipe.ingredients[index].title);
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'duplicate_recipe_icon',
              "recipe_id": recipe.id,
              "recipe_name": recipe.name,
              "group_name": recipe.group,
              "ingredient": ingredientTitleArr,
              "recipe_type": recipe.manual ? 'manual' : 'schedule',
              'user_id': currentUser.userID,
              'tenant_id': currentUser.tenant
            });
            setShowCopyModal(true);
          }}
          disabled={!canDuplicateRecipe}
        >
          <FileCopyOutlinedIcon
            className={`mr-3 ${
              !canDuplicateRecipe ? "text-slate-300" : "text-primary"
            }`}
            style={{ fontSize: "1.9rem" }}
          />
        </button>
        <button onClick={onEdit} disabled={!(canEditRecipe && IscurrentUser)}>
          <BorderColorOutlinedIcon
            className={`mr-3 ${
              !(canEditRecipe && IscurrentUser) ? "text-slate-300" : "text-primary"
            }`}
            style={{ fontSize: "1.9rem" }}
          />
        </button>
        <button
          onClick={() => {
            let ingredientTitleArr = [];
            for (let index = 0; index < recipe.ingredients.length; index++) {
              ingredientTitleArr.push(recipe.ingredients[index].title);
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'delete_recipe_icon',
              "recipe_id": recipe.id,
              "recipe_name": recipe.name,
              "group_name": recipe.group,
              "ingredient": ingredientTitleArr,        
              "recipe_type": recipe.manual ? 'manual' : 'schedule',
              'user_id': currentUser.userID,
              'tenant_id': currentUser.tenant
            });
            setShowDeleteModal(true);
          }}
          disabled={!canDeleteRecipe}
        >
          <DeleteOutlineIcon
            className={`${
              !canDeleteRecipe ? "text-slate-300" : "text-primary"
            }`}
            style={{ fontSize: "1.9rem" }}
          />
        </button>
      </div>
      {showLogsModal && (
        <LogsPopup
          setOpenModal={setShowLogsModal}
          text={logText}
          status={status}
          is_rt_tool={is_rt_tool}
          tasks={tasks}
          recipe={recipe}
        />
      )}
      {showCopyModal && (
        <Popup
          setOpenModal={setShowCopyModal}
          text="Duplicate this Recipe?"
          redirect={onCopy}
        />
      )}
      {showDeleteModal && (
        <Popup
          setOpenModal={setShowDeleteModal}
          text="Delete this Recipe?"
          redirect={onDelete}
        />
      )}
    </div>
  );
};

export default RecipeInfo;
