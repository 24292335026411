import React from "react";
import { connect } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = (props: any) => {
  const { component: Component, isAuthenticated, isEditing, ...rest } = props;

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(PrivateRoute);
