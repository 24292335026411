import React, { useState } from "react";
import EastIcon from "@mui/icons-material/East";

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../assets/images", false, /\.(png|jpe?g|svg)$/)
);

const IngredientPlatforms = ({ platformsFrom, platformsTo }: any) => {
  const [hover, setHover] = useState(false);

  return (
    <div className="flex flex-row items-center justify-center">
      {platformsFrom?.map((platform, index) => (
        <div className="flex flex-row mx-3" key={index}>
          <div className="flex flex-row items-center">
            <img
              src={images[`${platform}.png`] || images[`${platform}.svg`]}
              alt={platform}
              height={30}
              width={30}
            />
          </div>
        </div>
      ))}
      <EastIcon />
      {platformsTo?.map((platform, index) => (
        <div className="flex flex-row mx-3" key={index}>
          <div className="flex flex-row items-center">
            <img
              src={images[`${platform}.png`] || images[`${platform}.svg`]}
              alt={platform}
              height={30}
              width={30}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default IngredientPlatforms;
