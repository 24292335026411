import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useLocation, useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import PageHeader from "components/PageHeader/PageHeader";
import { DafaultButton } from "components/Common/Button";
import { ArrowBack } from "@mui/icons-material";
import { ArrowForward } from "@mui/icons-material";
import MultiSelect from "components/Common/MultiSelect";
import Select from 'react-select'
import {
  copyRecipe,
  listRecipes,
  setRecipeDetail,
} from "features/recipe/recipeSlice";
import setLoading from "features/helper";
import { listGroups } from "features/groups/groupSlice";

const CreateRecipe = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  let location: any = useLocation();
  const isCopy = location?.state?.copy;
  const recipeId = location?.state?.id;

  const recipe = useSelector((state: any) => state.recipe.recipe);
  const groupOptions = useSelector((state: any) => state.auth.user.groups);
  const currentUser = useSelector((state: any) => state.auth.user);

  const options = ["Marketing", "Accounting", "Finance"];

  const [recipeName, setRecipeName] = useState(recipe.name);
  const [recipeGroups, setRecipeGroups] = useState(recipe.groups);
  const [recipeDesc, setRecipeDesc] = useState(recipe.description);
  const [isNext, setIsNext] = useState(false);

  const groupOptionsFiltered = groupOptions.map((groups) => {
    return {value: groups.id, label: groups.name};
  });

  useEffect(() => {
    setLoading(true);
    dispatch(listGroups(currentUser.tenant)).then(() => {
        setLoading(false);
    });
  }, []);

  const handleNextButton = () => {
    setIsNext(true);
    if (recipeName !== "" && recipeGroups.length !== 0) {
      setLoading(true);
      if (!isCopy) {
        dispatch(
          setRecipeDetail({
            name: recipeName,
            description: recipeDesc,
            groups: recipeGroups.map((groups) => {
              return {id: groups.value, name: groups.label};
            }),
          })
        ).then(() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'add_recipe_details',
            "recipe_name": recipeName,
            "group_name": recipeGroups.map((groups) => {
              return {id: groups.value, name: groups.label};
            }),
            "description": recipeDesc,
            'user_id': currentUser.userID,
            'tenant_id': currentUser.tenant
          });
          setLoading(false);
          navigate("/recipe_ingredients");
        });
      } else {
        dispatch(
          copyRecipe({
            id: recipeId,
            payload: {
              name: recipeName,
              description: recipeDesc,
              group: recipeGroups.map((groups) => {
                return {value: groups.value, label: groups.label};
              }),
            },
          })
        ).then(() => {
          dispatch(listRecipes(currentUser.tenant)).then(() => {
            let ingredientTitleArr = [];
            for (let index = 0; index < recipe.order.length; index++) {
              ingredientTitleArr.push(recipe.ingredientId[index][recipe.order[index]].title);
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'duplicate_recipe',
              "recipe_id": recipeId,
              "recipe_name": recipeName,
              "group_name": recipeGroups,
              "ingredient": ingredientTitleArr,
              "recipe_type": recipe.manual ? 'manual' : 'schedule',
              'user_id': currentUser.userID,
              'tenant_id': currentUser.tenant
            });
            setLoading(false);
            navigate("/recipes");
          });
        });
      }
    }
  };

  return (
    <div className="p-[0_2.5%] w-[82%] ml-[18%]">
      <PageHeader title={isCopy ? "Duplicate Recipe" : "Create a New Recipe"} />

      <div className="mt-16">
        <label className="block mb-2 text-base font-bold text-primary">
          Name:
        </label>
        <input
          type="text"
          id="first_name"
          className={`bg-gray-50 border border-gray-300 text-primary text-sm rounded-lg block 
          p-2.5 w-1/2`}
          placeholder="Untitled"
          value={recipeName}
          onChange={(e) => {
            setRecipeName(e.target.value);
          }}
          required
        />
        {isNext && recipeName === "" ? (
          <span className="flex items-center font-medium tracking-wide text-red text-xs mb-10 mt-1 ml-1">
            Please provide a valid Recipe Name
          </span>
        ) : (
          <div className="mb-10"></div>
        )}

        <label className="block mb-2 text-base font-bold text-primary">
          Groups:
        </label>
        {/* <MultiSelect
          options={groupOptionsFiltered}
          selected={recipeGroups}
          setSelected={setRecipeGroups}
        /> */}
        <Select 
          options={groupOptionsFiltered} 
          isMulti 
          className="w-1/2" 
          onChange={(e) => {setRecipeGroups(e)}} 
        />
        {isNext && recipeGroups.length === 0 ? (
          <span className="flex items-center font-medium tracking-wide text-red text-xs mb-10 mt-1 ml-1">
            Please select at least 1 Group
          </span>
        ) : (
          <div className="mb-10"></div>
        )}

        <label className="block mb-2 text-base font-bold text-primary">
          Description:
        </label>
        <textarea
          rows={10}
          className="block p-2.5 text-sm text-primary bg-gray-50 rounded-lg border
           border-gray-300 w-2/3  mb-10"
          placeholder="Recipe Description..."
          value={recipeDesc}
          onChange={(e) => {
            setRecipeDesc(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-row justify-between mr-[33%]">
        <DafaultButton
          text="Back"
          startIcon={<ArrowBack />}
          color={"primary"}
          onClick={() => navigate(-1)}
          outlined
        />

        <DafaultButton
          text={isCopy ? "Duplicate Recipe" : "Next Step"}
          endIcon={<ArrowForward />}
          color={"success"}
          onClick={() => handleNextButton()}
          textColor="white"
          width="20rem"
        />
      </div>
    </div>
  );
};

export default CreateRecipe;
