import React from "react";

const PageSubHeader = ({ title }: any) => {
  return (
    <>
      <div className={"flex items-center justify-between"}>
        <div className="text-base my-2 text-primary font-bold">{title}</div>
      </div>
      <hr className="border-b border-primary opacity-40 mb-4 relative" />
    </>
  );
};

export default PageSubHeader;
