import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { RecipeSummaryData } from "components/Common/staticData";
import React, { useState } from "react";

const TextField = ({ label, type = "text", fieldData }: any) => {
  const [error, setError] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  if(type === 'number' && (fieldData?.fieldValues[fieldData?.index][fieldData?.fieldName] === '')) {
    fieldData.fieldValues[fieldData.index][fieldData.fieldName] = null
    fieldData?.setFieldValues(fieldData?.fieldValues);
  }

  return (
    <div className="my-4">
      <div className="flex items-center text-sm my-1">
        <p className="font-semibold mr-2 min-w-max">{label}</p>
        <button
          className="cursor-pointer mr-2"
          onClick={() => setShowDetails(!showDetails)}
        >
          <InfoOutlinedIcon style={{ fontSize: "1rem" }} />
        </button>
        {showDetails ? (
          <p className="font-medium text-[#808080]">{fieldData?.description}</p>
        ) : null}
        {error && <div className="text-red">{RecipeSummaryData.ErrorMsg}</div>}
      </div>
      <div className="flex justify-between gap-2">
        <div className="w-full border border-primary rounded-md px-3 py-1 items-center">
          <input
            value={fieldData?.fieldValues[fieldData?.index][fieldData?.fieldName]}
            onChange={(e) => {
              // e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
              fieldData.fieldValues[fieldData.index][fieldData.fieldName] =
              (type === 'number' && e.target.value !== '') ? +e.target.value : e.target.value;
              fieldData?.setFieldValues(fieldData?.fieldValues);
            }}
            type={type}
            className="w-full border-0 focus:ring-0 text-xs text-primary p-0"
          />
          {error && (
            <div
              className={`bg-red/[0.15] absolute top-0 bottom-0 left-0 right-0`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TextField;
