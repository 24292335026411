import React, { useState } from "react";
import RecipeInfo from "./RecipeInfo";
import RecipeAuthInfo from "./RecipeAuthInfo";
import RecipeInfoCards from "./RecipeInfoCards";
import IngredientList from "./IngredientList";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { toast } from "react-toastify";
import {
  deleteRecipe,
  getRecipe,
  getSelectedRecipe,
  getStatus,
  listRecipes,
  pauseRecipe,
  runRecipe,
} from "features/recipe/recipeSlice";
import setLoading from "features/helper";
import _ from "lodash";

const RecipeCard = ({ recipe, i, setFilteredRecipe, filteredRecipes }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const currentUser = useSelector((state: any) => state.auth.user);
  const recipeFilters = useSelector((state: any) => state?.recipe?.recipeFilters);

  const [showLogsModal, setShowLogsModal] = useState(false);
  const [logText, setLogText] = useState([]);

  let ingredientTitleArr = [];
  for (let index = 0; index < recipe.ingredients.length; index++) {
    ingredientTitleArr.push(recipe.ingredients[index].title);
  }

  const onCopy = () => {
    setLoading(true);
    dispatch(getRecipe(recipe.id)).then(() => {
      setLoading(false);
      navigate("/recipe_details/", { state: { copy: true, id: recipe.id } });
    });
  };

  const onDelete = async () => {
    setLoading(true);
    dispatch(deleteRecipe(recipe.id)).then(() => {
      var tempArray = _.cloneDeep(filteredRecipes);
      tempArray = tempArray.filter(({id}) => id !== recipe.id);
      setFilteredRecipe(tempArray);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'delete_recipe',
        "recipe_id": recipe.id,
        "recipe_name": recipe.name,
        "group_name": recipe.group,
        "ingredient": ingredientTitleArr,
        "recipe_type": recipe.manual ? 'manual' : 'schedule',
        'user_id': currentUser.userID,
        'tenant_id': currentUser.tenant
      });        
      toast.success(`Deleted recipe: ${recipe.name}`, {
        position: "bottom-right",
        autoClose: 8080,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    });
  };

  const onEdit = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'edit_recipe_icon',
      "recipe_id": recipe.id,
      "recipe_name": recipe.name,
      "group_name": recipe.group,
      "ingredient": ingredientTitleArr,        
      "recipe_type": recipe.manual ? 'manual' : 'schedule',
      'user_id': currentUser.userID,
      'tenant_id': currentUser.tenant
    });
    setLoading(true);
    dispatch(getRecipe(recipe.id)).then((res) => {
      if(res.payload.status >= 400) {
        toast.error('Something went wrong. Please check User Permissions', {
          position: "bottom-right",
          autoClose: 8080,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      } else {
        setLoading(false);
        navigate("/recipe_summary/", { state: { id: recipe.id, edit: true } });
      }      
    });
  };

  const recipeRun = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'run_recipe_starts',
      "recipe_id": recipe.id,
      "recipe_name": recipe.name,
      "group_name": recipe.group,
      "ingredient": ingredientTitleArr,        
      "recipe_type": recipe.manual ? 'manual' : 'schedule',
      'user_id': currentUser.userID,
      'tenant_id': currentUser.tenant
    });
    setLoading(true);
    setTimeout(() => { setLoading(false); }, 1000);
    dispatch(runRecipe(recipe.id)).then(() => {
      dispatch(getSelectedRecipe(recipe.id)).then((res) => {        
        var elementPos = filteredRecipes.map(function(x: any) {return x.id; }).indexOf(recipe.id);
        var tempArray = _.cloneDeep(filteredRecipes);
        tempArray[elementPos].percent = res.payload.percent;
        tempArray[elementPos].active = res.payload.active;
        tempArray[elementPos].ago = res.payload.ago;
        tempArray[elementPos].status = res.payload.status;        
        setFilteredRecipe(tempArray);
      });
      // setLoading(false);
    });
  }

  const recipePause = () => {
    setLoading(true);
    dispatch(pauseRecipe(recipe.id)).then(() => {
      dispatch(getSelectedRecipe(recipe.id)).then((res) => {
        var elementPos = filteredRecipes.map(function(x: any) {return x.id; }).indexOf(recipe.id);
        var tempArray = _.cloneDeep(filteredRecipes);
        tempArray[elementPos].percent = res.payload.percent;
        tempArray[elementPos].active = res.payload.active;
        tempArray[elementPos].ago = res.payload.ago;
        tempArray[elementPos].status = res.payload.status;
        setFilteredRecipe(tempArray);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'stop_recipe',
          "recipe_id": recipe.id,
          "recipe_name": recipe.name,
          "stopped_at": recipe.percent + '%',
          "group_name": recipe.group,
          "ingredient": ingredientTitleArr,        
          "recipe_type": recipe.manual ? 'manual' : 'schedule',
          'user_id': currentUser.userID,
          'tenant_id': currentUser.tenant
        });
      });
      setLoading(false);
    });
  }

  const recipeLogs = () => {
    setLoading(true);
        dispatch(getStatus(recipe.id)).then((res) => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'recipe_logs',
            "recipe_id": recipe.id,
            "recipe_name": recipe.name,
            "group_name": recipe.group,
            "ingredient": ingredientTitleArr,        
            "recipe_type": recipe.manual ? 'manual' : 'schedule',
            'user_id': currentUser.userID,
            'tenant_id': currentUser.tenant
          });
          setLoading(false);
          setLogText(res.payload.tasks)
          setShowLogsModal(true);
        });
  }

  return (
    <div
      className={`rounded-3xl border-[15px] ${
        i % 2 === 0 ? "border-[#BCEBEF]" : "border-[#ACD5BF]"
      } p-5 w-[97%] ml-[1.5%] mb-5`}
    >
      <RecipeInfo
        IscurrentUser={currentUser.userID === recipe.user_id}
        name={recipe.name}
        group={recipe.group}
        description={recipe.description}
        onCopy={onCopy}
        onDelete={onDelete}
        onEdit={onEdit}
        recipeID={recipe.id}
        status={recipe.status}
        filteredRecipes={filteredRecipes}
        setFilteredRecipe={setFilteredRecipe}
        recipeLogs={recipeLogs}
        showLogsModal={showLogsModal}
        logText={logText}
        setShowLogsModal={setShowLogsModal}
        is_rt_tool={recipe.is_rt_tool}
        tasks={JSON.parse(recipe.tasks)}
        recipe={recipe}
      />
      <div className="flex flex-row flex-wrap justify-between">
        <div className="basis-[69.5%]">
          <IngredientList ingredients={recipe.ingredients} />
          <RecipeAuthInfo
            date={recipe.created}
            author={recipe.author}
            manual={recipe.manual}
          />
        </div>
        <RecipeInfoCards
          isCurrentUser={currentUser.userID === recipe.user_id}
          group={recipe.group}
          status={recipe.status}
          active={recipe.active}
          lastRun={recipe.ago}
          runTime={recipe.run_time}
          progress={recipe.percent}
          permissionsEnabled={recipe.permissions_enabled}
          runRecipe={recipeRun}
          pauseRecipe={recipePause}
          is_rt_tool={recipe.is_rt_tool}
          tasks={JSON.parse(recipe.tasks)}
        />
      </div>
    </div>
  );
};

export default RecipeCard;
