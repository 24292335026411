import ApiOperations from "../ApiOperations";
import config from "components/Common/config";

const listGroups = async (tenantId) => {
  let x = await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/groups`,
    (status, data) => {
      const filteredArray = data.filter(obj => obj.organisation.id === tenantId);
      return filteredArray;
    }
  );
  return x;
};

const createGroup = async (payload: any) => {
  await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/groups/`,
    payload,
    (status, data) => {}
  );

  return;
};

const updateGroup = async (id: any, payload: any) => {
  await ApiOperations.patch(
    `${config.apiGateway.tenantURL}/api/v1/users/groups/` + id,
    payload,
    (status, data) => {}
  );

  return;
};

const deleteGroup = async (id: any) => {
  await ApiOperations.delete(
    `${config.apiGateway.tenantURL}/api/v1/users/groups/` + id,
    (status, data) => {}
  );

  return;
};

const getGroup = async (id: any) => {
  let response = await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/groups/` + id,
    (status, data) => {
      return data;
    }
  );

  return response;
};

const groupService = {
  listGroups,
  deleteGroup,
  getGroup,
  createGroup,
  updateGroup,
};

export default groupService;
