import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./userService";
// import { toast } from "react-toastify";

const initialState = {
  usersList: [],
  user: {
    name: "",
    email: "",
    password: "",
    tenantId: 1,
    imageUrl: "",
    groups: [],
    roles: null,
    userIdentifier: "",
    // description: "",
    // users: [],
  },
};

export const createUser = createAsyncThunk(
  "users/createUser",
  async (payload: any, thunkAPI) => {
    try {
      return await userService.createUser(payload);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async ({ id, payload }: any, thunkAPI) => {
    try {
      return await userService.updateUser(id, payload);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const resetUser = createAsyncThunk(
  "users/resetUser",
  async (thunkAPI) => {
    try {
      return;
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const listUsers = createAsyncThunk("users/list", async (tenantId: any, thunkAPI) => {
  try {
    return await userService.listUsers(tenantId);
  } catch (error: any) {
    const message: any =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return message;
  }
});

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async ({userId, tenantId}: any, thunkAPI) => {
    try {
      return await userService.deleteUser(userId, tenantId);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const getUser = createAsyncThunk(
  "users/getUser",
  async (id: any, thunkAPI) => {
    try {
      return await userService.getUser(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const setUserDetail = createAsyncThunk(
  "users/setUserDetail",
  async (payload: any, thunkAPI) => {
    try {
      return payload;
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const listUsersByTenantID = createAsyncThunk(
  "users/listUsersByTenantID",
  async (id: any, thunkAPI) => {
    try {
      return await userService.listUsersByTenantID(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const updateTandC = createAsyncThunk(
  "users/updateTandC",
  async (payload: any, thunkAPI) => {
    try {
      return await userService.updateTandC(payload);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const UserSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    reset: (state) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.fulfilled, (state, action) => {
        state.user = initialState.user;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = initialState.user;
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.user = initialState.user;
      })
      .addCase(listUsers.fulfilled, (state, action) => {
        state.usersList = action.payload;
      })
      .addCase(setUserDetail.fulfilled, (state, action) => {
        state.user.name = action.payload.name;
        state.user.email = action.payload.email;
        state.user.groups = action.payload.groups;
        state.user.roles = action.payload.roles;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(listUsersByTenantID.fulfilled, (state, action) => {
        state.usersList = action.payload;
      });
  },
});

export const { reset } = UserSlice.actions;
export default UserSlice.reducer;
