import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useNavigate } from "react-router-dom";
import PageSubHeader from "components/IngredientView.tsx/PageSubHeader";
import OptionSelector from "components/Common/OptionSelector";

const UserControl = ({
  currentRole,
  setCurrentRole,
  disabled,
  newRole,
  setNewRole,
}: any) => {
  return (
    <div>
      <PageSubHeader title="User Control" />
      <p className="font-bold text-lg my-5">Users</p>
      <div className="flex flex-row mt-10">
        <OptionSelector
          title="Organization"
          desc="Give users the ability to add, edit or delete users in an organization"
          options={["Add users", "Edit users", "Delete users"]}
          disabled={disabled}
          currentRole={currentRole}
          setCurrentRole={setCurrentRole}
          newRole={newRole}
          setNewRole={setNewRole}
        />
        <OptionSelector
          title="Role"
          desc="Give users the ability to add, edit or delete a role"
          options={["Add role", "Edit role", "Delete role"]}
          disabled={disabled}
          currentRole={currentRole}
          setCurrentRole={setCurrentRole}
          newRole={newRole}
          setNewRole={setNewRole}
        />
      </div>
      <div className="mt-5 mb-10">
        <OptionSelector
          title="Groups"
          desc="Give users the ability to add, edit or delete a group"
          options={["Add groups", "Edit groups", "Delete groups"]}
          disabled={disabled}
          currentRole={currentRole}
          setCurrentRole={setCurrentRole}
          newRole={newRole}
          setNewRole={setNewRole}
        />
      </div>
    </div>
  );
};

export default UserControl;
