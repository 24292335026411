import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useNavigate } from "react-router-dom";
import PageHeader from "components/PageHeader/PageHeader";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ArrowDown } from "assets/icons/Icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { deleteRole, getRole, listRoles } from "features/roles/roleSlice";
import Popup from "components/Common/Popup";
import setLoading from "features/helper";

import _ from "lodash";

const RolesDashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleRoleId, setDeleRoleId] = useState(null);
  const [roles, setRoles] = useState(null);
  const [asc, setAsc] = useState(true);

  const currentUser = useSelector((state: any) => state.auth.user);

  useEffect(() => {
    setLoading(true);
    dispatch(listRoles()).then((res) => {
      setRoles(res.payload)
      setLoading(false);
    });
  }, []);

  const onClick = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'create_new_role_starts',
      'user_id': currentUser.userID,
      'tenant_id': currentUser.tenant
    });
    navigate("/create_role_options");
  };

  const onDelete = (id: any) => {
    let role_name = '';
    for (let index = 0; index < roles.length; index++) {
      if(roles[index].id == deleRoleId) {
        role_name = roles[index].name;
      }
    }
    setLoading(true);
    dispatch(deleteRole(deleRoleId)).then(() => {
      dispatch(listRoles()).then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'delete_role',
          'role_id': deleRoleId,
          'user_id': currentUser.userID,
          'tenant_id': currentUser.tenant,
          'role_name': role_name
        });
        setLoading(false);
        navigate("/roles");
      });
    });
  };

  const onEdit = (id: any, name: any) => {
    setLoading(true);
    dispatch(getRole(id)).then(() => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'edit_role',
        'role_id': id,
        'user_id': currentUser.userID,
        'tenant_id': currentUser.tenant,
        'role_name': name
      });
      setLoading(false);
      navigate("/role_summary", { state: { id: id } });
    });
  };
  const sortUsers = () => {
    let tempRoles = _.cloneDeep(roles);
    tempRoles.sort((a, b) => {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return asc ? -1 : 1;
      }
      if (nameA > nameB) {
        return asc ? 1 : -1;
      }
      return 0;
    });
    setRoles(tempRoles);
    setAsc(!asc);
  };

  return (
    <div className="p-[0_2.5%] w-[82%] ml-[18%]">
      <PageHeader title="Roles" button="Create new Role" onClick={onClick} />
      <div className="w-[90%] ml-[5%] mt-24">
        <div className="grid grid-cols-10 gap-4 px-16">
          <div className="flex flex-row items-center col-span-8 text-primary font-bold text-lg">
            <p className="mr-5">Roles</p>
            {asc ? (
                <KeyboardArrowDownIcon className="cursor-pointer" onClick={() => sortUsers()} />
              ) : (
                <KeyboardArrowUpIcon className="cursor-pointer" onClick={() => sortUsers()} />
              )}
          </div>
          <p className="col-span-1 text-primary font-bold text-lg">Edit</p>
          <p className="col-span-1 text-primary font-bold text-lg">Delete</p>
        </div>
        {roles?.map((data: any, index: any) => (
          <div
            key={index}
            className="grid grid-cols-10 gap-4 my-5 px-16 py-2 border rounded-lg border-[#CFD8DC]"
          >
            <p className="col-span-8 text-primary text-lg">{data.name}</p>
            <BorderColorOutlinedIcon
              className="col-span-1 text-primary cursor-pointer ml-1"
              style={{ fontSize: "1.9rem" }}
              onClick={() => onEdit(data.id, data.name)}
            />
            <DeleteOutlineIcon
              className="col-span-1 text-primary cursor-pointer ml-3"
              style={{ fontSize: "1.9rem" }}
              onClick={() => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  'event': 'delete_role_icon',
                  'role_id': data.id,
                  'user_id': currentUser.userID,
                  'tenant_id': currentUser.tenant,
                  'role_name': data.name
                });
                setShowDeleteModal(true);
                setDeleRoleId(data.id);
              }}
            />
          </div>
        ))}
        {showDeleteModal ? (
          <Popup
            setOpenModal={setShowDeleteModal}
            text="Delete this Role?"
            redirect={onDelete}
          />
        ) : null}
      </div>
    </div>
  );
};

export default RolesDashboard;
