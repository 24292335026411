import React, { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { DafaultButton } from "components/Common/Button";
import { IngredientViewData } from "components/Common/staticData";
import PageSubHeader from "components/IngredientView.tsx/PageSubHeader";
import PageHeader from "components/PageHeader/PageHeader";
import HeaderLogo from "components/Common/HeaderLogo";
import { useLocation, useNavigate, matchPath } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { viewIngredient } from "features/ingredients/ingredientSlice";
import { ReactComponent as ScheduledIcon } from "../assets/images/Scheduled.svg";
import { ReactComponent as ManualIcon } from "../assets/images/Manual.svg";
import _ from "lodash";
import moment from "moment";
import Loader from "components/Common/Loader";
import setLoading from "features/helper";

const IngredientView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { pathname } = useLocation();
  const solutionId = matchPath(`/ingredients/:id`, pathname)?.params?.id;

  const solution = useSelector(
    (state: any) => state?.ingredient?.ingredientDetail
  );

  const [solutionDetail, setSolutionDetail] = useState(solution);
  const [solutionAge, setSolutionAge] = useState([]);

  const mainHeadMargin = "mb-9";
  const subHeadMargin = "mb-5";
  const subHeadStyle = "text-base font-bold";

  const handleAge = (age: any) => {
    let text = age;
    let replacedAge = text?.replace("-", "/");

    const today = new Date();
    const releasedDate = new Date(replacedAge);
    const calculatedMonth = today.getMonth() - releasedDate.getMonth();
    const calculatedYear = today.getFullYear() - releasedDate.getFullYear();
    let calculateDate = [calculatedYear, calculatedMonth];
    return calculateDate;
  };

  const instrunctions = solution?.script?.instructions;
  const formattedDate = moment(solutionDetail?.script?.released).format(
    "MMM D, YYYY"
  );
  const icon = solutionDetail?.setup ? (
    <>
      <ManualIcon className="mr-3" />
      {IngredientViewData.ManualRecipe}
    </>
  ) : (
    <>
      <ScheduledIcon className="mr-3" />
      {IngredientViewData.ScheduledRecipe}
    </>
  );

  const year =
    solutionAge[0] <= 0
      ? ""
      : solutionAge[0] == 1
      ? "a year and "
      : `${solutionAge[0]} years and `;

  const month =
    solutionAge[1] <= 0
      ? ""
      : solutionAge[1] == 1
      ? "a month "
      : `${solutionAge[1]} months `;

  useEffect(() => {
    setLoading(true);
    dispatch(viewIngredient(solutionId)).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setSolutionDetail(solution);
    setSolutionAge(handleAge(solution?.script?.released));
  }, [solution]);

  // if (_.isEmpty(solution)) return <Loader />;

  return (
    <div className="p-[0_2.5%] mb-[2.5%] w-[82%] m-0 float-right text-primary">
      <div className="mb-10">
        <PageHeader
          title={solutionDetail?.script?.title ?? ""}
          // item={<HeaderLogo text={"GA360"} />}
        />
        <p className="flex items-center">{icon}</p>
      </div>
      <div className="w-[90%] ml-[2.4%] mr-[7.7%]">
        <div className={mainHeadMargin}>
          <PageSubHeader title={IngredientViewData.Description} />
          <p>{solution?.script?.description}</p>
        </div>
        {instrunctions && (
          <div className={mainHeadMargin}>
            <PageSubHeader title={IngredientViewData.Instructions} />
            {instrunctions?.map((line, i) => (
              <div
                className={"leading-8 [&_a]:text-[#003366] [&_a]:font-medium"}
                key={i}
                dangerouslySetInnerHTML={{ __html: `${i + 1}. ${line}` }}
              />
            ))}
          </div>
        )}
        <div className={mainHeadMargin}>
          <PageSubHeader title={IngredientViewData.Details} />
          <div className={subHeadMargin}>
            <p className={subHeadStyle}>{IngredientViewData.OpenSource}</p>
            <p>{IngredientViewData.yes}</p>
          </div>
          <div className={subHeadMargin}>
            <p className={subHeadStyle}>{IngredientViewData.Age}</p>
            <p>
              {formattedDate} ({year}
              {month} ago)
            </p>
          </div>
          <div className={subHeadMargin}>
            <p className={subHeadStyle}>{IngredientViewData.ScheduleDays}</p>
            <p>{IngredientViewData.ConfiguredByUser}</p>
          </div>
        </div>
        <DafaultButton
          text={IngredientViewData.Back}
          startIcon={<ArrowBack />}
          color={"primary"}
          onClick={() => navigate(-1)}
          outlined
        />
      </div>
    </div>
  );
};

export default IngredientView;
