import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useNavigate } from "react-router-dom";
import PageHeader from "components/PageHeader/PageHeader";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { deleteUser, getUser, listUsers } from "features/users/userSlice";
import Popup from "components/Common/Popup";
import setLoading from "features/helper";

import _ from "lodash";

const UsersDashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleUserId, setDeleUserId] = useState(null);

  // const users = useSelector((state: any) => state.user.usersList);

  const [users, setUsers] = useState(null);
  const [asc, setAsc] = useState(true);
  const [sorting, setSorting] = useState("");

  const currentUserRoles = useSelector((state: any) => state.auth.user.role.resources);
  const currentUser = useSelector((state: any) => state.auth.user);

  const canEdit = currentUserRoles.Organization
    ? currentUserRoles.Organization.includes("Edit users")
    : false;

  const canDelete = currentUserRoles.Organization
    ? currentUserRoles.Organization.includes("Delete users")
    : false;

  const canCreate = currentUserRoles.Organization
    ? currentUserRoles.Organization.includes("Add users")
    : false;

  useEffect(() => {
    setLoading(true);
    dispatch(listUsers(currentUser.tenant)).then((res) => {
      setUsers(res.payload);
      setLoading(false);
    });
  }, []);

  const onClick = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "create_new_user_starts",
      user_id: currentUser.userID,
      tenant_id: currentUser.tenant,
    });
    navigate("/user_create");
  };

  const onDelete = (id: any) => {
    let role_name = '';
    let groupsArr = [];
    for (let index = 0; index < users.length; index++) {
      if(users[index].id == deleUserId) {
        role_name = users[index].roles[0].name;
        for (let group_index = 0; group_index < users[index].groups.length; group_index++) {
          groupsArr.push(users[index].groups[group_index].name);
        }
      }
    }
    setLoading(true);
    dispatch(deleteUser({ userId: deleUserId, tenantId: currentUser.tenant })).then(() => {
      dispatch(listUsers(currentUser.tenant)).then((res) => {
        setUsers(res.payload);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "delete_user",
          deleted_user_id: deleUserId,
          user_id: currentUser.userID,
          tenant_id: currentUser.tenant,
          'group_name': groupsArr,
          'role_name': role_name
        });
        setLoading(false);
        navigate("/users");
      });
    });
  };

  const onEdit = (id: any, groups: any, role_name: any) => {
    let groupsArr = [];
    for (let index = 0; index < groups.length; index++) {
      groupsArr.push(groups[index].name);
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "edit_user_icon",
      edited_user_id: id,
      user_id: currentUser.userID,
      tenant_id: currentUser.tenant,
      'group_name': groupsArr,
      'role_name': role_name
    });
    setLoading(true);
    dispatch(getUser(id)).then(() => {
      setLoading(false);
      navigate("/user_summary", { state: { id: id } });
    });
  };

  const sortUsers = (category: any) => {
    let tempUsers = _.cloneDeep(users);
    tempUsers.sort((a, b) => {
      let nameA: any;
      let nameB: any;
      if (category === "name") {
        nameA = a[category].toUpperCase(); // Convert names to uppercase for case-insensitive sorting
        nameB = b[category].toUpperCase();
      } else {
        nameA = a[category][0]["name"].toUpperCase(); // Convert names to uppercase for case-insensitive sorting
        nameB = b[category][0]["name"].toUpperCase();
      }
      if (nameA < nameB) {
        return asc ? -1 : 1;
      }
      if (nameA > nameB) {
        return asc ? 1 : -1;
      }
      return 0;
    });
    setUsers(tempUsers);
    setAsc(!asc);
    setSorting(category);
  };

  return (
    <div className="p-[0_2.5%] w-[82%] ml-[18%]">
      <PageHeader title="Users" button="Create new User" onClick={onClick} disabled={!canCreate} />
      <div className="w-[90%] ml-[5%] my-24">
        <div className="grid grid-cols-11 gap-4 px-16">
          <div className={`flex flex-row items-center col-span-2 text-primary 
                          ${sorting === 'name' ? 'font-black' : 'font-semibold'} text-lg`}>
            <p className="mr-5">Name</p>
            {sorting === "name" ? (
              asc ? (
                <KeyboardArrowDownIcon className="cursor-pointer" onClick={() => sortUsers("name")} />
              ) : (
                <KeyboardArrowUpIcon className="cursor-pointer" onClick={() => sortUsers("name")} />
              )
            ) : (
              <KeyboardArrowDownIcon className="cursor-pointer" onClick={() => sortUsers("name")} />
            )}
          </div>
          <div className={`flex flex-row items-center col-span-1 text-primary 
                          ${sorting === 'roles' ? 'font-black' : 'font-semibold'} text-lg`}>
            <p className="mr-5">Role</p>
            {sorting === "roles" ? (
              asc ? (
                <KeyboardArrowDownIcon className="cursor-pointer" onClick={() => sortUsers("roles")} />
              ) : (
                <KeyboardArrowUpIcon className="cursor-pointer" onClick={() => sortUsers("roles")} />
              )
            ) : (
              <KeyboardArrowDownIcon className="cursor-pointer" onClick={() => sortUsers("roles")} />
            )}
          </div>
          <div className="flex flex-row items-center col-span-6 text-primary font-semibold text-lg">
            <p className="mr-5">Groups</p>
          </div>
          {/* <div className="flex flex-row items-center col-span-2 text-primary font-bold text-lg">
            <p className="mr-5">Status</p>
            <ArrowDown />
          </div> */}
          <p className="col-span-1 text-primary font-semibold text-lg text-center">Edit</p>
          <p className="col-span-1 text-primary font-semibold text-lg text-center">Delete</p>
        </div>
        {users?.map((data: any, index: any) => (
          <div
            key={index}
            className="grid grid-cols-11 gap-4 my-5 px-16 py-2 border rounded-lg border-[#CFD8DC]"
          >
            <p className="col-span-2 text-primary text-lg">{data.name}</p>
            <p className="flex flex-row items-center col-span-1 text-primary text-lg">
              {data?.roles[0]?.name}
            </p>
            <div className="flex flex-row col-span-6 truncate ...">
              {data?.groups.map((group: any, index: any) => (
                <p key={index} className="text-primary text-lg">
                  {group?.name}
                  {index === data?.groups.length - 1 ? "" : ", "}&nbsp;
                </p>
              ))}
            </div>
            {/* <div className="flex flex-row items-center col-span-2 text-primary text-lg">
              <div
                className={`${
                  data?.is_active ? "bg-[#0EB200]" : "bg-[#FB2E01]"
                } rounded-full mr-2 w-2 h-2`}
              />
              <p>{data?.is_active ? "Active" : "Inactive"}</p>
            </div> */}
            <button className="text-center" onClick={() => onEdit(data.id, data.groups, data.roles[0].name)} disabled={!canEdit}>
              <BorderColorOutlinedIcon
                className={`col-span-1 text-center ${canEdit ? "text-primary" : "text-slate-300"}`}
                style={{ fontSize: "1.9rem" }}
              />
            </button>
            <button
              className="text-center"
              onClick={() => {
                let groupsArr = [];
                for (let index = 0; index < data.groups.length; index++) {
                  groupsArr.push(data.groups[index].name);
                }
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "delete_user_icon",
                  deleted_user_id: data.id,
                  user_id: currentUser.userID,
                  tenant_id: currentUser.tenant,
                  'group_name': groupsArr,
                  'role_name': data.roles[0].name
                });
                setShowDeleteModal(true);
                setDeleUserId(data.id);
              }}
              disabled={!canDelete}
            >
              <DeleteOutlineIcon
                className={`col-span-1 ${canDelete ? "text-primary" : "text-slate-300"}`}
                style={{ fontSize: "1.9rem" }}
              />
            </button>
          </div>
        ))}
        {showDeleteModal ? (
          <Popup setOpenModal={setShowDeleteModal} text="Delete this User?" redirect={onDelete} />
        ) : null}
      </div>
    </div>
  );
};

export default UsersDashboard;