import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { DafaultButton } from "components/Common/Button";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RecipeSetting from "components/Roles/RecipeSetting";
import { setRoleResources } from "features/roles/roleSlice";
import setLoading from "features/helper";

const NewRoleRecipeSetting = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const role = useSelector((state: any) => state.roles.role);
  const currentUser = useSelector((state: any) => state.auth.user);

  const [currentRole, setCurrentRole] = useState({
    name: "",
    resources: role.resources,
  });

  const nextClick = () => {
    setLoading(true);
    dispatch(setRoleResources(currentRole.resources)).then(() => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'recipe_settings',
        'user_id': currentUser.userID,
        'tenant_id': currentUser.tenant
      });
      setLoading(false);
      navigate("/role_create");
    });
  };

  return (
    <div className="p-[0_2.5%] w-[82%] ml-[18%]">
      <RecipeSetting
        currentRole={_.cloneDeep(currentRole)}
        setCurrentRole={setCurrentRole}
        disabled={false}
      />
      <div className="flex flex-row justify-between w-[72%] my-20">
        <DafaultButton
          text={"Back"}
          outlined
          startIcon={<ArrowBackIcon />}
          radius={"6px"}
          onClick={() => {
            // setFullRecipeDetails();
            navigate(-1);
          }}
        />
        <DafaultButton
          text={"Next Step"}
          endIcon={<ArrowForwardIcon />}
          color="success"
          textColor={"#FFFFFF"}
          width={"12rem"}
          radius={"6px"}
          onClick={() => nextClick()}
          //   disabled={edit}
        />
      </div>
    </div>
  );
};

export default NewRoleRecipeSetting;
