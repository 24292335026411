import React, { useState } from "react";
import productLogoContainer from "../../assets/images/productLogoContainer.png";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined";
import ComputerIcon from "@mui/icons-material/Computer";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import NavItem from "./NavItem";
import { useNavigate } from "react-router-dom";
import { LeftNavItems } from "components/Common/staticData";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { resetRole } from "features/roles/roleSlice";
import { resetGroup } from "features/groups/groupSlice";

const LeftNavBar = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([LeftNavItems.Recipes, ""]);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: any) => state.auth.user);

  const handleClick = (item) => {
    setSelected([item, selected[1]]);
    if (item === LeftNavItems.UserMangement) {
      setOpen(true);
    } else {
      setOpen(false);
      dispatch(resetGroup());
      dispatch(resetRole()).then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'nav_menu',
        'nav_field': item,
        'user_id': currentUser.userID,
        'tenant_id': currentUser.tenant
      });
        navigate("/" + item.toLowerCase());
      });
    }
  };

  const handleSubClick = (item) => {
    setSelected([LeftNavItems.UserMangement, item]);
    dispatch(resetGroup());
    dispatch(resetRole()).then(() => {
      window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'nav_menu',
        'nav_field': item
      });
      navigate("/" + item.toLowerCase());
    });
  };

  return (
    <div className="fixed top-0 flex flex-col w-[18%] p-[2%_0_0_2%] h-screen shadow-[inset_0_-1px_8px_0px_rgba(20,27,53,0.12)] bg-white">
      <div className="flex flex-row items-center">
        <img src={productLogoContainer} alt="" className="w-[60%]" />
        {/* <p className="font-medium text-3xl pl-[5%]">SPiDR</p> */}
      </div>
      <div className="flex flex-col pt-[20%]">
        <p className="font-medium text-xl">MENU</p>
        <ul className="pt-[10%]">
          <NavItem
            disabled={true}
            icon={<GridViewRoundedIcon />}
            text={LeftNavItems.Dashboard}
            selected={LeftNavItems.Dashboard === selected[0]}
            onClick={() => {
              handleClick(LeftNavItems.Dashboard);
            }}
          />
          <NavItem
            icon={<FormatListBulletedIcon />}
            text={LeftNavItems.Ingredients}
            selected={LeftNavItems.Ingredients === selected[0]}
            onClick={() => {
              handleClick(LeftNavItems.Ingredients);
            }}
          />
          <NavItem
            icon={<DescriptionOutlinedIcon />}
            text={LeftNavItems.Recipes}
            selected={LeftNavItems.Recipes === selected[0]}
            onClick={() => handleClick(LeftNavItems.Recipes)}
          />
          <NavItem
            icon={<FolderSharedOutlinedIcon />}
            text={LeftNavItems.UserMangement}
            selected={LeftNavItems.UserMangement === selected[0]}
            onClick={() => handleClick(LeftNavItems.UserMangement)}
          />
          {open ? (
            <ul className="pl-[15%] transition duration-300 ease-in">
              <NavItem
                icon={<MenuBookOutlinedIcon />}
                text={LeftNavItems.Roles}
                selected={LeftNavItems.Roles === selected[1]}
                onClick={() => handleSubClick(LeftNavItems.Roles)}
              />
              <NavItem
                icon={<GroupOutlinedIcon />}
                text={LeftNavItems.Groups}
                selected={LeftNavItems.Groups === selected[1]}
                onClick={() => handleSubClick(LeftNavItems.Groups)}
              />
              <NavItem
                icon={<PersonOutlineOutlinedIcon />}
                text={LeftNavItems.Users}
                selected={LeftNavItems.Users === selected[1]}
                onClick={() => handleSubClick(LeftNavItems.Users)}
              />
            </ul>
          ) : (
            <></>
          )}
          {/* <NavItem
            icon={<ComputerIcon />}
            text={LeftNavItems.ServiceAccounts}
            selected={LeftNavItems.ServiceAccounts === selected[0]}
            onClick={() => handleClick(LeftNavItems.ServiceAccounts)}
          />
          <NavItem
            icon={<CardTravelIcon />}
            text={LeftNavItems.Billings}
            selected={LeftNavItems.Billings === selected[0]}
            onClick={() => handleClick(LeftNavItems.Billings)}
          /> */}
        </ul>
      </div>
    </div>
  );
};

export default LeftNavBar;
