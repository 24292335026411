import React from "react";
import { ingredientPlatforms } from "components/Common/staticData";
import IngredientPlatforms from "components/Common/IngredientPlatforms";

const IngredientList = ({ ingredients }: any) => {

  function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }

  const images = importAll(
    require.context("../../assets/images", false, /\.(png|jpe?g|svg)$/)
  );
  
  return (
    <div className="bg-[#F5F6F8] p-5 rounded-2xl h-min">
      <p className="text-center mb-5 font-normal text-primary">Ingredients</p>
      <div className="flex flex-wrap flex-row">
        {ingredients?.map((ingredient, index) => (
          <div
            key={index}
            className={`flex flex-col bg-white rounded-2xl basis-[32%] p-3 pb-5 mb-5 shadow-[3px_10px_50px_-1px_rgba(121,120,130,0.15)] ${
              (index + 1) % 3 !== 0 ? "mr-[2%]" : ""
            }`}
          >
            <div className="flex basis-1/4 flex-row items-center mb-2 ml-3">
              {/* < IngredientPlatforms platforms={ingredient.platform} /> */}
              {ingredient.platform?.map((platform, index) => (
                <img
                key={index}
                  className="mr-3"
                  src={images[`${ingredientPlatforms[platform]}.png`] || images[`${ingredientPlatforms[platform]}.svg`]}
                  alt={ingredientPlatforms[platform]}
                  height={20}
                  width={20}
                />
              ))}
            </div>
            <p className="flex flex-row items-center justify-center text-center basis-3/4 font-semibold text-black text-base">
              {ingredient.title}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IngredientList;
