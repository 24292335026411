import React, { useState } from "react";
import {
  ApplyButton,
  ClearButton,
  DateSelectionButton,
} from "components/Common/Button";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const DatePicker = ({
  onDateRangeFilter = () => {},
  appliedDate = () => {},
}: any) => {
  const orangeColor = "#FFB525";

  const today = new Date();
  const minDate = new Date(2017, 1, 1);

  const [buttonDate, setButtonDate] = useState(appliedDate);

  const setDate = (year, month, day) => {
    const newDate = dateCalculator(year, month, day);
    const endDate = month > 0 ? newDate : today;
    setButtonDate([
      {
        startDate: newDate,
        endDate: endDate,
        key: "selection",
      },
    ]);
  };

  const setMonth = () => {
    setButtonDate([
      {
        startDate: new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getDate()
        ),
        endDate: today,
        key: "selection",
      },
    ]);
  };

  const dateCalculator = (year, month, day) => {
    let newDate = new Date(
      today.getFullYear() - year,
      today.getMonth() - month,
      today.getDate() - day
    );
    return newDate;
  };

  return (
    <div className="absolute right-0 p-3.5 rounded-[28px] bg-white shadow-dropdown focus:outline-none z-50 cursor-default">
      <div className={"flex items-center justify-start gap-2"}>
        <DateSelectionButton
          text={"Today"}
          bg={"beige"}
          onClick={() => setDate(0, 0, 0)}
        />
        <DateSelectionButton
          text={"Last 8 days"}
          onClick={() => setDate(0, 0, 7)}
        />
        <DateSelectionButton text={"Last month"} onClick={() => setMonth()} />
      </div>
      <hr className="border-[0.5px] border-[#BEBEBE] mt-3" />
      <div className="flex items-center justify-center w-full">
        <DateRange
          editableDateInputs={true}
          shownDate={today}
          onChange={(item) => setButtonDate([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={buttonDate}
          showDateDisplay={false}
          rangeColors={[orangeColor]}
          minDate={minDate}
          maxDate={today}
        />
      </div>
      <hr className="border-[0.5px] border-[#BEBEBE] mb-3" />
      <div className={"flex items-center justify-end gap-4"}>
        <ClearButton
          text={"Clear"}
          onClick={() => {
            setDate(0, 0, 0);
            onDateRangeFilter(
              [
                {
                  startDate: new Date(),
                  endDate: new Date(),
                  key: "selection",
                },
              ],
              "Clear"
            );
          }}
        />
        <ApplyButton
          text={"Apply"}
          onClick={() => onDateRangeFilter(buttonDate, "Apply")}
        />
      </div>
    </div>
  );
};

export default DatePicker;
