import ApiOperations from "../ApiOperations";
import config from "components/Common/config";

const listUsers = async (tenantId) => {
  let x = await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/users`,
    (status, data) => {
      const filteredData = data.filter(item => item.organisation.id === tenantId);
      return filteredData;
    }
  );
  return x;
};

const listUsersByTenantID = async (id: any) => {
  let x = await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/tenant/${id}`,
    (status, data) => {
      return data.users;
    }
  );
  return x;
};

const createUser = async (payload: any) => {
  await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/users/`,
    payload,
    (status, data) => {}
  );

  return;
};

const updateUser = async (id: any, payload: any) => {
  await ApiOperations.patch(
    `${config.apiGateway.tenantURL}/api/v1/users/users/` + id,
    payload,
    (status, data) => {}
  );

  return;
};

const updateTandC = async (payload: any) => {
  let res = await ApiOperations.patch(
    `${config.apiGateway.tenantURL}/api/v1/users/users/updatetc`,
    payload,
    (status, data) => {
      return data;
    }
  );

  return res;
};

const deleteUser = async (userId: any, tenantId: any) => {
  await ApiOperations.delete(
    `${config.apiGateway.tenantURL}/api/v1/users/users?id=${userId}&tenantId=${tenantId}`,
    (status, data) => {}
  );

  return;
};

const getUser = async (id: any) => {
  let response = await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/users/` + id,
    (status, data) => {
      data[0].roles[0].resources = JSON.parse(data[0].roles[0].resources);
      return data[0];
    }
  );

  return response;
};

const userService = {
  listUsers,
  deleteUser,
  getUser,
  createUser,
  updateUser,
  updateTandC,
  listUsersByTenantID,
};

export default userService;
