import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

export const setLoader = createAsyncThunk(
  "loader/setLoader",
  async (payload: any, thunkAPI) => {
    try {
      return payload;
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setLoader.fulfilled, (state, action) => {
      state.isLoading = action.payload;
    });
  },
});

export const { reset } = loaderSlice.actions;
export default loaderSlice.reducer;
