import ApiOperations from "../ApiOperations";
import { ingredientPlatforms } from "components/Common/staticData";
import config from "components/Common/config";

const listIngredients = async () => {
  let x = await ApiOperations.get(
    `${config.apiGateway.ingredientURL}/api/v1/ingredients/list/`,
    (status, data) => {
      data.forEach(function (part, index) {
        this[index].platform_from.forEach(function (part, index) {
          if (this[index] in ingredientPlatforms) {
            this[index] = ingredientPlatforms[this[index]];
          }
        }, this[index].platform_from);
        this[index].platform_to.forEach(function (part, index) {
          if (this[index] in ingredientPlatforms) {
            this[index] = ingredientPlatforms[this[index]];
          }
        }, this[index].platform_to);
      }, data);

      return data;
    }
  );
  return x;
};

const viewIngredient = async (id) => {
  let x = await ApiOperations.get(
    `${config.apiGateway.ingredientURL}/api/v1/ingredients/${id}`,
    (status, data) => {
      return data;
    }
  );
  return x;
};

const ingredientsService = {
  listIngredients,
  viewIngredient,
};

export default ingredientsService;
