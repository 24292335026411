export const SingleSelectDropdownStyles = {
  control: () => ({
    display: "flex",
    alignItems: "start",
    cursor: "pointer",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: "flex",
    svg: {
      fill: "#40506A",
    },
    div: {
      padding: 3,
      alignItems: "center",
    },
  }),
  indicatorSeparator: () => ({ opacity: 0 }),
  placeholder: () => ({
    color: "#40506A",
  }),
  valueContainer: () => ({ display: "flex" }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#EDEEF0",
    margin: "0",
    padding: "0",
    borderRadius: "6px",
    boxShadow: "4px 4px 10px 1px rgba(0, 0, 0, 0.15)",
    width: "",
    whiteSpace: "nowrap",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  option: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "#D3D3D3" : "#40506A",
    padding: "8px 16px",
    margin: "0",
    cursor: state.isDisabled ? "" : "pointer",
    backgroundColor: (state.isSelected && !state.isDisabled) ? "#D4D5D7" : "#EDEEF0",
    "&:hover": {
      backgroundColor: !state.isDisabled ? "#D4D5D7" : "",
    },
  }),
  singleValue: (provided, state) => {
    const color = "#40506A";
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const margin = 0;
    return { ...provided, opacity, transition, color, margin };
  },
};

export const MultiSelectDropdownStyles = {
  control: () => ({
    display: "flex",
    alignItems: "start",
    cursor: "pointer",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: "flex",
    svg: {
      fill: "#40506A",
    },
    div: {
      padding: 3,
      alignItems: "center",
    },
  }),
  indicatorSeparator: () => ({ opacity: 0 }),
  placeholder: () => ({
    color: "#40506A",
  }),
  valueContainer: () => ({
    display: "flex",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#EDEEF0",
    margin: "0",
    padding: "0",
    borderRadius: "6px",
    boxShadow: "4px 4px 10px 1px rgba(0, 0, 0, 0.15)",
    width: "",
    whiteSpace: "nowrap",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  // multiValue: () => ({}),
  option: (provided, state) => ({
    ...provided,
    color: "#40506A",
    padding: "8px 16px",
    margin: "0",
    cursor: "pointer",
    backgroundColor: state.isSelected ? "#D4D5D7" : "#EDEEF0",
    "&:hover": {
      backgroundColor: "#D4D5D7",
    },
    width: "100%",
  }),
  singleValue: (provided, state) => {
    const color = "#40506A";
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition, color };
  },
};

export const CalendarDropdownStyles = {
  control: () => ({
    display: "flex",
    alignItems: "start",
    cursor: "pointer",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: "flex",
    svg: {
      fill: "#40506A",
    },
    div: {
      padding: 3,
      alignItems: "center",
    },
  }),
  indicatorSeparator: () => ({ opacity: 0 }),
  placeholder: () => ({
    color: "#40506A",
  }),
  valueContainer: () => ({ display: "flex" }),
  menu: () => ({
    opacity: 0,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  singleValue: (provided, state) => {
    const color = "#40506A";
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition, color };
  },
};

export const RecipeSummaryDropdown = {
  container: () => ({
    border: "1px solid rgb(102, 112, 128)",
    borderRadius: "10px",
  }),
  control: () => ({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    margin: "0.1rem 0.5rem",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#40506A",
    padding: 0,
    margin: 0,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#FAFCFF",
    whiteSpace: "nowrap",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  option: (provided) => ({ ...provided, cursor: "pointer" }),
};

export const schedulingDropdown = {
  container: () => ({
    border: "1px solid rgb(178, 178, 178)",
    borderRadius: "10px",
    backgroundColor: "#FFF",
  }),
  control: () => ({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    margin: "0.1rem 0.5rem",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#B2B2B2",
    padding: 0,
    margin: 0,
  }),
  menu: (provided) => ({
    ...provided,
    opacity: 0,
    // backgroundColor: "#FAFCFF",
    // whiteSpace: "nowrap",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  option: (provided) => ({ ...provided, cursor: "pointer" }),
  singleValue: (provided, state) => {
    const color = "#B2B2B2";
    return { ...provided, color };
  },
};