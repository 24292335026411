import React, { useEffect, useState } from "react";
import {
  DayFirstHours,
  DaySecondHours,
  Weekdays,
} from "components/Common/staticData";

const CustomTimeSelection = ({
  customDateSelection,
  setCustomDateSelection,
  customTimeSelection,
  setCustomTimeSelection,
}: any) => {
  const grid = `grid grid-cols-12 gap-x-2 gap-y-4 items-center text-[#B2B2B2] font-bold my-4 whitespace-nowrap`;

  const selectDate = (date) => {
    let array = [...customDateSelection];
    var index = array.indexOf(date);
    if (index === -1) {
      array.push(date);
    } else {
      array.splice(index, 1);
    }

    setCustomDateSelection(array);
  };

  const selectTime = (hour) => {
    let array = [...customTimeSelection];
    var index = array.indexOf(hour);
    if (index === -1) {
      array.push(hour);
    } else {
      array.splice(index, 1);
    }

    setCustomTimeSelection(array);
  };

  return (
    <div>
      <div className="mt-10">
        <label className="text-primary font-bold">Custom Schedule:</label>
      </div>
      <hr className="w-1/2 my-5" />
      <div className={grid}>
        <label className={`text-primary font-bold`}>
          Date <br /> Selection:
        </label>
        <div className="flex flex-row border-primary border primary bg-white rounded-[10px] p-2 col-start-2 col-span-4 min-w-max">
          <div className="flex flex-row">
            {Weekdays.map((day: any, index: any) => (
              <div
                className={`cursor-pointer rounded-3xl py-1 w-14 text-center mr-3 text-sm
                ${
                  customDateSelection.includes(day)
                    ? "bg-primary text-[#EEF1F4]"
                    : "bg-[#EEF1F4] text-primary"
                } `}
                key={index}
                onClick={() => selectDate(day)}
              >
                {day}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={grid}>
        <label className={`text-primary font-bold`}>
          Time <br /> Selection:
        </label>
        <div className="flex flex-row border-primary border primary bg-white rounded-[10px] p-2 col-start-2 col-span-4 min-w-max">
          <div>
            <div className="flex flex-row">
              <p className="text-primary text-sm mt-1">AM</p>
              <div className="flex flex-col ml-3">
                <div className="flex flex-row mb-2">
                  {DayFirstHours.map((hour: any, index: any) => (
                    <div
                      className={`cursor-pointer rounded-3xl py-1 w-14 text-center mr-5 text-sm 
                      ${
                        customTimeSelection.includes(hour)
                          ? "bg-primary text-[#EEF1F4]"
                          : "bg-[#EEF1F4] text-primary"
                      } `}
                      key={index}
                      onClick={() => selectTime(hour)}
                    >
                      {hour}
                    </div>
                  ))}
                </div>
                <div className="flex flex-row">
                  {DaySecondHours.map((hour: any, index: any) => (
                    <div
                      className={`cursor-pointer rounded-3xl py-1 w-14 text-center mr-5 text-sm 
                    ${
                      customTimeSelection.includes(hour % 12)
                        ? "bg-primary text-[#EEF1F4]"
                        : "bg-[#EEF1F4] text-primary"
                    } `}
                      key={index}
                      onClick={() => selectTime(hour % 12)}
                    >
                      {hour}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-row mt-5">
              <p className="text-primary text-sm mt-1">PM</p>
              <div className="flex flex-col ml-3">
                <div className="flex flex-row mb-2">
                  {DayFirstHours.map((hour: any, index: any) => (
                    <div
                      className={`cursor-pointer rounded-3xl py-1 w-14 text-center mr-5 text-sm 
                    ${
                      customTimeSelection.includes(hour + 12)
                        ? "bg-primary text-[#EEF1F4]"
                        : "bg-[#DDE2E7] text-primary"
                    } `}
                      key={index}
                      onClick={() => selectTime(hour + 12)}
                    >
                      {hour}
                    </div>
                  ))}
                </div>
                <div className="flex flex-row">
                  {DaySecondHours.map((hour: any, index: any) => (
                    <div
                      className={`cursor-pointer rounded-3xl py-1 w-14 text-center mr-5 text-sm 
                    ${
                      customTimeSelection.includes((hour % 12) + 12)
                        ? "bg-primary text-[#EEF1F4]"
                        : "bg-[#DDE2E7] text-primary"
                    } `}
                      key={index}
                      onClick={() => selectTime((hour % 12) + 12)}
                    >
                      {hour}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTimeSelection;
