import React, { useState } from "react";

const Popup = ({
  setOpenModal,
  text,
  redirect,
  inputText,
  setInputText,
}: any) => {
  const [value, setValue] = useState("");
  return (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
          onClick={() => setOpenModal(false)}
        ></div>
        <div className="flex items-center min-h-screen">
          <div className="flex flex-col items-center justify-center relative w-full max-w-2xl p-8 mx-auto bg-white rounded-lg shadow-[2px_4px_9px_rgba(0,0,0,0.2)]">
            <p className="mb-1 text-base text-primary">{text}</p>
            {(inputText || inputText === "") && (
              <input
                type="text"
                className={`text-base text-primary-900 bg-none border-1 rounded-3xl my-3 py-1 px-3 mx-3.5 p-0 focus:ring-0 w-3/4`}
                placeholder={inputText ? inputText : "New Role Name"}
                onChange={(event) => {
                  setValue(event.target.value);
                }}
              />
            )}
            <div className="items-center gap-2 mt-3">
              <button
                className="bg-[#0177FB] text-white py-1 w-36 rounded-lg mr-10"
                onClick={() => {
                  if (inputText || inputText === "") {
                    setInputText(value);
                  }
                  redirect();
                  setOpenModal(false);
                }}
              >
                Confirm
              </button>
              <button
                className="bg-[#B2B2B2] text-white py-1 w-36 rounded-lg"
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
