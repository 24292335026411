import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useNavigate } from "react-router-dom";
import PageSubHeader from "components/IngredientView.tsx/PageSubHeader";
import OptionSelector from "components/Common/OptionSelector";
import { adminRole } from "components/Common/staticData";

const RecipeSetting = ({
  currentRole,
  setCurrentRole,
  disabled,
  newRole,
  setNewRole,
}: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  return (
    <div>
      <PageSubHeader title="Recipe Settings" />
      <div className="flex flex-row mt-10">
        <OptionSelector
          title="View"
          desc=""
          options={["Users", "Groups", "Organization"]}
          disabled={disabled}
          currentRole={currentRole}
          setCurrentRole={setCurrentRole}
          newRole={newRole}
          setNewRole={setNewRole}
        />
        <OptionSelector
          title="Create"
          desc=""
          options={["Create", "Edit", "Duplicate"]}
          disabled={disabled}
          currentRole={currentRole}
          setCurrentRole={setCurrentRole}
          newRole={newRole}
          setNewRole={setNewRole}
        />
      </div>
      <p className="font-bold text-lg mt-14">Control</p>
      <div className="flex flex-row mt-7">
        <OptionSelector
          title="Run ad hoc recipes"
          desc=""
          options={adminRole["Run ad hoc recipes"]}
          disabled={disabled}
          currentRole={currentRole}
          setCurrentRole={setCurrentRole}
          newRole={newRole}
          setNewRole={setNewRole}
        />
        <OptionSelector
          title="Activate scheduled recipes"
          desc=""
          options={adminRole["Activate scheduled recipes"]}
          disabled={disabled}
          currentRole={currentRole}
          setCurrentRole={setCurrentRole}
          newRole={newRole}
          setNewRole={setNewRole}
        />
      </div>
      <div className="flex flex-row mt-14 mb-10">
        <OptionSelector
          title="Delete Recipes"
          desc=""
          options={adminRole["Delete Recipes"]}
          disabled={disabled}
          currentRole={currentRole}
          setCurrentRole={setCurrentRole}
          newRole={newRole}
          setNewRole={setNewRole}
        />
        <OptionSelector
          title="Deactive scheduled recipes"
          desc=""
          options={adminRole["Deactive scheduled recipes"]}
          disabled={disabled}
          currentRole={currentRole}
          setCurrentRole={setCurrentRole}
          newRole={newRole}
          setNewRole={setNewRole}
        />
      </div>
    </div>
  );
};

export default RecipeSetting;
