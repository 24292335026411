import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../app/store";
import { useParams } from "react-router-dom";
import { setUser, signOut } from "../features/auth/authSlice";
import { toast } from "react-toastify";
import { listIngredients } from "features/ingredients/ingredientSlice";

const AuthView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const token = useSelector((state: any) => state.auth.token);
  const refresh = useSelector((state: any) => state.auth.refresh);
  let location: any = useLocation();
  const recipeId = location?.state?.id ? location?.state?.id : null;

  useEffect(() => {
    let tokenData: any = {};

    if (token === "null" || token === null) {
      toast.error("Error logging in. Please try again", {
        position: "top-center",
        autoClose: 8080,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/");
    } else {
      localStorage.setItem("Authorization", token);
      localStorage.setItem("RefreshToken", refresh)
      tokenData = jwt_decode(token);

      const currentTime = Date.now() / 1000;
      if (tokenData.exp > currentTime) {
        let userRole = tokenData.payload.roles[0];
        userRole.resources = JSON.parse(userRole.resources);
        let user: any = {
          name: tokenData.payload.name,
          // email: tokenData.email,
          userID: tokenData.payload.id,
          picture: tokenData.payload.imageUrl,
          domain: tokenData.payload.organisation?.domain,
          tenant: tokenData.payload.organisation?.id,
          role: userRole,
          groups: tokenData.payload.groups,
          tc_verified: tokenData?.payload?.tc_verified,
        };
        toast.success("Successfully logged in", {
          position: "bottom-right",
          autoClose: 8080,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(setUser(user)).then((res) => {
          dispatch(listIngredients()).then(() => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            'event': 'log_in',
            'user_id': res.payload.userID
            });
            if(recipeId) {
              navigate("/recipe_summary/", { state: { id: recipeId, edit: true } });
            } else {
              navigate("/recipes");
            }            
          });
        });
      } else {
        dispatch(signOut());
        navigate("/");
      }
    }
  }, []);

  return <div></div>;
};

export default AuthView;
