import axios, {AxiosError} from "axios";
import config from "components/Common/config";

class ApiOperations {
  service: any;
  // token = ;
  constructor() {
    let service = axios.create({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        // Authorization: "Bearer " + this.token,
      },
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess(response: any) {
    return response;
  }
  // handleError = (error: any) => {
  //   // switch (error.response.status) {
  //   //   case 401:
  //   //     this.redirectTo(document, "/");
  //   //     break;
  //   //   case 404:
  //   //     this.redirectTo(document, "/");
  //   //     break;
  //   //   default:
  //   //     this.redirectTo(document, "/");
  //   //     break;
  //   // }
  //   return Promise.reject(error);
  // };


  handleError = async (error: AxiosError) : Promise<AxiosError>  => {

    if(error.response){
      console.log(error.response);
      if (
        error.response.status == 401 && (error.response.data['detail'] == "Invalid token" || error.response.statusText == "Unauthorized")
      ) {

        console.log('call to refresht token')
        const refreshTokens = localStorage.getItem("RefreshToken");
  
        try {
          const result =  await axios.post(`${config.apiGateway.tenantURL}/api/v1/users/auth/refresh`,{
            refreshToken: refreshTokens
          }).then((data) => data)
          
          const {accessToken, refreshToken} = result.data;
 
          localStorage.setItem("Authorization", accessToken);
          localStorage.setItem("RefreshToken", refreshToken);  
          return;
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(error);
  };

  redirectTo = (document: any, path: any) => {
    document.location = path;
  };

  get(path: any, callback: any) {
    return this.service
      .get(path, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      })
      .then((response: any) => callback(response.status, response.data))
      .catch((response: any) =>
        callback(response.response.status, response.message)
      );
  }

  post(path: any, payload: any, callback: any) {
    return this.service
      .request({
        method: "POST",
        url: path,
        responseType: "json",
        data: payload,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      })
      .then((response: any) => callback(response.status, response.data))
      .catch((response: any) =>
        callback(response.response.status, response.message)
      );
  }

  patch(path: any, payload: any, callback: any) {
    return this.service
      .request({
        method: "PATCH",
        url: path,
        responseType: "json",
        data: payload,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      })
      .then((response: any) => callback(response.status, response.data))
      .catch((response: any) =>
        callback(response.response.status, response.message)
      );
  }

  delete(path: any, callback: any) {
    return this.service
      .request({
        method: "DELETE",
        url: path,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      })
      .then((response: any) => callback(response.status, response.data))
      .catch((response: any) =>
        callback(response.response.status, response.message)
      );
  }
}

export default new ApiOperations();
