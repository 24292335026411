import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useLocation, useNavigate } from "react-router-dom";

import PageHeader from "components/PageHeader/PageHeader";
import { DafaultButton } from "components/Common/Button";
import { ArrowBack } from "@mui/icons-material";
import { ArrowForward } from "@mui/icons-material";
import setLoading from "features/helper";
import { createUser, updateUser } from "features/users/userSlice";
import UserControl from "components/Roles/UserControl";
import RecipeSetting from "components/Roles/RecipeSetting";
import Select from 'react-select'

import _ from "lodash";
import { listGroups } from "features/groups/groupSlice";
import { listRoles } from "features/roles/roleSlice";
import { toast } from "react-toastify";

const UserSummary = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  let location: any = useLocation();

  const userId = location?.state?.id;
  const users = useSelector((state: any) => state.user.user);
  const currentUser = useSelector((state: any) => state.auth.user);
  const roles = useSelector((state: any) => state.roles.rolesList);
  const groups = useSelector((state: any) => state.groups.groupsList);
  
  const fetchAllGroups = groups?.map(function(item) {
    return {value: item['name'], label: item['name']};
  });

  const fetchAllRoles = roles?.map(function(item) {
    return {resources: JSON.parse(item['resources']), value: item['name'], label: item['name'], id: item['id']};
  });
  
  const fetchUserGroups = users?.groups.map(function(item) {
    return item['name'] ? {value: item['name'], label: item['name']} : {value: item, label: item};
  });

  const [userName, setUserName] = useState(users?.name ? users.name : "");
  const [userEmail, setUserEmail] = useState(users?.email ? users.email : "");
  const [userRole, setUserRole] = useState<any>(
    users?.roles ? users.roles : undefined
  );
  const [userGroup, setUserGroup] = useState<any>(
    fetchUserGroups ? fetchUserGroups : []
  );
  const [selectedRole, setSelectedRole] = useState<any>({  
    value: users?.roles ? users.roles[0]['name'] : '',
    label: users?.roles ? users?.roles[0]['name'] : '',
    id: users?.roles ? users?.roles[0]['id'] : '',
    resources: users?.roles ? users?.roles[0]['resources'] : {}
  });

  useEffect(() => {
    setLoading(true);
    dispatch(listGroups(currentUser.tenant)).then(() => {
      dispatch(listRoles()).then(() => {
        setLoading(false);
      });
    });
  }, []);
  

  const handleNextButton = () => {
    
    setLoading(true);
    if (userName !== "" && userEmail !== "" && userGroup.length !== 0) {      
      if(userId) {
        let updatedUser = {}
        updatedUser['userId'] = userId;
        updatedUser['roles'] = [selectedRole.value];
        updatedUser['groups'] = userGroup.map((function(item) {return item.value}));
        updatedUser['tenantId'] = users.organisation.id
        console.log('GROUPS', updatedUser['groups']);
        dispatch(updateUser({id: userId, payload: updatedUser})).then(() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'edit_user',
            'edited_user_id': userId,
            'user_id': currentUser.userID,
            'tenant_id': currentUser.tenant,
            'group_name': updatedUser['groups'],
            'role_name': selectedRole.value
          });             
          setLoading(false);
          toast.success(`Updated User. Please login again to reflect changes.`, {
            position: "bottom-right",
            autoClose: 8080,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/users");
        });
      } else {
        let finalUser: any = _.cloneDeep(users);
        finalUser.name = userName;
        finalUser.email = userEmail;
        finalUser.groups = userGroup.map((function(item) {return item.value}));
        finalUser.is_new = true;
        finalUser.roles = [selectedRole.value];
        dispatch(createUser(finalUser)).then(() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'new_user_created',
            'user_id': currentUser.userID,
            'tenant_id': currentUser.tenant,
            'group_name': userGroup[0].label,
            'role_name': selectedRole.value
          });
          setLoading(false);
          navigate("/users");
        });
      }      
    }
  };

  return (
    <div className="p-[0_2.5%] w-[82%] ml-[18%]">
      <PageHeader title={"Create a New User"} />
      <div className="my-16">
        <div className="flex flex-row">
          <div className="w-full">
            <label className="block mb-2 text-base font-bold text-primary">
              Name:
            </label>
            <input
              disabled={true}
              type="text"
              id="first_name"
              className={`bg-gray-50 border border-gray-300 text-primary text-sm rounded-lg block 
          p-2.5 w-2/3`}
              placeholder="Untitled"
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              required
            />
          </div>
          <div className="w-full">
            <label className="block mb-2 text-base font-bold text-primary">
              Role:
            </label>
            <Select
              defaultValue={selectedRole}
                options={fetchAllRoles} 
                className="w-1/2" 
                onChange={(e) => {setSelectedRole(e)}} 
              />
          </div>
        </div>
        <div className="flex flex-row mt-10">
          <div className="w-full">
            <label className="block mb-2 text-base font-bold text-primary">
              Email:
            </label>
            <input
              disabled={true}
              type="text"
              id="first_name"
              className={`bg-gray-50 border border-gray-300 text-primary text-sm rounded-lg block 
          p-2.5 w-2/3`}
              placeholder="Untitled"
              value={userEmail}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              required
            />
          </div>
          <div className="w-full">
            <label className="block mb-2 text-base font-bold text-primary">
              Group:
            </label>
            <Select
              defaultValue={userGroup}
                options={fetchAllGroups} 
                isMulti 
                className="w-1/2" 
                onChange={(e) => {setUserGroup(e)}} 
              />
          </div>
        </div>
      </div>
      <UserControl
        currentRole={_.cloneDeep(selectedRole)}
        setCurrentRole={setSelectedRole}
        disabled={true}
      />
      <RecipeSetting
        currentRole={_.cloneDeep(selectedRole)}
        setCurrentRole={setSelectedRole}
        disabled={true}
      />
      <div className="flex flex-row justify-between my-20 mr-[16.67%]">
        <DafaultButton
          text="Back"
          startIcon={<ArrowBack />}
          color={"primary"}
          onClick={() => navigate(-1)}
          outlined
        />

        <DafaultButton
          text={userId ? "Update User" : "Create new User"}
          endIcon={<ArrowForward />}
          color={"success"}
          onClick={() => handleNextButton()}
          textColor="white"
          width="20rem"
        />
      </div>
    </div>
  );
};

export default UserSummary;
