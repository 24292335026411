import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import groupService from "./groupService";
// import { toast } from "react-toastify";

const initialState = {
  groupsList: [],
  group: {
    name: "",
    active: "true",
    description: "",
    tenantId: null,
    users: [],
  },
};

export const createGroup = createAsyncThunk(
  "groups/createGroup",
  async (payload: any, thunkAPI) => {
    try {
      return await groupService.createGroup(payload);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const updateGroup = createAsyncThunk(
  "groups/updateGroup",
  async ({ id, payload }: any, thunkAPI) => {
    try {
      return await groupService.updateGroup(id, payload);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const resetGroup = createAsyncThunk(
  "groups/resetGroup",
  async (thunkAPI) => {
    try {
      return;
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const listGroups = createAsyncThunk("groups/list", async (tenantId: any, thunkAPI) => {
  try {
    return await groupService.listGroups(tenantId);
  } catch (error: any) {
    const message: any =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return message;
  }
});

export const deleteGroup = createAsyncThunk(
  "groups/deleteGroup",
  async (id: any, thunkAPI) => {
    try {
      return await groupService.deleteGroup(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const getGroup = createAsyncThunk(
  "groups/getGroup",
  async (id: any, thunkAPI) => {
    try {
      return await groupService.getGroup(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const setGroupDetail = createAsyncThunk(
  "groups/setGroupDetail",
  async (payload: any, thunkAPI) => {
    try {
      return payload;
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const setGroupUsers = createAsyncThunk(
  "groups/setGroupUsers",
  async (payload: any, thunkAPI) => {
    try {
      return payload;
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const GroupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    reset: (state) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(createGroup.fulfilled, (state, action) => {
        state.group = initialState.group;
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        state.group = initialState.group;
      })
      .addCase(resetGroup.fulfilled, (state, action) => {
        state.group = initialState.group;
      })
      .addCase(listGroups.fulfilled, (state, action) => {
        state.groupsList = action.payload;
      })
      .addCase(setGroupDetail.fulfilled, (state, action) => {
        state.group.name = action.payload.name;
        state.group.description = action.payload.description;
      })
      .addCase(setGroupUsers.fulfilled, (state, action) => {
        for (const users in action.payload) {
          state.group.users.push(users);
        }
      })
      .addCase(getGroup.fulfilled, (state, action) => {
        state.group = action.payload;
      });
  },
});

export const { reset } = GroupSlice.actions;
export default GroupSlice.reducer;
