import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useLocation, useNavigate } from "react-router-dom";

import PageHeader from "components/PageHeader/PageHeader";
import { DafaultButton } from "components/Common/Button";
import { ArrowBack } from "@mui/icons-material";
import { ArrowForward } from "@mui/icons-material";
import setLoading from "features/helper";
import { getRole, listRoles } from "features/roles/roleSlice";
import { listGroups } from "features/groups/groupSlice";
import { setUserDetail } from "features/users/userSlice";

const UserCreation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const currentUser = useSelector((state: any) => state.auth.user);

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setUserRole] = useState<any>({});
  const [userGroup, setUserGroup] = useState<any>([]);

  const [isNext, setIsNext] = useState(false);

  const [groupsList, setGroupsList] = useState([]);
  const [rolesList, setRolesList] = useState([]);

  useEffect(() => {
    let groupsListTemp = [];
    let rolesListTemp = [];
    setLoading(true);
    dispatch(listRoles()).then((roles) => {
      dispatch(listGroups(currentUser.tenant)).then((groups) => {
        setLoading(false);
        groups.payload.map((data: any, index: any) => {
          groupsListTemp.push({ value: data.id, label: data.name });
        });
        roles.payload.map((data: any, index: any) => {
          rolesListTemp.push({ value: data.id, label: data.name });
        });
        setGroupsList(groupsListTemp);
        setRolesList(rolesListTemp);
      });
    });
  }, []);

  const handleNextButton = () => {
    let finalUser: any = {};
    setLoading(true);
    if (
      userName !== "" &&
      userEmail !== "" &&
      userGroup.length !== 0
    ) {
      finalUser.name = userName;
      finalUser.email = userEmail;
      finalUser.groups = userGroup.map(function (data) {
        return data.label;
      });
      dispatch(getRole(userRole.value)).then((res) => {
        finalUser.roles = [res.payload];
        dispatch(setUserDetail(finalUser)).then(() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'add_user_details',
            'user_id': currentUser.userID,
            'tenant_id': currentUser.tenant,
            'group_name': userGroup[0].label,
            'role_name': finalUser.roles[0].name
          });
          setLoading(false);
          navigate("/edit_user_control");
      });
      })
    }
    setLoading(false);
  };

  return (
    <div className="p-[0_2.5%] w-[82%] ml-[18%]">
      <PageHeader title={"Create a New User"} />
      <div className="mt-16">
        <label className="block mb-2 text-base font-bold text-primary">
          Name:
        </label>
        <input
          type="text"
          id="first_name"
          className={`bg-gray-50 border border-gray-300 text-primary text-sm rounded-lg block 
          p-2.5 w-1/3`}
          placeholder="Untitled"
          value={userName}
          onChange={(e) => {
            setUserName(e.target.value);
          }}
          required
        />
        {isNext && userName === "" ? (
          <span className="flex items-center font-medium tracking-wide text-red text-xs mb-10 mt-1 ml-1">
            Please provide a valid User Name
          </span>
        ) : (
          <div className="mb-10"></div>
        )}
        <label className="block mb-2 text-base font-bold text-primary">
          Email:
        </label>
        <input
          type="text"
          className={`bg-gray-50 border border-gray-300 text-primary text-sm rounded-lg block 
          pl-2.5 w-1/3`}
          placeholder="Untitled"
          value={userEmail}
          onChange={(e) => {
            setUserEmail(e.target.value);
          }}
          required
        />
        {isNext && userEmail === "" ? (
          <span className="flex items-center font-medium tracking-wide text-red text-xs mb-10 mt-1 ml-1">
            Please provide a valid Group Name
          </span>
        ) : (
          <div className="mb-10"></div>
        )}
        <label className="block mb-2 text-base font-bold text-primary">
          Role:
        </label>
        <Select
          options={rolesList}
          placeholder={""}
          className="w-1/3 border-black"
          isSearchable={false}
          menuPortalTarget={document.body}
          onChange={(selected) => {
            setUserRole(selected);
          }}
        />
        {isNext ? (
          <span className="flex items-center font-medium tracking-wide text-red text-xs mb-10 mt-1 ml-1">
            Please provide a valid Group Name
          </span>
        ) : (
          <div className="mb-10"></div>
        )}
        <label className="block mb-2 text-base font-bold text-primary">
          Group:
        </label>
        <Select
          isMulti
          options={groupsList}
          placeholder={""}
          className="w-1/3 border-black"
          isSearchable={false}
          menuPortalTarget={document.body}
          onChange={(selected) => {
            setUserGroup(selected);
          }}
        />
        {isNext && userGroup.length === 0 ? (
          <span className="flex items-center font-medium tracking-wide text-red text-xs mb-10 mt-1 ml-1">
            Please provide a valid Group Name
          </span>
        ) : (
          <div className="mb-10"></div>
        )}
      </div>
      <div className="flex flex-row justify-between my-20 mr-[16.67%]">
        <DafaultButton
          text="Back"
          startIcon={<ArrowBack />}
          color={"primary"}
          onClick={() => navigate(-1)}
          outlined
        />

        <DafaultButton
          text={"Next Step"}
          endIcon={<ArrowForward />}
          color={"success"}
          onClick={() => handleNextButton()}
          textColor="white"
          width="20rem"
        />
      </div>
    </div>
  );
};

export default UserCreation;
