import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ingredientsService from "./ingredientService";

const initialState = {
  ingredientList: [],
  ingredientDetail: {},
};

// ingredientSlice
export const listIngredients = createAsyncThunk(
  "ingredient/list",
  async (thunkAPI) => {
    try {
      return ingredientsService.listIngredients();
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

// ingredient detail
export const viewIngredient = createAsyncThunk(
  "ingredients/ingreDetail",
  async (id: any, thunkAPI) => {
    try {
      return ingredientsService.viewIngredient(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return message;
    }
  }
);

export const ingredientSlice = createSlice({
  name: "ingredient",
  initialState,
  reducers: {
    reset: (state) => {
      state.ingredientList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listIngredients.fulfilled, (state, action) => {
      state.ingredientList = action.payload;
    });
    builder.addCase(viewIngredient.fulfilled, (state, action) => {
      state.ingredientDetail = action.payload;
    });
  },
});

export const { reset } = ingredientSlice.actions;
export default ingredientSlice.reducer;
