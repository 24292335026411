import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import recipeReducer from "../features/recipe/recipeSlice";
import ingredientReducer from "../features/ingredients/ingredientSlice";
import loaderReducer from "../features/loader/loaderSlice";
import roleReducer from "../features/roles/roleSlice";
import groupReducer from "../features/groups/groupSlice";
import userReducer from "../features/users/userSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    recipe: recipeReducer,
    ingredient: ingredientReducer,
    loader: loaderReducer,
    roles: roleReducer,
    groups: groupReducer,
    user: userReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
