import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ArrowDown } from "assets/icons/Icons";
import React, { useState } from "react";
import { RecipeSummaryData } from "components/Common/staticData";
import Select from "react-select";

const Dropdown = ({ options, label, fieldData }: any) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  // const [filterOptions, setFilterOptions] = useState(options);

  let structuredOptions = options.map((item) => {
    return { label: item, value: item };
  });

  return (
    <div className="text-xs my-4">
      <div className="flex items-center text-sm my-1">
        <p className="font-semibold mr-2 min-w-max">{label}</p>
        <button className="cursor-pointer mr-2" onClick={() => setShowDetails(!showDetails)}>
          <InfoOutlinedIcon style={{ fontSize: "1rem" }} />
        </button>
        {showDetails ? <p className="font-medium text-[#808080]">{fieldData.description}</p> : null}
        {error && <div className="text-red">{RecipeSummaryData.ErrorMsg}</div>}
      </div>
      <Select
        options={structuredOptions}
        className="w-full"
        onChange={(e: any) => {
          console.log(e.label);
          fieldData.fieldValues[fieldData.index][fieldData.fieldName] = e.label;
          fieldData.setFieldValues(fieldData.fieldValues);
        }}
      />
      {/* <div
        onClick={() => {
          setOpen(!open)
        }}
        className={`flex flex-row justify-between items-center cursor-pointer text-primary border px-4 py-2 w-full h-10 
        ${
          open
            ? "rounded-[0.5rem_0.5rem_0_0] border-gray-300 border-t-gray-300"
            : "rounded-lg"
        }`}
      >
        <div className="flex items-center justify-start">
          {fieldData?.fieldValues[fieldData.index][fieldData.fieldName]}
        </div>
        <ArrowDown />
      </div>
      <div className={`${open ? 'border-[0px_1px_1px_1px] border-b-gray-300' : ''} rounded-[0px_0px_0.5rem_0.5rem] overflow-y-auto max-h-48`}>
      {open &&
        options?.map((option, i) => (
          <div
            onClick={() => {
              fieldData.fieldValues[fieldData.index][fieldData.fieldName] =
                option;
              fieldData.setFieldValues(fieldData.fieldValues);
              setOpen(!open);
            }}
            key={i}
            className={`bg-white flex flex-row items-center border-y-[1px]
                        text-primary px-8 py-2 h-10 cursor-pointer focus:bg-gray border-y-[#B2B2B2]
              ${
                options.length === i + 1
                  ? "rounded-[0_0_0.5rem_0.5rem]"
                  : ""
              }`}
          >
            {option}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Dropdown;
