import React, { useEffect } from "react";
import { ReactComponent as ScheduledIcon } from "../../assets/images/Scheduled.svg";
import { ReactComponent as ManualIcon } from "../../assets/images/Manual.svg";
import IngredientPlatforms from "components/Common/IngredientPlatforms";
import IngredientCardCheckBox from "components/Common/IngredientCardCheckBox";
import { useNavigate } from "react-router-dom";
import AdbIcon from '@mui/icons-material/Adb';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

const IngredientCard = ({
  id,
  name,
  desc,
  manual,
  platformsFrom,
  platformsTo,
  onClick,
  onIngredientSelect,
  onIngredientAdd,
  counter,
  readmore,
  lineHeight,
  check,
  disabled,
  ready
}: any) => {
  const navigate = useNavigate();

  const boxShadow = check
    ? "ingredientCardBoxshadowBlue"
    : "ingredientCardBoxshadowGray";

  const icon = manual ? <ManualIcon /> : <ScheduledIcon />;
  const readyIcon = ready ? <CheckCircleIcon style={{ color:'#50C878' }} /> 
                          : <HourglassTopIcon style={{ color:'#808080' }} />

  return (
    <div
      className={`flex flex-col justify-between shadow-ingredientCardBoxshadowGray 
      border-ingredientCardBorder border-[0px] rounded-[10px] p-6 min-h-full min-w-full ${
        disabled ? "bg-gray" : "bg-white"
      }`}
    >
      <div>
        <div className="flex items-center justify-between mb-3 h-6">
          {icon}          
          {check && (
            <div className="flex flex-row items-center">
              {counter > 0 && (
                <div
                  className="bg-[#F0F0F0] rounded-2xl px-2 py-0.5 cursor-pointer"
                  onClick={onIngredientAdd}
                >
                  <p className="text-sm	font-semibold text-primary">
                    +{counter}
                  </p>
                </div>
              )}
              <IngredientCardCheckBox
                handleChange={onIngredientSelect}
                counter={counter}
                disabled={disabled}
              />
            </div>
          )}
        </div>
        <div onClick={onClick}>
          <div className={"font-bold text-lg text-primary mb-3"}>{name}</div>
          <div className={"mb-3.5"}>
            <div className={`text-[#6F767E] line-clamp-3`}>{desc}</div>
            {readmore && (
              <div
                className="text-[#0177FB] text-left cursor-pointer"
                onClick={() => navigate(`/ingredients/${id}`)}
                id={lineHeight}
              >
                read more
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between flex-wrap" onClick={onClick}>
        <IngredientPlatforms
          platformsFrom={platformsFrom}
          platformsTo={platformsTo}
        />
        {readyIcon}
      </div>
    </div>
  );
};

export default IngredientCard;
