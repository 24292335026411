import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { RecipeSummaryData } from "components/Common/staticData";
import React, { useState } from "react";

const TextArea = ({ label, text = "", fieldData }: any) => {
  const [error, setError] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  let setCurrentValue;
  if(typeof fieldData.fieldValues[fieldData.index][fieldData.fieldName] == "object")  {
    fieldData.fieldValues[fieldData.index][fieldData.fieldName] = JSON.stringify(fieldData.fieldValues[fieldData.index][fieldData.fieldName]);
    fieldData.setFieldValues(fieldData.fieldValues);
    setCurrentValue = JSON.stringify(fieldData.fieldValues[fieldData.index][fieldData.fieldName]);
  } else {
    setCurrentValue = fieldData.fieldValues[fieldData.index][fieldData.fieldName];
  }

  return (
    <div className="my-4">
      <div className="flex items-center text-sm my-1">
        <p className="font-semibold mr-2 min-w-max">{label}</p>
        <button
          className="cursor-pointer mr-2"
          onClick={() => setShowDetails(!showDetails)}
        >
          <InfoOutlinedIcon style={{ fontSize: "1rem" }} />
        </button>
        {showDetails ? (
          <p className="font-medium text-[#808080]">{fieldData.description}</p>
        ) : null}
        {error && <div className="text-red">{RecipeSummaryData.ErrorMsg}</div>}
      </div>
      <div className="flex justify-between gap-2">
        <div className="w-full border border-primary rounded-md p-3">
          <textarea
            value={fieldData.fieldValues[fieldData.index][fieldData.fieldName]}
            onChange={(e) => {
              fieldData.fieldValues[fieldData.index][fieldData.fieldName] =
              typeof e.target.value == "object"
                ? JSON.stringify(e.target.value) : e.target.value;
              fieldData.setFieldValues(fieldData.fieldValues);
            }}
            rows={4}
            className={`w-full border-0 focus:ring-0 text-xs text-primary p-0`}
          />
          {error && (
            <div
              className={`bg-red/[0.15] absolute top-0 bottom-0 left-0 right-0`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TextArea;
