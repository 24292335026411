import React, { useEffect, useState } from "react";
import { ArrowDown } from "assets/icons/Icons";
import _ from "lodash";
import { DafaultButton } from "components/Common/Button";
import { Dropzone, FileMosaic } from "@files-ui/react";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import MultiSelect from "components/Common/MultiSelect";
import setLoading from "features/helper";
import TextField from "components/Recipes/RecipeSummary/TextField";
import Dropdown from "components/Recipes/RecipeSummary/Dropdown";
import {
  excelSheetToSpreadsheet,
  excelSheetToSpreadsheet2,
  excelSheetToSpreadsheetTF,
  generateCMTMConfigSheet,
  qa_campaign,
  qa_trafficking_sheet,
  trafficking_sheet_qa_sheet_builder,
} from "./CMToManyFunction";
import { supportedDestinations } from "./CMToManyConfig";

const CMToManyMain = ({ recipe, metadata, fieldValues, setFieldValues }: any) => {
  const currentUserID = useSelector((state: any) => state.auth.user.userID);

  const [open, setOpen] = useState(true);
  const [isTraffickingSheet, setIsTraffickingSheet] = useState(false);
  const [qaMessage, setQaMessage] = useState(
    fieldValues
      ? fieldValues[0]["supportedDestinations"]
        ? fieldValues[0]["supportedDestinations"].length > 0
          ? "QA Completed"
          : ""
        : ""
      : ""
  );
  const [selectedDestinations, setSelectedDestinations] = useState(
    fieldValues[0]["supportedDestinations"] ? fieldValues[0]["supportedDestinations"] : []
  );
  const [configFile, setConfigFile] = useState([]);
  const [assetFiles, setAssetFiles] = useState([]);
  const [tfSheet, setTfSheet] = useState(null);
  const [tfSheetQA, setTfSheetQA] = useState("");

  const updateFilesTS = async (incommingFiles) => {
    if (incommingFiles.length == 0) {
      setTfSheetQA("");
      setAssetFiles(incommingFiles);
      return;
    }
    setAssetFiles(incommingFiles);

    const file = incommingFiles[0];
    let finalDataSheet = {};

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "array" });

      workbook.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        finalDataSheet[sheetName] = jsonData;
      });

      const res = await excelSheetToSpreadsheetTF(finalDataSheet, currentUserID);
      console.log("RES: ", res);
      console.log(finalDataSheet);
      setTfSheet(res);
      setLoading(false);
    };
    reader.readAsArrayBuffer(file.file);
  };

  const updateFiles = async (incommingFiles) => {
    if (incommingFiles.length == 0) {
      setConfigFile(incommingFiles);
      return;
    }
    setConfigFile(incommingFiles);
    const file = incommingFiles[0];

    let finalDataSheet = {};

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "array" });

      workbook.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        finalDataSheet[sheetName] = jsonData;
      });

      if (fieldValues[0]["command"] === "Load Entities in Destinations") {
        const res = await excelSheetToSpreadsheet2(finalDataSheet, currentUserID);
        console.log("RES: ", res);
        //   setFinalSheetURL(res);

        let tempFieldValues = _.cloneDeep(fieldValues);
        tempFieldValues[0]["spreadsheetURL"] = res;
        setFieldValues(tempFieldValues);
      } else {
        const res = await excelSheetToSpreadsheet(finalDataSheet, currentUserID);
        console.log("RES: ", res);
        //   setFinalSheetURL(res);

        let tempFieldValues = _.cloneDeep(fieldValues);
        tempFieldValues[0]["destinationMetadataSheet"] = res;
        setFieldValues(tempFieldValues);
      }

      setLoading(false);
    };
    reader.readAsArrayBuffer(file.file);
  };

  const fetchCmEntities = async () => {
    const profileId = fieldValues[0].profileId;
    const campaignId = fieldValues[0].campaignID;
    let qa = await qa_campaign(profileId, campaignId, currentUserID);
    setQaMessage(qa);
  };

  const qaTraffickingSheet = async () => {
    let qa = await qa_trafficking_sheet(tfSheet, currentUserID);
    setTfSheetQA(qa);
  };

  const generateConfigSheet = async () => {
    const profileId = fieldValues[0].profileId;
    const campaignId = fieldValues[0].campaignID;
    generateCMTMConfigSheet(
      profileId,
      campaignId,
      fieldValues[0].supportedDestinations,
      currentUserID
    );
  };

  return (
    <div className="rounded-[10px] shadow-ingreSummaryBox bg-white py-5 px-7">
      {fieldValues.length > 0 ? (
        <>
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={() => setOpen(!open)}
          >
            <div className="flex gap-16">
              <p className="font-bold text-lg">{metadata.title}</p>
            </div>
            <ArrowDown />
          </div>
          {open && (
            <div className="text-primary mt-3 my-6">
              <p className="font-normal text-sm">{metadata.description}</p>
              <div className="flex flex-col justify-center w-full border border-primary rounded-xl p-3 my-8">
                {metadata?.instructions?.map((instruction: any, index: any) => (
                  <div key={index} className="flex flex-row items-center my-2">
                    <div className="flex justify-center items-center w-8 h-8 rounded-full bg-slate-300 mr-5">
                      <p>{index}</p>
                    </div>
                    <div
                      className="[&_a]:text-[#003366] [&_a]:font-medium"
                      dangerouslySetInnerHTML={{ __html: `${instruction}` }}
                    />
                  </div>
                ))}
              </div>

              <div className="flex flex-col justify-start gap-x-2 ml-8 mb-5">
                <div className="flex flex-row items-center justify-start gap-x-2 mb-5">
                  <span className="text-sm">Select Campaign</span>
                  <label className="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={isTraffickingSheet}
                      onChange={(e) => {
                        setIsTraffickingSheet(!isTraffickingSheet);
                      }}
                    />
                    <div
                      className={`w-10 h-6 border-2 rounded-full left-2
                    peer-checked:after:translate-x-full after:absolute after:top-1 after:left-[5px]
                    after:bg-primary after:rounded-full after:h-4 after:w-4 after:transition-all`}
                    />
                  </label>
                  <span className="text-sm">Trafficking Sheet</span>
                </div>

                <div>
                  {!isTraffickingSheet ? (
                    <div>
                      <TextField
                        type={"string"}
                        label={"CM360 User Profile ID"}
                        fieldData={{
                          fieldValues: _.cloneDeep(fieldValues),
                          setFieldValues: setFieldValues,
                          index: 0,
                          fieldName: "profileId",
                          description: "CM360 Profile ID of the user",
                        }}
                      />
                      <TextField
                        type={"string"}
                        label={"CM360 Campaign ID"}
                        fieldData={{
                          fieldValues: _.cloneDeep(fieldValues),
                          setFieldValues: setFieldValues,
                          index: 0,
                          fieldName: "campaignID",
                          description: "CM360 Campaign ID of the user",
                        }}
                      />
                      <DafaultButton
                        text={"QA CM360 Entities"}
                        color={"info"}
                        textColor={"#FFFFFF"}
                        radius={"6px"}
                        onClick={() => fetchCmEntities()}
                        disabled={
                          fieldValues[0].profileID === "" || fieldValues[0].campaignID === ""
                        }
                      />
                      <p
                        className={`mt-2 mb-5 font-semibold ${
                          qaMessage === "QA Completed" ? "text-[#14A12B]" : "text-[#BF0303]"
                        }`}
                      >
                        {qaMessage}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <Dropzone
                        style={{ height: "40%", flexBasis: "25%", marginBottom: "2%" }}
                        onChange={updateFilesTS}
                        value={assetFiles}
                        label="Drag and drop Excel Sheet here, or click to upload"
                      >
                        {assetFiles.map((file, i) => (
                          <FileMosaic key={i} {...file} preview />
                        ))}
                      </Dropzone>
                      <DafaultButton
                        text={"QA CM360 Trafficking Sheet"}
                        color={"info"}
                        textColor={"#FFFFFF"}
                        radius={"6px"}
                        onClick={() => qaTraffickingSheet()}
                        disabled={assetFiles.length === 0}
                      />
                      {assetFiles.length > 0 && tfSheetQA !== "" ? (
                        tfSheetQA === "QA Completed, no Error" ? (
                          <p className={`mt-2 mb-5 font-semibold text-[#14A12B]`}>
                            {" "}
                            QA Completed, No Errors{" "}
                          </p>
                        ) : (
                          <>
                            <div className="mb-2" />
                            <span className={`mt-2 mb-5 font-semibold text-[#BF0303]`}>
                              Please Download to see errors:
                            </span>
                            <span
                              className={`mt-2 mb-5 font-semibold text-[#0000EE] cursor-pointer`}
                              onClick={() => trafficking_sheet_qa_sheet_builder(tfSheetQA)}
                            >
                              {"  "}Trafficking Sheet Errors.xlsx
                            </span>
                          </>
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  {(qaMessage !== "QA Completed" && !isTraffickingSheet) ||
                  (tfSheetQA !== "QA Completed, no Error" && assetFiles.length === 0 && isTraffickingSheet) ? (
                    <></>
                  ) : (
                    <>
                      {(qaMessage === "QA Completed" && !isTraffickingSheet) ||
                      (tfSheetQA === "QA Completed, no Error" && isTraffickingSheet) ? (
                        <>
                          <p className="font-semibold mr-2 mt-4 min-w-max text-sm">
                            Select Destinations
                          </p>
                          <MultiSelect
                            placeholderText="Please Select Destinations"
                            options={supportedDestinations}
                            selected={selectedDestinations}
                            onChange={(e) => {
                              let tempFieldValues = _.cloneDeep(fieldValues);
                              tempFieldValues[0]["supportedDestinations"] = e;
                              setFieldValues(tempFieldValues);
                            }}
                            setSelected={setSelectedDestinations}
                            width={"w-full"}
                          />
                          <div className="my-5" />
                          <DafaultButton
                            text={"Download config Sheet"}
                            color={"info"}
                            textColor={"#FFFFFF"}
                            radius={"6px"}
                            onClick={() => generateConfigSheet()}
                            disabled={selectedDestinations.length === 0}
                          />
                          <div className="my-5" />
                          <Dropdown
                            options={[
                              "Generate Spreadsheet to Preview all Entities",
                              "Load Entities in Destinations",
                            ]}
                            label={"Command"}
                            fieldData={{
                              fieldValues: _.cloneDeep(fieldValues),
                              setFieldValues: setFieldValues,
                              index: 0,
                              fieldName: "command",
                              description: "Command to execute",
                            }}
                          />
                          <div className="my-5" />
                          <p className="font-semibold mr-2 mt-4 min-w-max text-sm">
                            {fieldValues[0]["command"] === "Load Entities in Destinations"
                              ? "Upload final preview sheet to upload entities to destinations"
                              : "Upload complete config Sheet to generate preview"}
                          </p>
                          <Dropzone
                            style={{ height: "40%", flexBasis: "25%" }}
                            onChange={updateFiles}
                            value={configFile}
                            label="Drag and drop Excel Sheet here, or click to upload"
                          >
                            {configFile.map((file, i) => (
                              <FileMosaic key={i} {...file} preview />
                            ))}
                          </Dropzone>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CMToManyMain;
