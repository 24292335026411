import PageHeader from "components/PageHeader/PageHeader";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import IngredientsFilters from "components/Ingredients/IngredientsFilters";
import SearchBar from "components/Common/SearchBar";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { listIngredients } from "features/ingredients/ingredientSlice";
import IngredientCard from "components/Common/IngredientCard";
import setLoading from "features/helper";

const Ingredients = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const ingredientList = useSelector(
    (state: any) => state.ingredient.ingredientList
  );
  const currentUser = useSelector((state: any) => state.auth.user);

  const [filteredIngredients, setFilteredIngredients] =
    useState(ingredientList);

  const [typeFilter, setTypeFilter] = useState("All");
  const [platformFilter, setPlatformFilter] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");

  const onIngredientTypeChange = (selected) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'ingredients_filter',
      'platform': platformFilter.length !== 0 ? platformFilter[0] : 'All',
      'ingredient_type': selected.value,
      'user_id': currentUser.userID,
      'tenant_id': currentUser.tenant
    });
    setTypeFilter(selected.value);
    filteredArray(selected.value, platformFilter, searchFilter);
  };

  const onSearch = (value) => {
    setSearchFilter(value);
    filteredArray(typeFilter, platformFilter, value);
  };

  const onIngredientPlatformChange = (selected) => {
    var array = [];
    selected.forEach((element) => {
      array.push(element.value);
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'ingredients_filter',
      'platform': array.length !== 0 ? array : 'All',
      'ingredient_type': typeFilter,
      'user_id': currentUser.userID,
      'tenant_id': currentUser.tenant
    });

    setPlatformFilter(array);
    filteredArray(typeFilter, array, searchFilter);
  };

  const filteredArray = (type, platforms, search) => {
    var array = [...ingredientList];

    if (type === "Manual") {
      array = array.filter(function (ingredient) {
        return ingredient.manual === true;
      });
    } else if (type === "Scheduled") {
      array = array.filter(function (ingredient) {
        return ingredient.manual === false;
      });
    }

    if (platforms.length !== 0) {
      platforms.forEach((element) => {
        array = array.filter(function (ingredient) {
          return (
            ingredient.platform_from.includes(element) ||
            ingredient.platform_to.includes(element)
          );
        });
      });
    }

    if (search !== "") {
      array = array.filter(function (ingredient) {
        return ingredient.title.toLowerCase().includes(search.toLowerCase());
      });
    }

    setFilteredIngredients(array);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(listIngredients()).then((res) => {
      setFilteredIngredients(res.payload);
      setLoading(false);
    });
  }, []);

  let ingredientTitleArr = [];

  return (
    <div className=" mb-[2.5%] w-[82%] m-0 float-right text-primary">
      <PageHeader title="Ingredients" extraClassName="px-[2.5%]">
        <IngredientsFilters
          onIngredientTypeChange={onIngredientTypeChange}
          onIngredientPlatformChange={onIngredientPlatformChange}
        />
        <SearchBar width={"w-64"} onSubmit={onSearch} />
      </PageHeader>
      <div className="p-[0_2.5%]">
      <Grid container spacing={3}>
        {filteredIngredients?.map((cardList, i) => (
          <Grid
            item
            xs={3}
            key={i}
            className="cursor-pointer"
            onClick={() => {
              ingredientTitleArr.push(cardList?.title);
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': 'view_ingredient',
                'ingredient': ingredientTitleArr,
                'ingredient_id': cardList?.id,
                'user_id': currentUser.userID,
                'tenant_id': currentUser.tenant
              });
              navigate(`/ingredients/${cardList?.id}`)
            }}
          >
            <IngredientCard
              name={cardList?.title}
              desc={cardList?.description}
              manual={cardList?.manual}
              platformsFrom={cardList?.platform_from}
              platformsTo={cardList?.platform_to}
              ready={cardList?.ready}
            />
          </Grid>
        ))}
      </Grid>
      </div>
    </div>
  );
};

export default Ingredients;
