import RecipeCard from "components/Dashboard/RecipeCard";
import PageHeader from "components/PageHeader/PageHeader";
import React, { useEffect, useState } from "react";
import FilteringSection from "components/Dashboard/DashboardFilters";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useSelector } from "react-redux";
import { clearRecipeState, filteredRecipeState, listRecipes } from "features/recipe/recipeSlice";
import { useNavigate } from "react-router-dom";
import setLoading from "features/helper";
import noRecipesIllustartion from "assets/images/noRecipesIllustartion.png";
import { HeaderButton } from "components/Common/Button";
import { toast } from "react-toastify";
import _ from "lodash";
import { listUsersByTenantID } from "features/users/userSlice";
import { listGroups } from "features/groups/groupSlice";

const Recipes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const recipeList = useSelector((state: any) => state?.recipe?.recipeList);
  const currentUser = useSelector((state: any) => state.auth.user);
  const recipeFilters = useSelector((state: any) => state?.recipe?.recipeFilters);

  const [filteredRecipe, setFilteredRecipe] = useState(recipeList);
  const [usersList, setUsersList] = useState([]);
  const [groupsList, setGroupsList] = useState([]);

  const [sortByFilter, setSortByFilter] = useState(recipeFilters.sortBy);
  const [statusFilter, setStatusFilter] = useState(recipeFilters.status);
  const [roleFilter, setRoleFilter] = useState(recipeFilters.role);
  const [dateFilter, setDateFilter] = useState(recipeFilters.date);
  const [usersFilter, setUsersFilter] = useState(recipeFilters.userList);
  const [groupsFilter, setGroupsFilter] = useState(recipeFilters.groupList);
  const [appliedDate, setAppliedDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const onStatusChange = (selected) => {
    var array = [];
    selected.forEach((element) => {
      array.push(element.value);
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'recipes_filter',
      'status': array.length !== 0 ? array : 'All status',
      'sort_by': sortByFilter.length !== 0 ? sortByFilter : '',
      'by_user': usersFilter.length !== 0 ? usersFilter : 'Current User',
      'by_group': groupsFilter.length !== 0 ? groupsFilter : 'All Groups',
      'user_id': currentUser.userID,
      'tenant_id': currentUser.tenant
    });
    setStatusFilter(array);
    filteredArray(sortByFilter, array, dateFilter, usersFilter, groupsFilter);
  };

  const onUserListChange = (selected) => {
    var array = [];
    if (selected.length !== 0 && selected[0].value === 'All') {
      array = ['All']
    } else {
      array = selected;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'recipes_filter',
      'status': statusFilter.length !== 0 ? statusFilter : 'All status',
      'sort_by': sortByFilter.length !== 0 ? sortByFilter : '',
      'by_user': array.length !== 0 ? array : 'Current User',
      'by_group': groupsFilter.length !== 0 ? groupsFilter : 'All Groups',
      'user_id': currentUser.userID,
      'tenant_id': currentUser.tenant
    });
    setUsersFilter(array);
    filteredArray(sortByFilter, statusFilter, dateFilter, array, groupsFilter);
  };

  const onGroupListChange = (selected) => {
    var array = [];
    if (selected.length !== 0 && selected[0].value === 'All') {
      array = ['All']
    } else {
      array = selected;
      // selected.forEach((element) => {
      //   array.push(element.value);
      // });
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'recipes_filter',
      'status': statusFilter.length !== 0 ? statusFilter : 'All status',
      'sort_by': sortByFilter.length !== 0 ? sortByFilter : '',
      'by_user': usersFilter.length !== 0 ? usersFilter : 'Current User',
      'by_group': array.length !== 0 ? array : 'All Groups',
      'user_id': currentUser.userID,
      'tenant_id': currentUser.tenant
    });
    setGroupsFilter(array);
    filteredArray(sortByFilter, statusFilter, dateFilter, usersFilter, array);
  };

  const onSortByChange = (selected) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'recipes_filter',
      'status': statusFilter.length !== 0 ? statusFilter : 'All status',
      'sort_by': selected.value,
      'by_user': usersFilter.length !== 0 ? usersFilter : 'Current User',
      'by_group': groupsFilter.length !== 0 ? groupsFilter : 'All Groups',
      'user_id': currentUser.userID,
      'tenant_id': currentUser.tenant
    });
    setSortByFilter(selected.value);
    filteredArray(selected.value, statusFilter, dateFilter, usersFilter, groupsFilter);
  };

  const onDateRangeFilter = (buttonDate, status) => {
    setAppliedDate(buttonDate);
    var date = [];
    if (status === "Apply") {
      var startDate = buttonDate[0].startDate;
      var endDate = buttonDate[0].endDate;
      endDate.setHours(23, 59, 59, 999);
      date = [startDate, endDate];
      setDateFilter([startDate, endDate]);
    } else {
      setDateFilter([]);
    }
    filteredArray(sortByFilter, statusFilter, date, usersFilter, groupsFilter);
  };

  const filteredArray = (sortBy, status, date, userListFilter, groupListFilter) => {
    var filterState = {
      sortBy: sortBy,
      status: status,
      date: date,
      userList: userListFilter,
      groupList: groupListFilter
    }
    setLoading(true);
    dispatch(filteredRecipeState(filterState))
    
    dispatch(listRecipes(currentUser.tenant)).then((res) => {
      var array = _.cloneDeep(res.payload);
      if (status.length !== 0) {
        array = array.filter(function (recipe) {
          return status.includes(recipe.status.toUpperCase());
        });
      }

      if (!userListFilter.includes('All')) {
        if (userListFilter.length === 0) {
          array = array.filter(function (a) {
            return a.user_id == currentUser.userID;
          });
        } else {
          array = array.filter(function (a) {
            return userListFilter.some(obj => obj.value === a.user_id);
          });
        }
      }  

      if (!groupListFilter.includes('All')) {
        if (groupListFilter.length !== 0) {
          array = array.filter(function (a) {
            let hasPermission = a.group.some((obj1: any) => {
              return groupListFilter.find((obj2: any) => {
                return obj1.id === obj2.value;
              });
            });
            return hasPermission;
          });
        }        
      }  
  
      if (date.length !== 0) {
        array = array.filter(function (a) {
          var curDate = new Date(a.created);
  
          return curDate >= date[0] && curDate <= date[1];
        });
      }
  
      if (sortBy === "Author") {
        array.sort((a, b) => {
          if (a.author < b.author) {
            return -1;
          }
          if (a.author > b.author) {
            return 1;
          }
          return 0;
        });
      } else if (sortBy === "Status") {
        array.sort((a, b) => {
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        });
      } else if (sortBy === "Date Created") {
        array.sort(function (a, b) {
          return new Date(b.created).valueOf() - new Date(a.created).valueOf();
        });
      } else if (sortBy === "Last Run") {
        array.sort(function (a, b) {
          return new Date(b.last_run).valueOf() - new Date(a.last_run).valueOf();
        });
      }
      setFilteredRecipe(array);
      setLoading(false);
    })
    .catch((e) => {
      console.log(e);
      toast.error("Could not filter Reciped. Please try again.", {
        position: "bottom-right",
        autoClose: 8080,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    });
  };

  const onClick = () => {
    dispatch(clearRecipeState()).then(() => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'create_new_recipe_starts',
        'user_id': currentUser.userID,
        'tenant_id': currentUser.tenant
      });
      navigate("/recipe_details", { state: { copy: false, id: null } });
    });
  };

  useEffect(() => {}, [filteredRecipe]);

  useEffect(() => {
    setLoading(true);
    dispatch(listRecipes(currentUser.tenant)).then(() => {
      dispatch(listUsersByTenantID(currentUser.tenant)).then((res) => {
        // dispatch(listGroups()).then((groupRes) => {
          filteredArray(sortByFilter, statusFilter, dateFilter, usersFilter, groupsFilter);

          let tempUserList = [{value: 'All', label: 'All'}];
          let tempGroupList = [{value: 'All', label: 'All'}];
          res.payload.forEach(element => {
            tempUserList.push({value: element.id, label: element.name})
          });
          // console.log(currentUser.groups);
          currentUser?.groups?.forEach(element => {
            tempGroupList.push({value: element.id, label: element.name})
          });
          setUsersList(tempUserList);
          setGroupsList(tempGroupList);
          setLoading(false);
        // })                
      });      
    });
  }, []);

  return (
    <div className="p-[0_2.5%] w-[82%] m-0 float-right">
      <PageHeader
        title={"Recipes"}
        button="Create new Recipe"
        onClick={onClick}
        disabled={
          currentUser.role.resources.Create
            ? !currentUser.role.resources.Create.includes("Create")
            : true
        }
      >
      <FilteringSection
      currentUser={currentUser}
        statusChange={onStatusChange}
        onSortByChange={onSortByChange}
        onDateRangeFilter={onDateRangeFilter}
        onUserListChange={onUserListChange}
        onGroupListChange={onGroupListChange}
        statusFilter={statusFilter}
        sortByFilter={sortByFilter}
        dateFilter={dateFilter}
        usersFilter={usersFilter}
        groupsFilter={groupsFilter}
        appliedDate={appliedDate}
        usersList={usersList}
        groupsList={groupsList}
      />
      </PageHeader>
      {filteredRecipe.length > 0 && typeof filteredRecipe === "object" ? (
        filteredRecipe?.map((recipe, i) => (
          <RecipeCard 
            recipe={recipe}
            filteredRecipes={filteredRecipe} 
            setFilteredRecipe={setFilteredRecipe} 
            i={i} 
            key={i} 
          />
        ))
      ) : (
        <div className="flex flex-col justify-center items-center">
          <img
            style={{ height: "40%", width: "40%" }}
            src={noRecipesIllustartion}
            alt=""
          />
          <p className="text-primary text-3xl font-bold">
            No Recipes Available
          </p>
          <p className="text-primary text-center mt-5">
            There are no recipes to show you right now. <br /> Add a recipe to
            begin.
          </p>
          <div className="mt-10">
            <HeaderButton
              text={"Create new Recipe"}
              onClick={onClick}
              disabled={
                currentUser.role.resources.Create
                  ? !currentUser.role.resources.Create.includes("Create")
                  : true
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Recipes;
