import React, { useEffect, useState } from "react";
import _ from "lodash";

const RTFinalMapping = ({ creatives, dvElements, campaignMetadata, metadata, setMetadata }: any) => {    

    useEffect(() => {
        let temp = {};
        let existingMetadata = {};
        if (metadata.length) {
          metadata?.forEach(element => {
            existingMetadata[element.creativeID+'_'+element.lineItemID] = {check: element.check, name: element.name}
          });
        };
        
        creatives?.forEach((creative) => {
            dvElements.forEach((li) => {
                let creativeId = creative['id'];
                let lineItemID = li.split(" : ")[0];
                let index = creativeId+'_'+lineItemID
                
                temp[index] = {
                  'check': index in existingMetadata ? existingMetadata[index].check : false,
                  'name':  index in existingMetadata ? existingMetadata[index].name : '',
                  'creativeID': creative.id,
                  'creativeName': creative.name,
                  'lineItemID': lineItemID,
                  'size': creative.size,
                  'startDate': campaignMetadata.startDate,
                  'endDate': campaignMetadata.endDate,
                  'campaignID': campaignMetadata.id
                };
            });            
        })
        setMetadata(temp);
    }, [dvElements, creatives])

    
    return(
        <div className="mt-4 max-w-full">
          <div className="">
            <table className="table-auto w-full divide-y divide-gray-200 shadow border">
                <thead className="bg-secondary/90 shadow">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-md  font-bold text-center text-black uppercase  "
                    >
                      DV Element
                    </th>
                    <th
                      scope="col"
                      colSpan={creatives?.length}
                      className="px-6 py-3 text-md  font-bold text-center text-black uppercase "
                    >
                      Creatives
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td></td>
                    {creatives.map((creative: any, index: any) => {
                      return (
                        <td
                          key={index}
                          className="px-6 py-4 max-h-8 bg-gray text-sm font-medium text-gray-800 justify-center items-center"
                        >
                          <div className="flex flex-row items-center justify-center">
                            {creative.name}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  {dvElements.map((li: any, index: any) => {
                    let lineItem = li.split(" : ");
                    let lineItemId = lineItem[0];
                    let lineItemName = lineItem[1];
                    return (
                        <tr key={index}>
                            <td className="px-6 py-4 text-sm font-medium text-gray-800">
                            <div className="mb-2 flex flex-col">
                                {lineItemName}
                                <span className="text-gray-300 ml-5">
                                {lineItemId}
                                </span>
                            </div>
                            </td>
                            {creatives.map((creative: any, index: any) => {
                                let mappedMetadata = creative['id']+'_'+lineItemId
                                return (
                                    <td key={index}>
                                    <div className="flex flex-row items-center justify-center">
                                        <input 
                                            readOnly
                                            className="mb-[1.2em] mr-5 form-checkbox text-primary cursor-pointer focus:ring-0 focus:shadow-none rounded h-[1.19rem] w-[1.19rem]"
                                            type="checkbox"
                                            checked={metadata[mappedMetadata]?.check}
                                            onChange={() => {
                                                let tempMetadata = _.cloneDeep(metadata);
                                                tempMetadata[mappedMetadata].check = !tempMetadata[mappedMetadata]?.check;
                                                setMetadata(tempMetadata);
                                            }}
                                        />
                                        {metadata[mappedMetadata]?.check ?
                                        <div className="mb-[1.2em]">
                                            <input
                                            id="number"
                                            className="rounded-md bg-gray-50 border border-gray-300 text-gray-900 text-sm block h-[2rem] p-2.5"
                                            placeholder="Placement Name"
                                            required
                                            value={metadata[mappedMetadata]?.name}
                                            onChange={(e) => {
                                                let tempMetadata = _.cloneDeep(metadata);
                                                
                                                tempMetadata[mappedMetadata]['name'] = e.target.value;
                                                setMetadata(tempMetadata);
                                            }}
                                            />
                                        </div>
                                        : null}
                                    </div>
                                    </td>
                                );
                            })}
                        </tr>
                    )
                  })}
                </tbody>
            </table>
          </div>
        </div>
    )
}

export default RTFinalMapping;