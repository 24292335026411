import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useNavigate } from "react-router-dom";
import PageHeader from "components/PageHeader/PageHeader";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { deleteGroup, getGroup, listGroups } from "features/groups/groupSlice";
import Popup from "components/Common/Popup";
import setLoading from "features/helper";

import _ from "lodash";

const GroupsDashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleGroupId, setDeleGroupId] = useState(null);
  const [hover, setHover] = useState(false);

  const [groups, setGroups] = useState(null);
  const [asc, setAsc] = useState(true);
  const [sorting, setSorting] = useState("");

  const currentUser = useSelector((state: any) => state.auth.user);
  const currentUserRoles = useSelector(
    (state: any) => state.auth.user.role.resources
  );

  const canEdit = currentUserRoles.Groups
    ? currentUserRoles.Groups.includes("Edit groups")
    : false;

  const canDelete = currentUserRoles.Groups
    ? currentUserRoles.Groups.includes("Delete groups")
    : false;

  const canCreate = currentUserRoles.Groups
    ? currentUserRoles.Groups.includes("Add groups")
    : false;

  useEffect(() => {
    setLoading(true);
    dispatch(listGroups(currentUser.tenant)).then((res) => {
      setGroups(res.payload);
      setLoading(false);
    });
  }, []);

  const onClick = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'create_new_group_starts',
      'user_id': currentUser.userID,
      'tenant_id': currentUser.tenant
    });
    navigate("/group_create");
  };

  const onDelete = (id: any) => {
    let group_name = '';
    for (let index = 0; index < groups.length; index++) {
      if(groups[index].id == deleGroupId) {
        group_name = groups[index].name;
      }
    }
    setLoading(true);
    dispatch(deleteGroup(deleGroupId)).then(() => {
      dispatch(listGroups(currentUser.tenant)).then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'delete_group',
          'group_id': deleGroupId,
          'user_id': currentUser.userID,
          'tenant_id': currentUser.tenant,
          'group_name': group_name
        });
        setLoading(false);
        navigate("/groups");
      });
    });
  };

  const onEdit = (id: any, name: any) => {
    setLoading(true);
    dispatch(getGroup(id)).then(() => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'edit_group',
        'group_id': id,
        'user_id': currentUser.userID,
        'tenant_id': currentUser.tenant,
        'group_name': name
      });
      setLoading(false);
      navigate("/group_view", { state: { id: id } });
    });
  };

  const sortGroups = (category: any) => {

    let tempGroups = _.cloneDeep(groups);
    tempGroups.sort((a, b) => {
      let nameA: any;
      let nameB: any;
      nameA = a[category].toUpperCase(); // Convert names to uppercase for case-insensitive sorting
      nameB = b[category].toUpperCase();

      if (nameA < nameB) {
        return asc ? -1 : 1;
      }
      if (nameA > nameB) {
        return asc ? 1 : -1;
      }
      return 0;
    });
    setGroups(tempGroups);
    setAsc(!asc);
    setSorting(category);
  };

  return (
    <div className="p-[0_2.5%] w-[82%] ml-[18%]">
      <PageHeader
        title="Groups"
        button="Create new Group"
        onClick={onClick}
        disabled={!canCreate}
      />
      <div className="w-[90%] ml-[5%] mt-24">
        <div className="grid grid-cols-10 gap-4 px-16">
          <div className={`flex flex-row items-center col-span-2 text-primary 
                          ${sorting === 'name' ? 'font-black' : 'font-semibold'} text-lg`}>
            <p className="mr-5">Groups</p>
            {sorting === "name" ? (
              asc ? (
                <KeyboardArrowDownIcon className="cursor-pointer" onClick={() => sortGroups("name")} />
              ) : (
                <KeyboardArrowUpIcon className="cursor-pointer" onClick={() => sortGroups("name")} />
              )
            ) : (
              <KeyboardArrowDownIcon className="cursor-pointer" onClick={() => sortGroups("name")} />
            )}
          </div>
          <div className="flex flex-row items-center col-span-4 text-primary font-semibold text-lg">
            <p className="mr-5">Name</p>
          </div>
          <div className={`flex flex-row items-center col-span-2 text-primary 
                        ${sorting === 'active' ? 'font-black' : 'font-semibold'} text-lg`}>
            <p className="mr-5">Status</p>
            {sorting === "active" ? (
              asc ? (
                <KeyboardArrowDownIcon className="cursor-pointer" onClick={() => sortGroups("active")} />
              ) : (
                <KeyboardArrowUpIcon className="cursor-pointer" onClick={() => sortGroups("active")} />
              )
            ) : (
              <KeyboardArrowDownIcon className="cursor-pointer" onClick={() => sortGroups("active")} />
            )}
          </div>
          <p className="col-span-1 text-primary font-semibold text-lg text-center">
            Edit
          </p>
          <p className="col-span-1 text-primary font-semibold text-lg text-center">
            Delete
          </p>
        </div>
        {groups?.map((data: any, index: any) => (
          <div
            key={index}
            className="grid grid-cols-10 gap-4 my-5 px-16 py-2 border rounded-lg border-[#CFD8DC]"
          >
            <p className="col-span-2 text-primary text-lg">{data.name}</p>
            <div className="col-span-4 text-primary">
              <div className="flex flex-row text-lg">
                {data.users.map((user: any, index: any) => (
                  <div
                    key={index}
                    className="flex w-8 h-8 justify-center items-center rounded-full bg-gray border-2 border-white cursor-pointer"
                    onMouseEnter={() => <p className="absolute">a</p>}
                    onMouseLeave={() => null}
                  >
                    <p>{user.name.substring(0, 1)}</p>
                    {/* {hover && <p className="absolute">Rahul</p>} */}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-row items-center col-span-2 text-primary text-lg">
              <div
                className={`${
                  data.active === "true" ? "bg-[#0EB200]" : "bg-[#FB2E01]"
                } rounded-full mr-2 w-2 h-2`}
              />
              <p>{data.active === "true" ? "Active" : "Inactive"}</p>
            </div>
            <button
              className="text-center"
              onClick={() => onEdit(data.id, data.name)}
              disabled={!canEdit}
            >
              <BorderColorOutlinedIcon
                className={`col-span-1 ${
                  canEdit ? "text-primary" : "text-slate-300"
                } ml-1`}
                style={{ fontSize: "1.9rem" }}
              />
            </button>
            <button
              className="text-center"
              onClick={() => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  'event': 'delete_group_icon',
                  'group_id': data.id,
                  'user_id': currentUser.userID,
                  'tenant_id': currentUser.tenant,
                  'group_name': data.name
                });
                setShowDeleteModal(true);
                setDeleGroupId(data.id);
              }}
              disabled={!canDelete}
            >
              <DeleteOutlineIcon
                className={`col-span-1 ${
                  canDelete ? "text-primary" : "text-slate-300"
                } ml-1`}
                style={{ fontSize: "1.9rem" }}
              />
            </button>
          </div>
        ))}
        {showDeleteModal ? (
          <Popup
            setOpenModal={setShowDeleteModal}
            text="Delete this Group?"
            redirect={onDelete}
          />
        ) : null}
      </div>
    </div>
  );
};

export default GroupsDashboard;
