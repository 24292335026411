import React from "react";
import { Helmet } from "react-helmet";
import icon from "../../assets/images/yuniter_icon.png";

interface IDocumentTitle {
  pageTitle: string;
}

export default ({ pageTitle }: IDocumentTitle): JSX.Element => (
  <Helmet>
    <title>Yuniter | {pageTitle}</title>
    <meta
      name="description"
      content="Scaled Automation Application For Ad Operations Teams"
    />
    <meta name="theme-color" content="#FF632B" />
    <link rel="icon" href={icon} />
  </Helmet>
);
