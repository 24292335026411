import React, { useState } from "react";
import Popup from "./Popup";

const OptionSelector = ({
  title,
  desc,
  options,
  disabled,
  currentRole,
  setCurrentRole,
  newRole,
  setNewRole,
}: any) => {
  let selector = currentRole?.resources[title]
    ? currentRole?.resources[title]
    : [];


  const [showPopup, setShowPopup] = useState(false);

  const currentOption = (selected: any) => {
    if (newRole === "") {
      setShowPopup(true);
    } else if (currentRole?.resources[title] && newRole !== "") {
      let index = currentRole?.resources[title].indexOf(selected);
      if (index === -1) {
        currentRole?.resources[title].push(selected);
      } else {
        currentRole?.resources[title].splice(index, 1);
      }
    } else if (currentRole?.resources) {
      currentRole.resources[title] = [selected];
    }
    setCurrentRole(currentRole);
  };

  return (
    <div className="w-[45%] text-primary">
      {showPopup && (
        <Popup
          setOpenModal={setShowPopup}
          text="Duplicate this Recipe?"
          inputText={newRole}
          setInputText={setNewRole}
          redirect={() => {}}
        />
      )}
      <p className="font-bold mb-3">{title}</p>
      <p className="text-sm mb-3">{desc}</p>
      <div className="w-96 border border-primary rounded-xl">
        {options.map((option: any, index: any) => {
          return (
            <button
              key={index}
              disabled={disabled}
              onClick={() => currentOption(option)}
              className={`flex flex-row items-center text-primary text-sm
          p-2.5 h-10 min-w-full ${
            options.length === index + 1 ? "" : "border-b"
          }`}
            >
              <input
                type="checkbox"
                onChange={() => currentOption(option)}
                disabled={disabled}
                checked={selector.includes(option)}
                className="form-checkbox text-primary mr-8 cursor-pointer"
                onClick={() => currentOption(option)}
              ></input>
              {option}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default OptionSelector;
