import React from "react";

const NavItem = ({ icon, text, onClick, selected, disabled }: any) => {
  function PrettyImage() {
    return React.cloneElement(icon, {
      className: disabled ? "text-[#B2B2B2]" : (!selected ? "text-[#5e6a81]" : "text-primary"),
      style: { fontSize: "1.9rem" },
    });
  }
  return (
    <button
      className="flex flex-row w-full mb-[10%] items-center"
      onClick={onClick}
      disabled={disabled}
    >
      <PrettyImage />
      <p
        className={`pl-[5%] font-body ${
          disabled ? "text-[#B2B2B2]" :
          (!selected ? "text-[#5e6a81]" : "text-primary")
        } ${!selected ? "font-normal" : "font-bold"}`}
      >
        {text}
      </p>
    </button>
  );
};

export default NavItem;
