import React from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { useSelector } from "react-redux";
import { checkRolePermission } from "components/Common/config";

const RecipeInfoCards = ({
  isCurrentUser,
  group,
  active,
  lastRun,
  runTime,
  progress,
  status,
  runRecipe,
  pauseRecipe,
  permissionsEnabled,
  is_rt_tool,
  tasks
}: any) => {

  const currentUser = useSelector((state: any) => state.auth.user);
  const runRole = currentUser.role.resources['Run ad hoc recipes'];

  let canRun = checkRolePermission(runRole, group, isCurrentUser, currentUser)
  
  try {
    if (
      status === "FINISHED" &&
      is_rt_tool &&
      tasks[0].values.mappings[0] &&
      "placementID" in tasks[0].values.mappings[0]
    ) {
      progress = 100;
    }
  } catch(e) {}
  

  let progressColor;
  if (status === "FINISHED") {
    progressColor = "#0177FB";
  } else if (status === "ERROR") {
    progressColor = "#DA2801";
  } else if (status === "STOPPED") {
    progressColor = "#FB5B01";
  } else {
    progressColor = "#0CC5FF";
  }
  return (
    <div className="flex flex-row flex-wrap basis-[29.5%] justify-between items-start h-min">
      <div className="basis-[48%] bg-[#F5F6F8] flex flex-col items-center h-24 p-3 rounded-2xl mb-5">
        <p className="mb-3 font-normal text-primary">Run Status</p>
        <div className="flex flex-row items-center">
          <div
            className={`w-5 h-5 ${
              active ? "bg-[#ACD4BF]" : "bg-[#FB1F01]/40"
            } rounded-full mr-4`}
          ></div>
          <p className="font-semibold text-primary text-base">
            {active ? "ON" : "OFF"}
          </p>
        </div>
      </div>
      <div className="basis-[48%] bg-[#F5F6F8] flex flex-col items-center h-24 p-3 rounded-2xl mb-5">
        <p className="mb-3 font-normal text-primary">Last Run</p>
        <p className="font-semibold text-center text-primary text-lg">
          {lastRun && lastRun !== "None"
            ? lastRun
            // ? moment(lastRun, "YYYY-MM-DD").format("MMM DD, YYYY").toUpperCase()
            : "New Recipe"}
        </p>
      </div>
      <div className="basis-[48%] bg-[#F5F6F8] flex flex-col items-center h-36 p-3 rounded-2xl">
        <p className="mb-2 font-normal text-primary">Run Time</p>
        <p className="font-normal text-primary text-4xl mb-1">{runTime ? runTime : '-'}</p>
        <div className="flex flex-col items-center">
          <div>
          {active ? (
            <>
              <button disabled={!canRun || !permissionsEnabled} onClick={() => runRecipe()}>
                <PlayCircleIcon
                  className={`${!canRun || !permissionsEnabled ? 'text-slate-400' : 'text-primary cursor-pointer'} mr-1`}
                  style={{ fontSize: "1.75rem" }}
                />
              </button>
              <button disabled={!canRun || !permissionsEnabled} onClick={() => pauseRecipe()}>
                <StopCircleOutlinedIcon
                  className={`${!canRun || !permissionsEnabled ? 'text-slate-400' : 'text-primary cursor-pointer'}`}
                  style={{ fontSize: "1.75rem" }}
                />
              </button>
            </>
          ) : (
            <>
              <button disabled={!canRun || !permissionsEnabled} onClick={() => runRecipe()}>
                <PlayCircleOutlineIcon
                className={`${!canRun || !permissionsEnabled ? 'text-slate-400' : 'text-primary cursor-pointer'} mr-1`}
                style={{ fontSize: "1.75rem" }}
              /> 
              </button>
              <button disabled={!canRun || !permissionsEnabled} onClick={() => pauseRecipe()}>
                <StopCircleIcon
                  className={`${!canRun || !permissionsEnabled ? 'text-slate-400' : 'text-primary cursor-pointer'}`}
                  style={{ fontSize: "1.75rem" }}
                />
              </button>
            </>
          )}
          </div>
          {!permissionsEnabled ?
          <p className="text-red text-center text-sm">Enable Permissions to Run Recipe</p>
          : <></>}
        </div>
      </div>
      <div className="basis-[48%] bg-[#F5F6F8] flex flex-col items-center h-36 p-3 rounded-2xl">
        <p className="mb-3 font-normal text-primary">{status.toUpperCase()}</p>
        <CircularProgressbar
          value={progress ? progress : 0}
          text={`${progress ? progress : 0}%`}
          styles={buildStyles({
            textSize: "1.5rem",
            textColor: "#40506A",
            pathColor: progressColor,
          })}
        />
      </div>
    </div>
  );
};

export default RecipeInfoCards;
