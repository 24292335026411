import Select from "react-select";
import React, { useState } from "react";
import DatePicker from "components/PageHeader/DatePicker";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { CalendarDropdownStyles } from "components/Styles";

const CalendarDropdown = ({
  title,
  placeholder,
  onDateRangeFilter,
  appliedDate,
}: any) => {
  const filterContainer = "flex items-start justify-between text-primary";
  const nameSection = "font-light whitespace-nowrap";

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <div className={filterContainer} onClick={handleClick}>
          <p className={nameSection}>{title}: &nbsp;</p>
          <Select
            options={[]}
            placeholder={placeholder}
            styles={CalendarDropdownStyles}
            isSearchable={false}
            menuPortalTarget={document.body}
          />
        </div>
        {open && (
          <DatePicker
            onDateRangeFilter={onDateRangeFilter}
            appliedDate={appliedDate}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default CalendarDropdown;
