import React, { useState } from "react";
import { ApplyButton, ClearButton } from "components/Common/Button";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const Calendarrr = ({ onChange }: any) => {
  const [buttonDate, setButtonDate] = useState(new Date());

  const handleSelect = (date) => {
    setButtonDate(date);
  };

  return (
    <div className="absolute left-[46%] p-3.5 rounded-[28px] bg-white shadow-dropdown focus:outline-none z-[100] cursor-default">
      <hr className="border-[0.5px] border-[#BEBEBE] mt-3" />
      <div className="flex items-center justify-center w-full relative">
        <Calendar
          minDate={new Date()}
          date={buttonDate}
          onChange={handleSelect}
        />
      </div>
      <hr className="border-[0.5px] border-[#BEBEBE] mb-3" />
      <div className={"flex items-center justify-end gap-4"}>
        <ClearButton
          text={"Clear"}
          onClick={() => {
            setButtonDate(new Date());
          }}
        />
        <ApplyButton text={"Apply"} onClick={() => onChange(buttonDate)} />
      </div>
    </div>
  );
};

export default Calendarrr;
