import ClickAwayListener from "@mui/material/ClickAwayListener";
import { RecipeSummaryData } from "components/Common/staticData";
import React, { useState } from "react";

const Repeat = ({ setRepeatOpen, setSelectedRepeat }) => {
  const containerStyle =
    "text-xs w-28 font-normal text-center py-4 px-2 bg-white rounded-[9px] shadow-[2px_4px_9px_rgba(0,0,0,0.2)] text-[#000000]";
  const hrStyle = "border-t-[0.5px] border-[#BEBEBE] my-2.5";
  const timeStyle = "cursor-pointer hover:font-bold";

  const timeLabel = (time) => {
    return (
      <>
        <p className={timeStyle}>{time}</p>
        <hr className={hrStyle} />
      </>
    );
  };

  const handleSelection = (value) => {
    setRepeatOpen(false);
    setSelectedRepeat(value);
  };

  return (
    <div className="z-50 flex items-end absolute">
      <div className={`${containerStyle} `}>
        <p onClick={() => handleSelection("Hourly")}>
          {timeLabel(RecipeSummaryData.Hourly)}
        </p>
        <p onClick={() => handleSelection("Daily")}>
          {timeLabel(RecipeSummaryData.Daily)}
        </p>
        <p onClick={() => handleSelection("Weekly")}>
          {timeLabel(RecipeSummaryData.Weekly)}
        </p>
        <p onClick={() => handleSelection("Custom")} className={timeStyle}>
          {RecipeSummaryData.Custom}
        </p>
      </div>
    </div>
  );
};

export default Repeat;
