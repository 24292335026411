import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useLocation, useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import PageHeader from "components/PageHeader/PageHeader";
import { DafaultButton } from "components/Common/Button";
import { ArrowBack } from "@mui/icons-material";
import { ArrowForward } from "@mui/icons-material";
import setLoading from "features/helper";
import { createGroup, updateGroup } from "features/groups/groupSlice";
import _ from "lodash";
import viewer from "assets/images/viewer.png";
import PageSubHeader from "components/IngredientView.tsx/PageSubHeader";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const GroupsView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  let location: any = useLocation();

  // const users = useSelector((state: any) => state.user.usersList);
  const group = useSelector((state: any) => state.groups.group);
  const currentUser = useSelector((state: any) => state.auth.user);

  const [groupName, setGroupName] = useState(group?.name);
  const [groupDesc, setGroupDesc] = useState(group?.description);
  const [isNext, setIsNext] = useState(false);
  const [active, setActive] = useState(group?.active === "true");
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleNextButton = () => {
    let newGroup: any = {};
    newGroup.name = groupName;
    newGroup.description = groupDesc;
    newGroup.tenantId = currentUser.tenant;
    newGroup.users = selectedUsers;
    newGroup.active = active ? "true" : "false";
    setLoading(true);
    setIsNext(true);
    if (groupName !== "") {
      dispatch(updateGroup({ id: group?.id, payload: newGroup })).then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'update_edit_group',
          'group_id': location?.state?.id,
          'user_id': currentUser.userID,
          'tenant_id': currentUser.tenant,
          'group_name': groupName
        });
        setLoading(false);
        navigate("/groups");
      });
    }
  };

  useEffect(() => {
    var array = [...selectedUsers];
    group?.users.forEach((element: any) => {
      array.push(element.id);
    });
    setSelectedUsers(array);
  }, [group]);

  const handleUserClick = (id: any) => {
    let array = [...selectedUsers];
    let index = array.indexOf(id);
    if (index === -1) {
      array.push(id);
    } else {
      array.splice(index, 1);
    }
    setSelectedUsers(array);
  };

  return (
    <div className="p-[0_2.5%] w-[82%] ml-[18%]">
      <PageHeader title={"Create a New Group"} />

      <div className="mt-16">
        <label className="block mb-2 text-base font-bold text-primary">
          Name:
        </label>
        <input
          type="text"
          id="first_name"
          className={`bg-gray-50 border border-gray-300 text-primary text-sm rounded-lg block 
          p-2.5 w-1/2`}
          placeholder="Untitled"
          value={groupName}
          onChange={(e) => {
            setGroupName(e.target.value);
          }}
          required
        />
        {isNext && groupName === "" ? (
          <span className="flex items-center font-medium tracking-wide text-red text-xs mb-10 mt-1 ml-1">
            Please provide a valid Group Name
          </span>
        ) : (
          <div className="mb-10"></div>
        )}

        <label className="block mb-2 text-base font-bold text-primary">
          Description:
        </label>
        <textarea
          rows={2}
          className="block p-2.5 text-sm text-primary bg-gray-50 rounded-lg border
           border-gray-300 w-1/2 mb-10"
          placeholder="Group Description..."
          value={groupDesc}
          onChange={(e) => {
            setGroupDesc(e.target.value);
          }}
        />
      </div>
      <div className="mt-10 mb-4 flex flex-row items-center justify-between">
        {/* <label className="block mb-2 text-base font-bold text-primary">
          Status:
        </label> */}
        <label className="inline-flex relative items-center mr-5 cursor-pointer">
          <input
            type="checkbox"
            value=""
            className="sr-only peer"
            checked={active}
            onChange={() => setActive(!active)}
            // disabled={recipe?.manual}
          />
          <div
            className={`w-11 h-6 border-2 rounded-full peer peer-focus:ring-4 peer-focus:ring-white 
                  peer-checked:after:translate-x-full after:absolute after:top-1 after:left-[5px] 
                  
                  ${
                    active
                      ? "border-primary after:bg-[#0EB200]"
                      : "border-primary after:bg-[#FB2E01]"
                  }
                   after:border-gray-300 after:rounded-full after:h-4 after:w-4 after:transition-all `}
          ></div>
          <span className="ml-3 text-sm font-medium text-[#111827]">
            Status
          </span>
        </label>
      </div>
      <div className="mt-10 mb-4 flex flex-row items-center justify-between">
        <PageSubHeader title="Current Users" />
        <AddCircleIcon
          style={{ fontSize: "2rem", cursor: "pointer" }}
          onClick={() => {
            navigate("/group_user_selection", { state: { id: group?.id } });
          }}
        />
      </div>
      <hr className="border-b border-primary opacity-40 mb-4 relative" />
      <div className="flex flex-row flex-wrap mb-10">
        {group?.users?.map((data: any, index: any) => (
          <div
            key={index}
            className="flex flex-col justify-between cursor-pointer mt-5 py-5 h-48 w-44 shadow-[0px_4px_4px_rgba(33,33,33,0.25)] rounded-xl mr-10"
            onClick={() => handleUserClick(data.id)}
          >
            <div className="flex justify-end mr-5">
              <input
                readOnly
                className="form-checkbox text-primary text-right cursor-pointer focus:ring-0 focus:shadow-none rounded h-6 w-6 text-right"
                type="checkbox"
                checked={selectedUsers.includes(data.id)}
              />
            </div>
            <div className="flex justify-center">
              <img
                src={data.imageUrl}
                alt="Profile Pic"
                className="rounded-full w-20"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = viewer;
                  currentTarget.className = "h-15 w-15";
                }}
              />
            </div>
            <p className="text-center text-primary">{data.name}</p>
          </div>
        ))}
      </div>
      <div className="flex flex-row justify-between mb-20">
        <DafaultButton
          text="Back"
          startIcon={<ArrowBack />}
          color={"primary"}
          onClick={() => navigate(-1)}
          outlined
        />

        <DafaultButton
          text={"Update Group"}
          endIcon={<ArrowForward />}
          color={"success"}
          onClick={() => handleNextButton()}
          textColor="white"
          width="20rem"
        />
      </div>
    </div>
  );
};

export default GroupsView;
