import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

const generateTime = () => {
  const timeOptions = {
    minutes: ["00"], //, "15", "30", "45"
    day: ["AM", "PM"],
  };
  const timeSet = [];

  for (let k = 0; k < timeOptions.day.length; k++) {
    for (let i = 0; i < 12; i++) {
      for (let j = 0; j < timeOptions.minutes.length; j++) {
        let formattedHour = i.toString().length < 2 ? "0" + i : i;
        if (formattedHour == "00" && timeOptions.day[k] == "PM") {
          formattedHour = 12;
        }
        timeSet.push(
          `${formattedHour}:${timeOptions.minutes[j]} ${timeOptions.day[k]}`
        );
      }
    }
  }
  return timeSet;
};

export default function TimePicker({ onChange }: any) {
  const [value, setValue] = useState(0);

  const handleChange = (_, value) => {
    setValue(value);
  };

  useEffect(() => {}, []);

  return (
    <div className="absolute">
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: 224,
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{
            borderRight: 1,
            borderColor: "divider",
            "&.MuiTabs-root": {
              boxShadow: "2px 4px 9px rgba(0,0,0,0.2)",
              borderRadius: "9px",
              zIndex: 50,
              backgroundColor: "#FFF",
            },
            ".MuiTabs-indicator": { opacity: 0 },
          }}
        >
          {generateTime()?.map((time, i) => (
            <p
              className={
                "hover:font-bold cursor-pointer py-2 text-xs w-28 font-normal text-center bg-white text-[#000000]"
              }
              key={i}
              onClick={() => onChange(time)}
            >
              {time}
            </p>
          ))}
        </Tabs>
      </Box>
    </div>
  );
}
