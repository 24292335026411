import { filterList } from "components/Common/staticData";
import SingleSelectDropdown from "components/Common/SingleSelectDropdown";
import React from "react";
import MultiSelectDropdown from "components/Common/MultiSelectDropdown";

const IngredientsFilters = ({
  onIngredientTypeChange,
  onIngredientPlatformChange,
}: any) => {
  return (
      <div className={"flex items-center gap-4"}>
        <SingleSelectDropdown
          title="By Category"
          placeholder={"All"}
          option={filterList?.ByIngredients}
        />
        <MultiSelectDropdown
          title="By Platform"
          placeholder={"All"}
          option={filterList?.ByDestination}
          onChange={onIngredientPlatformChange}
        />
        <SingleSelectDropdown
          title="By Ingredients Type"
          placeholder={"All"}
          option={filterList?.ByIngredients}
          onChange={onIngredientTypeChange}
        />
      </div>
  );
};

export default IngredientsFilters;
