import React from "react";
import { MultiSelectDropdownStyles } from "components/Styles";
import Select from "react-select";

const MultiSelectDropdown = ({
  title,
  placeholder,
  option,
  onChange,
  defaultValue,
  isDisabled = false,
}: any) => {
  const filterContainer = "flex items-start justify-between text-primary";
  const nameSection = "font-light whitespace-nowrap";

  return (
    <div className={filterContainer}>
      <p className={nameSection}>{title}: &nbsp;</p>
      <Select
        isDisabled={isDisabled}
        defaultValue={defaultValue?.map((value) => {
          return {
            value: typeof value === "object" ? value.value : value,
            label: typeof value === "object" ? value.label : value,
          };
        })}
        onChange={onChange}
        options={option}
        placeholder={placeholder}
        styles={MultiSelectDropdownStyles}
        isSearchable={false}
        menuPortalTarget={document.body}
        isMulti
      />
    </div>
  );
};

export default MultiSelectDropdown;
