import { FormGroup } from "@mui/material";
import React from "react";

export const IngredientCardCheckBox = ({
  handleChange,
  counter,
  disabled,
}: any) => {
  return (
    <FormGroup className={"text-primary ml-10"}>
      <input
        readOnly
        className="form-checkbox text-primary cursor-pointer focus:ring-0 focus:shadow-none rounded h-5 w-5"
        type="checkbox"
        checked={counter > 0}
        onClick={handleChange}
        disabled={disabled}
      />
    </FormGroup>
  );
};

export default IngredientCardCheckBox;
