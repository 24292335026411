import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useLocation, useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import PageHeader from "components/PageHeader/PageHeader";
import { DafaultButton } from "components/Common/Button";
import { ArrowBack } from "@mui/icons-material";
import { ArrowForward } from "@mui/icons-material";
import setLoading from "features/helper";
import { createGroup, getGroup, updateGroup } from "features/groups/groupSlice";
import _ from "lodash";
import { listUsersByTenantID } from "features/users/userSlice";
import viewer from "assets/images/viewer.png";

const GroupUserSelection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  let location: any = useLocation();
  const users = useSelector((state: any) => state.user.usersList);
  const group = useSelector((state: any) => state.groups.group);
  const currentUser = useSelector((state: any) => state.auth.user);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const isEdit = location?.state?.id

  useEffect(() => {
    setLoading(true);
    dispatch(listUsersByTenantID(currentUser.tenant)).then(() => {
      if (isEdit) {
        dispatch(getGroup(location?.state?.id)).then(() => {
          setLoading(false);
          var array = [...selectedUsers];
          group?.users.forEach((element: any) => {
            array.push(element.id);
          });
          setSelectedUsers(array);
        });
      } else {
        setLoading(false);
      }
    });
  }, []);

  const handleNextButton = () => {
    let newGroup: any = {};
    newGroup.name = group.name;
    newGroup.description = group.description;
    newGroup.active = true;
    newGroup.tenantId = currentUser.tenant;
    newGroup.users = selectedUsers;
    setLoading(true);
    if (location?.state?.id) {
      dispatch(
        updateGroup({ id: location?.state?.id, payload: newGroup })
      ).then(() => {
        setLoading(false);
        navigate("/groups");
      });
    } else {
      dispatch(createGroup(newGroup)).then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'new_group_created',
          'user_id': currentUser.userID,
          'tenant_id': currentUser.tenant,
          'group_name': group.name
        });
        setLoading(false);
        navigate("/groups");
      });
    }
  };

  const handleUserClick = (id: any) => {
    let array = [...selectedUsers];
    let index = array.indexOf(id);
    if (index === -1) {
      array.push(id);
    } else {
      array.splice(index, 1);
    }
    setSelectedUsers(array);
  };

  return (
    <div className="p-[0_2.5%] w-[82%] ml-[18%]">
      <PageHeader title={"Add Users"} />
      <div className="flex flex-row flex-wrap">
        {users.map((data: any, index: any) => (
          <div
            key={index}
            className="flex flex-col justify-between cursor-pointer mt-5 py-5 h-48 w-44 shadow-[0px_4px_4px_rgba(33,33,33,0.25)] rounded-xl mr-10"
            onClick={() => handleUserClick(data.id)}
          >
            <div className="flex justify-end mr-5">
              <input
                readOnly
                className="form-checkbox text-primary text-right cursor-pointer focus:ring-0 focus:shadow-none rounded h-6 w-6 text-right"
                type="checkbox"
                checked={selectedUsers.includes(data.id)}
              />
            </div>
            <div className="flex justify-center">
              <img
                src={data.imageUrl}
                alt="Profile Pic"
                className="rounded-full h-20 w-20"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = viewer;
                  currentTarget.className = "h-15 w-15";
                }}
              />
            </div>
            <p className="text-center text-primary">{data.name}</p>
          </div>
        ))}
      </div>
      <div className="flex flex-row justify-between mt-48 mr-36">
        <DafaultButton
          text="Back"
          startIcon={<ArrowBack />}
          color={"primary"}
          onClick={() => navigate(-1)}
          outlined
        />

        <DafaultButton
          text={location?.state?.id ? "Update Group" : "Create new Group"}
          endIcon={<ArrowForward />}
          color={"success"}
          onClick={() => handleNextButton()}
          textColor="white"
        />
      </div>
    </div>
  );
};

export default GroupUserSelection;
