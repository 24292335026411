import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { DafaultButton } from "components/Common/Button";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RecipeSetting from "components/Roles/RecipeSetting";
import {
  createRole,
  getRole,
  setRoleResources,
} from "features/roles/roleSlice";
import setLoading from "features/helper";
import { setUserDetail } from "features/users/userSlice";

const EditRoleRecipeSetting = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const role = useSelector((state: any) => state.roles.role);
  const user = useSelector((state: any) => state.user.user);
  const currentUser = useSelector((state: any) => state.auth.user);

  const [currentRole, setCurrentRole] = useState({
    name: "",
    resources: role.resources,
  });

  const [newRole, setNewRole] = useState(role.newRole);

  const nextClick = () => {
    setLoading(true);
    if (role.newRole !== "") {
      let tempRole: any = {};
      tempRole.name = role.newRole;
      tempRole.resources = role.resources;
      dispatch(createRole(tempRole)).then((response) => {
        let tempUser = _.cloneDeep(user);
        tempUser.roles = { value: response.payload, label: role.newRole };        
        dispatch(getRole(response.payload)).then((roleResponse) => {
          tempUser.roles = [roleResponse.payload]
          dispatch(setUserDetail(tempUser)).then(() => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'edit_user_recipe_settings',
              'user_id': currentUser.userID,
              'tenant_id': currentUser.tenant
            });
            setLoading(false);
            navigate("/user_summary");
          });
        });
      });
    } else {
      dispatch(setRoleResources(currentRole.resources)).then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'edit_user_recipe_settings',
          'user_id': currentUser.userID,
          'tenant_id': currentUser.tenant
        });
        setLoading(false);
        navigate("/user_summary");
      });
    }
  };

  return (
    <div className="p-[0_2.5%] w-[82%] ml-[18%]">
      <RecipeSetting
        currentRole={_.cloneDeep(currentRole)}
        setCurrentRole={setCurrentRole}
        disabled={false}
        newRole={newRole}
        setNewRole={setNewRole}
      />
      <div className="flex flex-row justify-between w-[72%] my-20">
        <DafaultButton
          text={"Back"}
          outlined
          startIcon={<ArrowBackIcon />}
          radius={"6px"}
          onClick={() => {
            // setFullRecipeDetails();
            navigate(-1);
          }}
        />
        <DafaultButton
          text={"Next Step"}
          endIcon={<ArrowForwardIcon />}
          color="success"
          textColor={"#FFFFFF"}
          width={"12rem"}
          radius={"6px"}
          onClick={() => nextClick()}
          //   disabled={edit}
        />
      </div>
    </div>
  );
};

export default EditRoleRecipeSetting;
