import React from "react";

const SummaryHeader = ({ title }: any) => {
  return (
    <div>
      <div className={`flex justify-between items-center`}>
        <div className="text-base my-2 text-primary font-bold">{title}</div>
      </div>
      <hr className={`border-1 border-solid border-[# 667080] my-1`} />
    </div>
  );
};

export default SummaryHeader;
