import { filterList } from "components/Common/staticData";
import CalendarDropdown from "components/Common/CalendarDropdown";
import MultiSelectDropdown from "components/Common/MultiSelectDropdown";
import SingleSelectDropdown from "components/Common/SingleSelectDropdown";
import React from "react";

const FilteringSection = ({
  currentUser,
  statusChange,
  onSortByChange,
  onDateRangeFilter,
  onUserListChange,
  onGroupListChange,
  statusFilter,
  sortByFilter,
  usersFilter,
  groupsFilter,
  appliedDate,
  usersList,
  groupsList,
}: any) => {
  let filterByRole = [
    { value: "User", label: "User", isDisabled: false },
    { value: "Groups", label: "Groups", isDisabled: !currentUser.role.resources.View.includes('Groups') },
    { value: "Organization", label: "Organization", isDisabled: !currentUser.role.resources.View.includes('Organization') },
  ]
  return (
    <>
      {/* relative for CalendarDropdown */}
      <div className={"flex gap-4"}>
        <MultiSelectDropdown
          defaultValue={statusFilter}
          title="Status"
          placeholder={"All status"}
          option={filterList.Status}
          onChange={statusChange}
        />
        <SingleSelectDropdown
          defaultValue={sortByFilter}
          title="Sort by"
          placeholder="Date Created"
          option={filterList.SortBy}
          onChange={onSortByChange}
        />
        <MultiSelectDropdown
          // isDisabled={true}
          defaultValue={usersFilter}
          title="By User"
          placeholder={"Current User"}
          option={usersList}
          onChange={onUserListChange}
        />
        <MultiSelectDropdown
          // isDisabled={true}
          defaultValue={groupsFilter}
          title="By Group"
          placeholder={"All Groups"}
          option={groupsList}
          onChange={onGroupListChange}
        />
      </div>
      <div>
        <CalendarDropdown
          title="Filter by"
          placeholder={"Date range"}
          onDateRangeFilter={onDateRangeFilter}
          appliedDate={appliedDate}
        />
      </div>
    </>
  );
};

export default FilteringSection;
