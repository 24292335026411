import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
import { toast } from "react-toastify";

// Get user from localstorage
const user = localStorage.getItem("user");
const token = localStorage.getItem("Authorization");
const refresh = localStorage.getItem("RefreshToken");

const initialState = {
  user: user ? user : null,
  token: token ? token : null,
  refresh: refresh ? refresh : null,
  isLoading: false,
  isAuthenticated: false,
};

// Login user
export const login = createAsyncThunk(
  "auth/login",
  async (payload: any, thunkAPI) => {
    try {
      return await authService.login(payload);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

// set user
export const setUser = createAsyncThunk(
  "auth/setUser",
  async (user: any, thunkAPI) => {
    try {
      return user;
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Logout user
export const logout = createAsyncThunk("auth/logout", async () => {
  toast.success("Successfully logged out", {
    position: "bottom-right",
    autoClose: 8080,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  await authService.logout();
});

// Force signOut user
export const signOut = createAsyncThunk("auth/logout", async () => {
  toast.error("Something went wrong. Please Log in again", {
    position: "bottom-right",
    autoClose: 8080,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  await authService.logout();
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.token = action?.payload[0];
        state.refresh = action?.payload[1];
      })
      .addCase(setUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(setUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.isAuthenticated = false;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
