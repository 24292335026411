import React from "react";
import { ReactComponent as IngreBinIcon } from "../../../assets/images/IngreBin.svg";

const AddedIngredients = ({ selected, removeIngredient }: any) => {
  return (
    <div className="mb-8 max-h-[65vh] overflow-y-auto px-1">
      {selected.length < 1 ? (
        <div
          className={
            "border-2 border-dashed rounded border-[#B2B2B2] text-[#B2B2B2] font-semibold flex justify-center items-center p-4 ml-2 mr-6"
          }
        >
          Added ingredients
        </div>
      ) : (
        selected?.map((select, index) => (
          <div
            onClick={() => removeIngredient(index)}
            className="flex flex-col justify-between bg-white shadow-ingredientCardBoxshadowGray cursor-pointer
            border-ingredientCardBorder border-[0px] rounded-[10px] p-6 min-h-full min-w-full mb-6"
            key={index}
          >
            <div className="flex flex-row items-center justify-between">
              <p className="text-[#161736] text-sm font-semibold">{select}</p>
              <IngreBinIcon />
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default AddedIngredients;
