import React, { useEffect, useState } from "react";
import _ from "lodash";
// import { creatives } from "./RtConfig";
import { DafaultButton } from "components/Common/Button";

const RTMapped = ({ mapping, is_mapped }: any) => {
  return (
    <div className="mt-4">
      <table className="min-w-full divide-y divide-gray-200 table-fixed shadow  border">
        <thead className="bg-secondary/90 shadow">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-md  font-bold text-center text-black uppercase  "
            >
              CM Creative ID
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-md  font-bold text-center text-black uppercase "
            >
              DV Line Item ID
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-md  font-bold text-center text-black uppercase "
            >
              Placement ID Created
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-md  font-bold text-center text-black uppercase "
            >
              Synced with DV360 Line Item
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {mapping.map((data: any, index: any) => {
            return (
              <tr key={index}>
                <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap text-center">
                  {data.creativeID}
                </td>
                <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap text-center">
                  {data.lineItemID}
                </td>
                <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap text-center">
                  {data.placementID}
                </td>
                <td className={`px-6 py-4 text-sm font-bold whitespace-nowrap text-center ${is_mapped ? "text-green" : "text-orange"}`}>
                  {is_mapped ? "Completed Successfully" : "Pending Sync"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RTMapped;
