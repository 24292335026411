import { ArrowDown } from "assets/icons/Icons";
import React, { useState } from "react";
import BooleanField from "./BooleanField";
import Dropdown from "./Dropdown";
import Switch from "./Switch";
import TextArea from "./TextArea";
import TextField from "./TextField";

const IngredientsBox = ({ fields, fieldValues, setFieldValues, index }: any) => {
  const [open, setOpen] = useState(true);

  const get_name_label = (name) => {
    var splitStr = name.split("_");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  const get_field_kind = (field: any, keyIndex: any) => {
    let fieldData = {
      fieldValues: fieldValues,
      setFieldValues: setFieldValues,
      index: index,
      fieldName: field.name,
      description: field.description,
    };
    let name = field.name;
    if (fieldValues.length > 0) {
      if (field.kind === "authentication") {
        return <Switch key={keyIndex} fieldData={fieldData} label={get_name_label(name)} />;
      } else if (
        field.kind === "string" ||
        field.kind === "email" ||
        field.kind === "integer" ||
        field.kind === "password" ||
        field.kind === "string_list" ||
        field.kind === "integer_list"
      ) {
        return (
          <TextField
            key={keyIndex}
            type={field.kind === "integer" ? "number" : field.kind}
            label={get_name_label(name)}
            fieldData={fieldData}
          />
        );
      } else if (field.kind === "choice") {
        return (
          <Dropdown
            key={keyIndex}
            options={field.choices}
            label={get_name_label(name)}
            fieldData={fieldData}
          />
        );
      } else if (field.kind === "boolean") {
        return <BooleanField key={keyIndex} label={get_name_label(name)} fieldData={fieldData} />;
      } else if (field.kind === "text") {
        return (
          <TextArea
            key={keyIndex}
            label={get_name_label(name)}
            text={"Input text"}
            fieldData={fieldData}
          />
        );
      } else if (field.kind === "json") {
        return (
          <TextArea
            key={keyIndex}
            label={get_name_label(name)}
            text={"Input text"}
            fieldData={fieldData}
          />
        );
      }
    }
  };

  return (
    <div className="rounded-[10px] shadow-ingreSummaryBox bg-white py-5 px-7">
      {fieldValues.length > 0 ? (
        <>
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={() => setOpen(!open)}
          >
            <div className="flex gap-16">
              <p className="font-bold text-lg">{fields.title}</p>
            </div>
            <ArrowDown />
          </div>
          {open && (
            <div className="text-primary mt-3 my-6">
              <p className="font-normal text-sm">{fields.description}</p>
              <div className="flex flex-col justify-center w-full border border-primary rounded-xl p-3 my-8">
                {fields?.instructions?.map((instruction: any, index: any) => (
                  <div key={index} className="flex flex-row items-center my-2">
                    <div className="flex justify-center items-center w-8 h-8 rounded-full bg-slate-300 mr-5">
                      <p>{index}</p>
                    </div>
                    <div
                      className="[&_a]:text-[#003366] [&_a]:font-medium"
                      dangerouslySetInnerHTML={{ __html: `${instruction}` }}
                    />
                  </div>
                ))}
              </div>
              {fields.fields.map((field: any, keyIndex: any) => get_field_kind(field, keyIndex))}
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default IngredientsBox;
