import React, { useEffect, useState } from "react";
import { ArrowDown } from "assets/icons/Icons";
import _ from "lodash";
import { DafaultButton } from "components/Common/Button";
import { Dropzone, FileMosaic } from "@files-ui/react";
import JSZip from "jszip";
import { useSelector } from "react-redux";
// import * as XLSX from "xlsx";
import {
  finalSheetUpload,
  headerColor,
  styleCells,
  styleHeader,
  traficking_sheet_Generator,
} from "./TSFunctions";
import { saveAs } from "file-saver";
import setLoading from "features/helper";
import TextField from "components/Recipes/RecipeSummary/TextField";
import * as XLSX from "xlsx-js-style";
import { excelSheetToSpreadsheetTF } from "components/CMToMany/CMToManyFunction";

const TrafickingSheetMain = ({ recipe, metadata, fieldValues, setFieldValues }: any) => {
  const currentUserID = useSelector((state: any) => state.auth.user.userID);

  const [open, setOpen] = useState(true);
  const [xlsSheet, setXlsSheet] = React.useState([]);
  const [finalXlsSheet, setFinalXlsSheet] = React.useState([]);
  const [finalTraffickingSheet, setFinalTraffickingSheet] = React.useState([]);
  const [assetFiles, setAssetFiles] = React.useState([]);
  const [assetNames, setAssetNames] = useState([]);
  const [placementMetadata, setPlacementMetadata] = useState({});
  const [isSheetuploaded, setIsSheetuploaded] = useState(false);
  const [filestoUpload, setFilesToUpload] = useState([]);
  const [filetoUpload, setFileToUpload] = useState(null);
  const [finalSheetURL, setFinalSheetURL] = useState("");
  const [isCmUpload, setIsCmUpload] = useState(
    fieldValues[0]["isCmUpload"] ? fieldValues[0]["isCmUpload"] : false
  );

  function numberToLetters(num) {
    let letters = "";
    while (num > 0) {
      let remainder = (num - 1) % 26;
      letters = String.fromCharCode(65 + remainder) + letters;
      num = Math.floor((num - 1) / 26);
    }
    return letters;
  }

  const uploadAssets = async (acceptedFiles) => {
    if (acceptedFiles.length == 0) {
      setAssetFiles(acceptedFiles);
      return;
    }
    setAssetFiles(acceptedFiles);

    const file = acceptedFiles[0].file;

    setFileToUpload(file);

    const zip = new JSZip();

    zip.loadAsync(file).then(async (zip) => {
      const promises = [];

      zip.forEach((relativePath, zipEntry) => {
        // Check if the zipEntry is a file (not a directory)
        if (!zipEntry.dir) {
          // Check if the file is an image based on its extension (e.g., jpg, png, etc.)
          const extension = zipEntry.name.split(".").pop().toLowerCase();
          if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
            // Add an async operation to read the file as a data URL and push the promise to the promises array
            promises.push(
              zipEntry.async("base64").then((imageData) => ({
                name: zipEntry.name,
                data: imageData,
              }))
            );
          }
        }
      });

      // Wait for all async operations to complete using Promise.all
      const imagesArray = await Promise.all(promises);
      const imageFiles = imagesArray.map((asset) =>
        asset.name.split("/").pop().split(".").slice(0, -1).join(".")
      );

      setAssetNames(imageFiles);
    });
  };

  const uploadFinalTraffickingSheet = async (acceptedFiles) => {
    if (acceptedFiles.length == 0) {
      setFinalTraffickingSheet(acceptedFiles);
      return;
    }
    setLoading(true);
    setFinalTraffickingSheet(acceptedFiles);
    const file = acceptedFiles[0];
    let finalDataSheet = {};

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "array" });

      workbook.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        finalDataSheet[sheetName] = jsonData;
      });

      const res = await excelSheetToSpreadsheetTF(finalDataSheet, currentUserID);
      console.log("RES: ", res);
      //   setFinalSheetURL(res);

      let tempFieldValues = _.cloneDeep(fieldValues);
      tempFieldValues[0]["finalTraffickingSheetURL"] = res;
      setFieldValues(tempFieldValues);

      setLoading(false);
    };
    reader.readAsArrayBuffer(file.file);
  };

  const uploadFinalConfigSheet = async (acceptedFiles) => {
    if (acceptedFiles.length == 0) {
      setFinalXlsSheet(acceptedFiles);
      return;
    }
    setLoading(true);
    setFinalXlsSheet(acceptedFiles);

    const file = acceptedFiles[0];
    let finalDataSheet = {};

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "array" });

      workbook.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        if (sheetName === "S1 - Flight Details") {
          jsonData.forEach((innerArray: any, index: any) => {
            jsonData[index] = innerArray.map((element) =>
              typeof element === "number"
                ? new Date((element - 25569) * 86400 * 1000).toLocaleDateString()
                : element
            );
          });
        }
        finalDataSheet[sheetName] = jsonData;
      });

      const res = await finalSheetUpload(finalDataSheet, currentUserID);
      console.log("RES: ", res);
      setFinalSheetURL(res);

      let tempFieldValues = _.cloneDeep(fieldValues);
      tempFieldValues[0]["sheetURL"] = res;
      setFieldValues(tempFieldValues);
      setLoading(false);
    };
    reader.readAsArrayBuffer(file.file);
  };

  const updateFiles = async (acceptedFiles) => {
    setXlsSheet(acceptedFiles);

    const file = acceptedFiles[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "array" });

      // Accessing the first sheet of the workbook (assuming only one sheet)
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Converting the worksheet data into an array of objects (one object per row)
      const rows: any = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Now you can access data for each row
      const headerRow = rows[0];
      const headerRowLength = rows[0].length;

      let finalDict = {};
      let site = "";
      let placementGroup = "";

      rows.forEach((row: any) => {
        if (row.length === headerRowLength && row[0] !== "Site Name") {
          if (row[0] !== undefined) {
            site = row[0];
          }
          if (row[headerRowLength - 2] === undefined) {
            placementGroup = row[1];
            finalDict[placementGroup] = [];
          } else {
            const placement = row[1];
            const adFormat = row[5];
            const findSize = placement.match(/(\d+x\d+)/);
            const findLanguage = placement.match(/.*[_-](EN|FR)[_-].*/);
            const size = findSize ? findSize[1] : "";
            const language = findLanguage ? findLanguage[1] : "EN";

            finalDict[placementGroup].push({
              site: site,
              placement: placement,
              size: size,
              language: language,
              adFormat: adFormat,
            });
          }
        }
      });
      setPlacementMetadata(finalDict);
    };
    reader.readAsArrayBuffer(file.file);
  };

  const genarateConfigSheet = async () => {
    setLoading(true);
    let sheetsData = await traficking_sheet_Generator(
      placementMetadata,
      assetNames,
      fieldValues[0].PlacementPattern,
      currentUserID,
      filetoUpload,
      fieldValues[0].advertiserId,
      fieldValues[0].profileId
    );

    const wb = XLSX.utils.book_new();

    let siteNames = [...new Set(sheetsData[0].data.slice(1).map((item) => item[0]))];

    // sheetsData.push({name: "Audience Placement Mapping", data: []})

    // console.log("sheet data");
    // console.log(sheetsData);

    // sheetsData.forEach((sheet) => {
    //   // console.log('asdh')
    //   console.log(sheet)
    // });

    sheetsData.forEach((sheet) => {
      // const [header, columnsWidth] = styleHeader(sheet.name !== "S6 - Creative Flight Mapping"?sheet.name !== "Audience Placement Mapping"?sheet.data.shift():["Placement Group / Audience Name 2"]:["Creative Theme", "Landing Page URL"],
      const [header, columnsWidth] = styleHeader(
        sheet.name !== "S6 - Creative Flight Mapping"
          ? sheet.data.shift()
          : ["Creative Theme", "Landing Page URL"],
        [
          "S1 - Flight Details",
          "S3 - Placement Name Setup",
          "S5 - CP Configs",
          "Audience Placement Mapping",
        ].includes(sheet.name)
          ? headerColor.green
          : headerColor.orange
      );
      let ws = XLSX.utils.aoa_to_sheet([header]);
      ws["!cols"] = columnsWidth;

      if (sheet.name !== "S6 - Creative Flight Mapping") {
        const cells = styleCells(sheet.data);
        XLSX.utils.sheet_add_aoa(ws, cells, { origin: "A2" });
      }
      if (sheet.name !== "Audience Placement Mapping") {
        const cells = styleCells(sheet.data);
        XLSX.utils.sheet_add_aoa(ws, cells, { origin: "A2" });
      }
      if (sheet.name === "S6 - Creative Flight Mapping")
        ws[`B1`].s.fill = { fgColor: { rgb: headerColor.green } };

      if (sheet.name === "S2 - Creative - Theme Mapping")
        ws["B1"].s.fill = { fgColor: { rgb: headerColor.green } };

      if (sheet.name === "S4 - Placement Name Audit") {
        ws["C1"].s.fill = { fgColor: { rgb: headerColor.green } };
        let placementPattern = fieldValues[0].PlacementPattern.split(">>");
        let patternLength = placementPattern.length;
        let counter = 1;
        let secondCounter = 0;
        let formula;
        let lastIsOptional = false;

        placementPattern.forEach((pattern) => {
          for (let i = 2; i <= sheet.data.length; i++) {
            formula = `=_xlfn.INDEX(_xlfn.TEXTSPLIT(A${i}, "_"), ${counter})`;

            if (/^\(.+\)$/.test(pattern)) {
              // lastIsOptional = true;
              formula = `_xlfn.IF(_xlfn.ISERROR(_xlfn.INDEX(_xlfn.TEXTSPLIT(A${i}, "_"), ${
                counter + 1
              })), "", _xlfn.INDEX(_xlfn.TEXTSPLIT(A${i}, "_"), ${counter}))`;
            } else if (lastIsOptional) {
              // lastIsOptional = false;
              formula = `_xlfn.IF(${String.fromCharCode(
                counter - 1 + "C".charCodeAt(0)
              )}${i} = "", _xlfn.INDEX(_xlfn.TEXTSPLIT(A${i}, "_"), ${
                counter - 1
              }), _xlfn.INDEX(_xlfn.TEXTSPLIT(A${i}, "_"), ${counter}))`;
            }

            XLSX.utils.sheet_add_aoa(ws, [[{ f: formula }]], {
              origin: `${String.fromCharCode(counter + "C".charCodeAt(0))}` + i,
            });
          }
          if (/^\(.+\)$/.test(pattern)) {
            lastIsOptional = true;
          } else if (lastIsOptional) {
            lastIsOptional = false;
          }
          counter++;
        });

        let errorCounterStart = counter + 3;
        let errorCounterEnd;

        placementPattern.forEach((pattern) => {
          for (let i = 2; i <= sheet.data.length; i++) {
            XLSX.utils.sheet_add_aoa(
              ws,
              [
                [
                  {
                    f: `_xlfn.IF(_xlfn.COUNTIF('S3 - Placement Name Setup'!${String.fromCharCode(
                      secondCounter + "A".charCodeAt(0)
                    )}:${String.fromCharCode(
                      secondCounter + "A".charCodeAt(0)
                    )},${String.fromCharCode(
                      secondCounter + "D".charCodeAt(0)
                    )}${i}) > 0, ${String.fromCharCode(
                      secondCounter + "D".charCodeAt(0)
                    )}${i}, "ERROR")`,
                  },
                ],
              ],
              { origin: `${numberToLetters(counter + 3)}${i}` }
              // String.fromCharCode(counter + "C".charCodeAt(0))}
            );
          }
          counter++;
          secondCounter++;
        });
        errorCounterEnd = counter + 3;

        for (let i = 2; i <= sheet.data.length; i++) {
          XLSX.utils.sheet_add_aoa(
            ws,
            [
              [
                {
                  f: `IF(COUNTIF(${numberToLetters(errorCounterStart)}${i}:${numberToLetters(
                    errorCounterEnd
                  )}${i},"ERROR") > 0, "ERROR","")`,
                },
              ],
            ],
            { origin: "B" + i }
          );
        }
      }

      if (sheet.name === "Inputs - Creative Assets") {
        for (let i = 2; i <= 200; i++) {
          XLSX.utils.sheet_add_aoa(
            ws,
            [
              [
                {
                  f: `IF(ISBLANK(A${i}), "", VLOOKUP(B${i}, 'S2 - Creative - Theme Mapping'!$A$2:$B$19, 2, FALSE))`,
                },
              ],
            ],
            { origin: "C" + i }
          );
        }
      }

      if (sheet.name === "S5 - CP Configs") {
        for (let col of ["A", "B", "C", "D"]) {
          ws[`${col}1`].s.fill = { fgColor: { rgb: headerColor.blue } };
        }
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                t: "s",
                f: `_xlfn.UNIQUE(_xlfn.FILTER('S2 - Creative - Theme Mapping'!$B$2:$B$982, 'S2 - Creative - Theme Mapping'!$B$2:$B$982<>0))`,
                F: `A2`,
                D: 1,
              },
            ],
          ],
          { origin: "A2" }
        );

        for (let i = 2; i <= 200; i++) {
          XLSX.utils.sheet_add_aoa(
            ws,
            [
              [
                {
                  f: `_xlfn.IF(_xlfn.ISBLANK($A$${i}), " ", _xlfn.TEXTSPLIT($A$${i}, " "))`,
                  F: `B${i}`,
                  D: 1,
                },
              ],
            ],
            { origin: "B" + i }
          );
          const targetTypeCol = header.findIndex((col) => col.v === "Targeting Type");
          if (targetTypeCol > -1) {
            XLSX.utils.sheet_add_aoa(
              ws,
              [[{ f: `IF($B$${i}="P", "PROSPECTING", IF($B$${i}="R", "RETARGETING", ""))` }]],
              { origin: String.fromCharCode(69 + targetTypeCol) + i }
            );
          }
        }
      }

      if (sheet.name === "S6 - Creative Flight Mapping") {
        let colLookup = ["C", "D", "E", "F", "G", "H", "J", "K", "I", "L", "M", "N"];
        for (let i = 2; i <= 200; i++) {
          colLookup.forEach((col) => {
            XLSX.utils.sheet_add_aoa(
              ws,
              [[{ f: `IF(OR(ISBLANK(${col}1), ISBLANK(A${i})), "", "FALSE")` }]],
              { origin: `${col}${i}` }
            );
          });
        }

        ws["A2"] = {
          t: "s",
          f: `_xlfn.UNIQUE(_xlfn.FILTER('Inputs - Creative Assets'!$C$2:$C$300, 'Inputs - Creative Assets'!$C$2:$C$300<>""))`,
          F: "A2",
          D: 1,
        };

        ws["C1"] = {
          t: "s",
          f: `_xlfn.TRANSPOSE(_xlfn.FILTER(_xlfn.UNIQUE('S1 - Flight Details'!$A$2:$A$1000), _xlfn.UNIQUE('S1 - Flight Details'!$A$2:$A$1000)<>""))`,
          F: "C1",
          D: 1,
        };
      }
      // console.log("HERE1")
      // console.log(sheet.name)
      if (sheet.name === "Audience Placement Mapping") {
        // console.log("HERE2");
        let colLookup = ["B", "C", "D", "E", "F", "G", "H", "J", "K", "I", "L", "M", "N"];
        for (let i = 2; i <= 200; i++) {
          colLookup.forEach((col) => {
            XLSX.utils.sheet_add_aoa(
              ws,
              [[{ f: `IF(OR(ISBLANK(${col}1), ISBLANK(A${i})), "", "FALSE")` }]],
              { origin: `${col}${i}` }
            );
          });
        }

        ws["A2"] = {
          t: "s",
          f: `_xlfn.UNIQUE(_xlfn.FILTER('Inputs - Placements'!$B$2:$B$300, 'Inputs - Placements'!$B$2:$B$300<>""))`,
          F: "A2",
          D: 1,
        };

        // ws["B1"] =
        // {
        //   t:"s",
        //   f: `_xlfn.TRANSPOSE(_xlfn.FILTER(_xlfn.UNIQUE('S1 - Flight Details'!$A$2:$A$1000), _xlfn.UNIQUE('S1 - Flight Details'!$A$2:$A$1000)<>""))`,
        //   F: "B1",
        //   D: 1
        // }
      }

      XLSX.utils.book_append_sheet(wb, ws, sheet.name);
    });

    let [siteHeaders, siteSheetCols] = styleHeader(["Site Name", "Site ID"]);
    let siteWs = XLSX.utils.aoa_to_sheet([siteHeaders]);
    siteWs["!cols"] = siteSheetCols;
    siteWs["B1"].s.fill = { fgColor: { rgb: headerColor.green } };

    const siteCells = styleCells(siteNames.map((site) => [site + "", ""]));
    XLSX.utils.sheet_add_aoa(siteWs, siteCells, { origin: "A2" });
    XLSX.utils.book_append_sheet(wb, siteWs, "S7 - Site Mapping");

    let sheetData = [
      ["Profile ID", fieldValues[0].profileId],
      ["Advertiser ID", fieldValues[0].advertiserId],
      ["Campaign Name"],
      ["Campaign Landing Page URL"],
    ];

    let campaignSetupWs = XLSX.utils.aoa_to_sheet(sheetData);

    for (let cell of ["A1", "A2", "A3", "A4"]) {
      campaignSetupWs[cell].s = {
        fill: { fgColor: { rgb: headerColor.green } },
        font: { bold: true },
      };
    }
    campaignSetupWs["!cols"] = [{ wch: 30 }, { wch: 40 }];

    XLSX.utils.book_append_sheet(wb, campaignSetupWs, "Campaign Setup");

    // let audienceSheetData = [
    //   ["Placement Group/Audience"],
    // ];

    // let audiencePlacementSetupWs = XLSX.utils.aoa_to_sheet(audienceSheetData);

    // for(let cell of ["A1"]){
    //   audiencePlacementSetupWs[cell].s={fill:{fgColor: { rgb: headerColor.green }}, font:{bold :true}}
    // }
    // audiencePlacementSetupWs["!cols"]=[{wch:30},{wch:40}]

    // XLSX.utils.book_append_sheet(wb, audiencePlacementSetupWs, "Audience Placement Mapping");

    const xlsxBuffer = XLSX.write(wb, { type: "buffer", bookType: "xlsx" });

    const blob = new Blob([xlsxBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Yuniter Traficking Config Sheet.xlsx`);

    setIsSheetuploaded(true);
    setLoading(false);
  };

  return (
    <div className="rounded-[10px] shadow-ingreSummaryBox bg-white py-5 px-7">
      {fieldValues.length > 0 ? (
        <>
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={() => setOpen(!open)}
          >
            <div className="flex gap-16">
              <p className="font-bold text-lg">{metadata.title}</p>
            </div>
            <ArrowDown />
          </div>
          {open && (
            <div className="text-primary mt-3 my-6">
              <p className="font-normal text-sm">{metadata.description}</p>
              <div className="flex flex-col justify-center w-full border border-primary rounded-xl p-3 my-8">
                {metadata?.instructions?.map((instruction: any, index: any) => (
                  <div key={index} className="flex flex-row items-center my-2">
                    <div className="flex justify-center items-center w-8 h-8 rounded-full bg-slate-300 mr-5">
                      <p>{index}</p>
                    </div>
                    <div
                      className="[&_a]:text-[#003366] [&_a]:font-medium"
                      dangerouslySetInnerHTML={{ __html: `${instruction}` }}
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-row items-center justify-start gap-x-2 mb-5">
                <span className="text-sm">STEP 1: Generate Final Trafficking Sheet</span>
                <label className="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={isCmUpload}
                    onChange={(e) => {
                      let tempFieldValues = _.cloneDeep(fieldValues);
                      tempFieldValues[0]["isCmUpload"] = !isCmUpload;
                      setFieldValues(tempFieldValues);
                      setIsCmUpload(!isCmUpload);
                    }}
                  />
                  <div
                    className={`w-10 h-6 border-2 rounded-full left-2
                    peer-checked:after:translate-x-full after:absolute after:top-1 after:left-[5px]
                    after:bg-primary after:rounded-full after:h-4 after:w-4 after:transition-all`}
                  />
                </label>
                <span className="text-sm">STEP 2: Upload Entities to CM360</span>
              </div>
              {isCmUpload ? (
                <div className="flex flex-col my-4">
                  <p className="font-semibold mr-2 mt-4 min-w-max text-sm">
                    Upload Final Trafficking Sheet to upload all entities to CM360
                  </p>
                  <Dropzone
                    style={{ height: "60%", flexBasis: "25%" }}
                    onChange={uploadFinalTraffickingSheet}
                    value={finalTraffickingSheet}
                  >
                    {finalTraffickingSheet.map((file, i) => (
                      <FileMosaic key={i} {...file} preview />
                    ))}
                  </Dropzone>
                </div>
              ) : (
                <>
                  <TextField
                    type={"string"}
                    label={"Placement Name Pattern"}
                    fieldData={{
                      fieldValues: _.cloneDeep(fieldValues),
                      setFieldValues: setFieldValues,
                      index: 0,
                      fieldName: "PlacementPattern",
                      description: "Placement Pattern for you Placement Names",
                    }}
                  />
                  <TextField
                    type={"string"}
                    label={"Advertiser ID"}
                    fieldData={{
                      fieldValues: _.cloneDeep(fieldValues),
                      setFieldValues: setFieldValues,
                      index: 0,
                      fieldName: "advertiserId",
                      description: "CM360 Advertiser ID to create all entities",
                    }}
                  />
                  <TextField
                    type={"string"}
                    label={"Profile ID"}
                    fieldData={{
                      fieldValues: _.cloneDeep(fieldValues),
                      setFieldValues: setFieldValues,
                      index: 0,
                      fieldName: "profileId",
                      description: "CM360 Profile ID of the user",
                    }}
                  />
                  <div className="flex flex-col my-4">
                    <p className="font-semibold mr-2 mt-4 min-w-max text-sm">
                      Upload Placements Sheet
                    </p>
                    <Dropzone
                      style={{ height: "60%", flexBasis: "25%" }}
                      onChange={updateFiles}
                      value={xlsSheet}
                    >
                      {xlsSheet.map((file, i) => (
                        <FileMosaic key={i} {...file} preview />
                      ))}
                    </Dropzone>
                  </div>
                  <div className="flex flex-col my-4">
                    <p className="font-semibold mr-2 mt-4 min-w-max text-sm">
                      Upload Creative Assets Zip File
                    </p>
                    <Dropzone
                      style={{ height: "60%", flexBasis: "25%" }}
                      onChange={uploadAssets}
                      value={assetFiles}
                    >
                      {assetFiles.map((file, i) => (
                        <FileMosaic key={i} {...file} preview />
                      ))}
                    </Dropzone>
                  </div>
                  <DafaultButton
                    text={"Generate Config Sheet & Upload Assets to CM360"}
                    color={"info"}
                    textColor={"#FFFFFF"}
                    radius={"6px"}
                    onClick={() => genarateConfigSheet()}
                    disabled={
                      assetFiles.length === 0 ||
                      xlsSheet.length === 0 ||
                      fieldValues[0].PlacementPattern === ""
                    }
                  />
                  {isSheetuploaded ? (
                    <div className="flex flex-col my-4">
                      <p className="font-semibold mr-2 mt-4 min-w-max text-sm">
                        Upload Congif Sheet
                      </p>
                      <Dropzone
                        style={{ height: "60%", flexBasis: "25%" }}
                        onChange={uploadFinalConfigSheet}
                        value={finalXlsSheet}
                      >
                        {finalXlsSheet.map((file, i) => (
                          <FileMosaic key={i} {...file} preview />
                        ))}
                      </Dropzone>
                    </div>
                  ) : (
                    <></>
                  )}{" "}
                </>
              )}
            </div>
          )}{" "}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TrafickingSheetMain;
