import MultiSelectDropdown from "components/Common/MultiSelectDropdown";
import SingleSelectDropdown from "components/Common/SingleSelectDropdown";
import {
  filterList,
  RecipeIngredientsData,
} from "components/Common/staticData";
import React from "react";

const SearchSubHeader = ({
  title,
  onIngredientTypeChange,
  onIngredientPlatformChange,
}: any) => {
  return (
    <div className={`items-center`}>
      <div className={`flex justify-between items-center`}>
        <div className="text-base my-2 text-primary font-bold">{title}</div>
        <div className={"flex gap-4"}>
          <SingleSelectDropdown
            title={RecipeIngredientsData.ByCategory}
            placeholder={RecipeIngredientsData.All}
            option={filterList?.ByIngredients}
            onChange={onIngredientTypeChange}
          />
          <MultiSelectDropdown
            title={RecipeIngredientsData.ByPlatform}
            placeholder={RecipeIngredientsData.All}
            option={filterList?.ByDestination}
            onChange={onIngredientPlatformChange}
          />
          <SingleSelectDropdown
            title={RecipeIngredientsData.ByIngredients}
            placeholder={RecipeIngredientsData.All}
            option={filterList?.ByIngredients}
            onChange={onIngredientTypeChange}
          />
        </div>
      </div>
      <hr className={`border-1 border-solid border-[# 667080] my-1`} />
    </div>
  );
};

export default SearchSubHeader;
