import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useLocation, useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import PageHeader from "components/PageHeader/PageHeader";
import { DafaultButton } from "components/Common/Button";
import { ArrowBack } from "@mui/icons-material";
import { ArrowForward } from "@mui/icons-material";
import setLoading from "features/helper";
import { createRole } from "features/roles/roleSlice";
import _ from "lodash";

const RoleCreation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  let location: any = useLocation();
  const role = useSelector((state: any) => state.roles.role);
  const currentUser = useSelector((state: any) => state.auth.user);

  const [roleName, setRoleName] = useState("");
  const [roleDesc, setRoleDesc] = useState("");
  const [isNext, setIsNext] = useState(false);

  const handleNextButton = () => {
    let tempRole = _.cloneDeep(role);
    setLoading(true);
    setIsNext(true);
    if (roleName !== "") {
      tempRole.name = roleName;
      dispatch(createRole(tempRole)).then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'new_role_created',
          'user_id': currentUser.userID,
          'tenant_id': currentUser.tenant,
          'role_name': roleName
        });
        setLoading(false);
        navigate("/roles");
      });
    }
  };

  return (
    <div className="p-[0_2.5%] w-[82%] ml-[18%]">
      <PageHeader title={"Create a New Role"} />

      <div className="mt-16">
        <label className="block mb-2 text-base font-bold text-primary">
          Name:
        </label>
        <input
          type="text"
          id="first_name"
          className={`bg-gray-50 border border-gray-300 text-primary text-sm rounded-lg block 
          p-2.5 w-1/2`}
          placeholder="Untitled"
          value={roleName}
          onChange={(e) => {
            setRoleName(e.target.value);
          }}
          required
        />
        {isNext && roleName === "" ? (
          <span className="flex items-center font-medium tracking-wide text-red text-xs mb-10 mt-1 ml-1">
            Please provide a valid Role Name
          </span>
        ) : (
          <div className="mb-10"></div>
        )}

        <label className="block mb-2 text-base font-bold text-primary">
          Description:
        </label>
        <textarea
          rows={10}
          className="block p-2.5 text-sm text-primary bg-gray-50 rounded-lg border
           border-gray-300 w-2/3  mb-10"
          placeholder="Role Description..."
          value={roleDesc}
          onChange={(e) => {
            setRoleDesc(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-row justify-between mr-[33%]">
        <DafaultButton
          text="Back"
          startIcon={<ArrowBack />}
          color={"primary"}
          onClick={() => navigate(-1)}
          outlined
        />

        <DafaultButton
          text={"Create New Role"}
          endIcon={<ArrowForward />}
          color={"success"}
          onClick={() => handleNextButton()}
          textColor="white"
          width="20rem"
        />
      </div>
    </div>
  );
};

export default RoleCreation;
