import Select from "react-select";
import React from "react";
import { SingleSelectDropdownStyles } from "components/Styles";

const SingleSelectDropdown = ({
  title,
  placeholder,
  option,
  onChange,
  defaultValue
}: any) => {
  const filterContainer = "flex items-start justify-between text-primary";
  const nameSection = "font-light whitespace-nowrap";

  return (
    <div className={filterContainer}>
      <p className={nameSection}>{title}: &nbsp;</p>
      <Select
        defaultValue={{value: defaultValue, label: defaultValue}}
        options={option}
        placeholder={placeholder}
        styles={SingleSelectDropdownStyles}
        isSearchable={false}
        menuPortalTarget={document.body}
        onChange={onChange}
        // menuIsOpen={true}
      />
    </div>
  );
};

export default SingleSelectDropdown;
