import RecipeCard from "components/Dashboard/RecipeCard";
import PageHeader from "components/PageHeader/PageHeader";
import React, { useEffect, useState } from "react";
import FilteringSection from "components/Dashboard/DashboardFilters";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../app/store";
import { useSelector } from "react-redux";
import { clearRecipeState, filteredRecipeState, listRecipes } from "features/recipe/recipeSlice";
import _ from "lodash";
import setLoading from "features/helper";
import noRecipesIllustartion from "assets/images/noRecipesIllustartion.png";
import { HeaderButton } from "components/Common/Button";
import { useNavigate } from "react-router-dom";
import Popup from "components/Common/Popup";
import TAndC from "components/Common/TandC";
import { updateTandC } from "features/users/userSlice";
import TandC from "components/TandC";
import jwt_decode from "jwt-decode";
import { setUser } from "features/auth/authSlice";
import { toast } from "react-toastify";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const recipeList = useSelector((state: any) => state.recipe.recipeList);
  const currentUser = useSelector((state: any) => state.auth.user);
  const recipeFilters = useSelector((state: any) => state?.recipe?.recipeFilters);

  const [filteredRecipe, setFilteredRecipe] = useState(recipeList);

  const [sortByFilter, setSortByFilter] = useState(recipeFilters.sortBy);
  const [statusFilter, setStatusFilter] = useState(recipeFilters.status);
  const [roleFilter, setRoleFilter] = useState(recipeFilters.role);
  const [dateFilter, setDateFilter] = useState(recipeFilters.date);

  const [showPopup, setShowPopup] = useState(true);

  const [appliedDate, setAppliedDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const onStatusChange = (selected) => {
    var array = [];
    selected.forEach((element) => {
      array.push(element.value);
    });
    setStatusFilter(array);
    filteredArray(sortByFilter, array, dateFilter, roleFilter);
  };

  const onSortByChange = (selected) => {
    setSortByFilter(selected.value);
    filteredArray(selected.value, statusFilter, dateFilter, roleFilter);
  };

  const onRecipeFilterChange = (selected) => {
    setRoleFilter(selected.value);
    filteredArray(sortByFilter, statusFilter, dateFilter, selected.value);
  };

  const onDateRangeFilter = (buttonDate, status) => {
    setAppliedDate(buttonDate);
    var date = [];
    if (status === "Apply") {
      var startDate = buttonDate[0].startDate;
      var endDate = buttonDate[0].endDate;
      endDate.setHours(23, 59, 59, 999);
      date = [startDate, endDate];
      setDateFilter([startDate, endDate]);
    } else {
      setDateFilter([]);
    }
    filteredArray(sortByFilter, statusFilter, date, roleFilter);
  };

  const filteredArray = (sortBy, status, date, roleRecipeFilter) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'dashboard_filter',
      'status': sortBy,
      'sort_by': status,
      'date_range': date,
      'user_id': currentUser.userID,
      'tenant_id': currentUser.tenant
    });

    var filterState = {
      sortBy: sortBy,
      status: status,
      date: date,
      role: roleRecipeFilter
    }

    dispatch(filteredRecipeState(filterState))

    setLoading(true);
    dispatch(listRecipes(currentUser.tenant)).then((res) => {
      var array = _.cloneDeep(res.payload);
      if (status.length !== 0) {
        array = array.filter(function (recipe) {
          return status.includes(recipe.status.toUpperCase());
        });
      }

      if (roleRecipeFilter === 'User') {
        array = array.filter(function (a) {
          return a.user_id == currentUser.userID
        });
      } else if (roleRecipeFilter === 'Groups') {
        array = array.filter(function (a) {
          let hasPermission = a.group.some((obj1: any) => {
            return currentUser.groups.find((obj2: any) => {
              return obj1.name === obj2.name;
            });
          });
          return hasPermission
        });
      }
  
      if (date.length !== 0) {
        array = array.filter(function (a) {
          var curDate = new Date(a.created);
  
          return curDate >= date[0] && curDate <= date[1];
        });
      }
  
      if (sortBy === "Author") {
        array.sort((a, b) => {
          if (a.author < b.author) {
            return -1;
          }
          if (a.author > b.author) {
            return 1;
          }
          return 0;
        });
      } else if (sortBy === "Status") {
        array.sort((a, b) => {
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        });
      } else if (sortBy === "Date Created") {
        array.sort(function (a, b) {
          return new Date(b.created).valueOf() - new Date(a.created).valueOf();
        });
      } else if (sortBy === "Last Run") {
        array.sort(function (a, b) {
          return new Date(b.last_run).valueOf() - new Date(a.last_run).valueOf();
        });
      }
      setFilteredRecipe(array);
      setLoading(false);
    })
    .catch((e) => {
      console.log(e);
      toast.error("Could not filter Reciped. Please try again.", {
        position: "bottom-right",
        autoClose: 8080,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    });
  };

  const onClick = () => {
    dispatch(clearRecipeState()).then(() => {
      navigate("/recipe_details", { state: { copy: false, id: null } });
    });
  };

  const confirmTandC = () => {
    dispatch(updateTandC({
      "userId" : currentUser.userID,
      "tc_verified": true
  })).then((res: any) => {
      localStorage.setItem("Authorization", res.payload.idToken);
      const tokenData: any = jwt_decode(res.payload.idToken);
      let userRole = tokenData.payload.roles[0];
      userRole.resources = JSON.parse(userRole.resources);
      let user: any = {
        name: tokenData?.payload?.name,
        // email: tokenData.email,
        userID: tokenData?.payload?.id,
        picture: tokenData?.payload?.imageUrl,
        domain: tokenData?.payload?.organisation?.domain,
        tenant: tokenData?.payload?.organisation?.id,
        role: userRole,
        groups: tokenData?.payload?.groups,
        tc_verified: tokenData?.payload?.tc_verified,
      };
      dispatch(setUser(user)).then(() => {
        navigate("/recipes")
      });
    });
  };

  useEffect(() => {
    setFilteredRecipe(recipeList);
  }, [recipeList]);

  useEffect(() => {}, [filteredRecipe]);

  useEffect(() => {
    setLoading(true);
    dispatch(listRecipes(currentUser.tenant)).then(() => {
      filteredArray(sortByFilter, statusFilter, dateFilter, roleFilter);
      setLoading(false);
    });
  }, []);

  return (
    <div className="p-[0_2.5%] w-[82%] m-0 float-right">
      {!currentUser?.tc_verified && showPopup && (
        <TandC
          setOpenModal={setShowPopup}
          text={<TAndC />}
          redirect={confirmTandC}
        />
      )}
      <PageHeader title={"Dashboard"} >
        <FilteringSection
          currentUser={currentUser}
          statusChange={onStatusChange}
          onSortByChange={onSortByChange}
          onDateRangeFilter={onDateRangeFilter}
          onRecipeFilterChange={onRecipeFilterChange}
          roleFilter={roleFilter}
          statusFilter={statusFilter}
          sortByFilter={sortByFilter}
          dateFilter={dateFilter}
          appliedDate={appliedDate}
        />
      </PageHeader>
      {filteredRecipe?.length > 0  && typeof filteredRecipe === "object" ? (
        filteredRecipe?.map((recipe, i) => (
          <RecipeCard 
            recipe={recipe}
            filteredRecipes={filteredRecipe} 
            setFilteredRecipe={setFilteredRecipe} 
            i={i} 
            key={i} 
          />
        ))
      ) : (
        <div className="flex flex-col justify-center items-center">
          <img
            style={{ height: "40%", width: "40%" }}
            src={noRecipesIllustartion}
            alt=""
          />
          <p className="text-primary text-3xl font-bold">
            No Recipes Available
          </p>
          <p className="text-primary text-center mt-5">
            There are no recipes to show you right now. <br /> Add a recipe to
            begin.
          </p>
          <div className="mt-10">
            <HeaderButton
              text={"Create new Recipe"}
              onClick={onClick}
              disabled={
                currentUser.role.resources.Create
                  ? !currentUser.role.resources.Create.includes("Create")
                  : true
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
