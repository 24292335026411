import ApiOperations from "features/ApiOperations";
import config from "components/Common/config";
import setLoading from "features/helper";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import { saveAs } from "file-saver";
import {
  config1,
  config2,
  config3,
  configSheetMetadata,
  defaultMappings,
  entityMapping,
  supportedDestinations,
} from "./CMToManyConfig";

export const qa_campaign = async (profileID, campaignId, currentUserId) => {
  setLoading(true);
  const response = await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/users/qa_campaign/${profileID}/${campaignId}/${currentUserId}`,
    (status, data) => {
      return data;
    }
  );
  setLoading(false);
  return response;
};

export const qa_trafficking_sheet = async (tfSheet, currentUserId) => {
  setLoading(true);
  let payload = { url: tfSheet };
  const response = await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/users/qa_trafficking_sheet/${currentUserId}`,
    payload,
    (status, data) => {
      return data;
    }
  );
  setLoading(false);
  return response;
};

export const excelSheetToSpreadsheetTF = async (payload, currentUserID) => {
  setLoading(true);
  let finalPayload = {};
  let finalSheetPayload = {};
  let sheetTabNames = [];

  ["Trafficking Sheet", "Traficking Sheet Metadata"].map((tab) => {
    if (payload[tab]) {
      finalSheetPayload[tab] = [];
      sheetTabNames.push(tab);
    }
    payload[tab]?.map((element) => {
      if (element.length > 0) {
        if (!element.every((value) => value === "empty" || value === "" || value === false)) {
          finalSheetPayload[tab].push(element);
        }
      }
    });
  });

  finalPayload["finalSheetPayload"] = finalSheetPayload;
  finalPayload["sheetTabNames"] = sheetTabNames;
  finalPayload["sheetName"] = "CMTM Destinations Config Sheet";

  const response = await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/users/metadata_sheet_Uplaoder/${currentUserID}`,
    finalPayload,
    (status, data) => {
      return data;
    }
  );
  setLoading(false);
  return response;
};

export const excelSheetToSpreadsheet = async (payload, currentUserID) => {
  setLoading(true);
  let finalPayload = {};
  let finalSheetPayload = {};
  let sheetTabNames = [];

  ["Package - Destination Mapping"].concat(supportedDestinations).map((tab) => {
    if (payload[tab]) {
      finalSheetPayload[tab] = [];
      sheetTabNames.push(tab);
    }
    payload[tab]?.map((element) => {
      if (element.length > 0) {
        if (!element.every((value) => value === "empty" || value === "" || value === false)) {
          finalSheetPayload[tab].push(element);
        }
      }
    });
  });

  finalPayload["finalSheetPayload"] = finalSheetPayload;
  finalPayload["sheetTabNames"] = sheetTabNames;
  finalPayload["sheetName"] = "CMTM Destinations Config Sheet";

  const response = await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/users/metadata_sheet_Uplaoder/${currentUserID}`,
    finalPayload,
    (status, data) => {
      return data;
    }
  );
  setLoading(false);
  return response;
};

export const excelSheetToSpreadsheet2 = async (payload, currentUserID) => {
  setLoading(true);
  let finalPayload = {};
  let finalSheetPayload = {};
  let sheetTabNames = [];

  supportedDestinations.map((tab) => {
    if (payload[tab]) {
      finalSheetPayload[tab] = [];
      sheetTabNames.push(tab);
    }
    payload[tab]?.map((element) => {
      if (element.length > 0) {
        if (!element.every((value) => value === "empty" || value === "" || value === false)) {
          finalSheetPayload[tab].push(element);
        }
      }
    });
  });

  finalPayload["finalSheetPayload"] = finalSheetPayload;
  finalPayload["sheetTabNames"] = sheetTabNames;
  finalPayload["sheetName"] = "CMTM Final Preview Sheet";

  const response = await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/users/metadata_sheet_Uplaoder/${currentUserID}`,
    finalPayload,
    (status, data) => {
      return data;
    }
  );
  setLoading(false);
  return response;
};

export const trafficking_sheet_qa_sheet_builder = async (data) => {
  setLoading(true);
  const workbook = new ExcelJS.Workbook();
  for (const sheet in data) {
    let finalData = [["Package", "Placement", "Creative"]];
    if (sheet === "Entities with Inconsistent Conf") {
      finalData[0] = ["Package", "Placement", "Creative", "Inconsistency"];
    }
    let rowCount = 1;
    let mergePackageArray = [];
    let mergePlacementArray = [];
    let mergePackageObject = { start: 1, end: 1 };
    let mergePlacementObject = { start: 1, end: 1 };
    let previousPackage = "";
    let previousPlacement = "";
    if (data[sheet].package.length > 0) {
      data[sheet].package.map((row) => {
        finalData.push([row]);
        rowCount++;
      });
    }
    if (data[sheet].placement.length > 0) {
      data[sheet].placement.map((row) => {
        const rowArray = row.split(", ");
        const packageName = rowArray[0].split(": ")[1];
        const placementName = rowArray[1].split(": ")[1];
        finalData.push([packageName, placementName]);
        rowCount++;
        if (packageName === previousPackage) {
          mergePackageObject.end++;
        } else {
          previousPackage = packageName;
          if (mergePackageObject.start != mergePackageObject.end) {
            mergePackageArray.push({
              start: mergePackageObject.start,
              end: mergePackageObject.end + 1,
            });
          }
          mergePackageObject.start = mergePackageObject.end + 2;
          mergePackageObject.end++;
        }
      });
    }
    mergePackageObject = { start: rowCount - 1, end: rowCount - 1 };
    mergePlacementObject = { start: rowCount - 1, end: rowCount - 1 };
    if (data[sheet].creative.length > 0) {
      data[sheet].creative.map((row) => {
        const rowArray = row.split(", ");
        const packageName = rowArray[0].split(": ")[1];
        const placementName = rowArray[1].split(": ")[1];
        let creativeName = rowArray[2].split(": ")[1];
        let inconsistency;
        if (rowArray.length >= 5) {
          inconsistency = `${rowArray[3]}, ${rowArray[4]}`;
        }
        finalData.push([packageName, placementName, creativeName, inconsistency]);

        if (packageName === previousPackage) {
          mergePackageObject.end++;
        } else {
          previousPackage = packageName;
          if (mergePackageObject.start != mergePackageObject.end) {
            mergePackageArray.push({
              start: mergePackageObject.start,
              end: mergePackageObject.end + 1,
            });
          }
          mergePackageObject.start = mergePackageObject.end + 2;
          mergePackageObject.end++;
        }
        if (placementName === previousPlacement) {
          mergePlacementObject.end++;
        } else {
          previousPlacement = placementName;
          if (mergePlacementObject.start != mergePlacementObject.end) {
            mergePlacementArray.push({
              start: mergePlacementObject.start,
              end: mergePlacementObject.end + 1,
            });
          }
          mergePlacementObject.start = mergePlacementObject.end + 2;
          mergePlacementObject.end++;
        }
      });
    }
    if (mergePackageObject.start != mergePackageObject.end) {
      mergePackageArray.push({ start: mergePackageObject.start, end: mergePackageObject.end + 1 });
    }
    if (mergePlacementObject.start != mergePlacementObject.end) {
      mergePlacementArray.push({
        start: mergePlacementObject.start,
        end: mergePlacementObject.end + 1,
      });
    }
    const ws = workbook.addWorksheet(sheet);

    finalData.forEach((row, rowIndex) => {
      const excelRow = ws.addRow(row);
      excelRow.commit();
    });

    mergePackageArray.map((element) => {
      ws.mergeCells(`A${element.start}:A${element.end}`);
      const cell = ws.getCell(`A${element.start}`);
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    mergePlacementArray.map((element) => {
      ws.mergeCells(`B${element.start}:B${element.end}`);
      const cell = ws.getCell(`B${element.start}`);
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    boldRow(ws, 0);
    setColumnWidths(ws);
  }
  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, "Trafficking Sheet Errors.xlsx");
  setLoading(false);
};

export const cmtmFinalSheetDownloader = async (currentUserID, url, supportedDestinations) => {
  setLoading(true);

  let finalUrl = url[url.length - 2].match(/(https?:\/\/[^\s]+)/)[1];
  const wb = new ExcelJS.Workbook();

  generateLookupSheets(supportedDestinations, wb);

  for (const destination of supportedDestinations) {
    const response = await ApiOperations.post(
      `${config.apiGateway.tenantURL}/api/v1/users/users/getTrafickingSheetFinalData/${currentUserID}`,
      { url: finalUrl, range: `'${destination}'` },
      (status, data) => {
        return data;
      }
    );
    let counter = 0;
    let headerRows = [];
    for (const row of response) {
      counter++;
      if (row.length === 0) headerRows.push(counter);
    }

    const ws = wb.addWorksheet(destination);
    let configParameters;
    let isConfig1 = true,
      isConfig2 = true;
    let validationCol, validationCol2, validationCol3;
    let mergeCounterStart = 0;
    let mergeCounterEnd = 0;
    let mergeValue = "";
    response.forEach((row, rowIndex) => {
      // if (mergeValue === row[0]) {
      //   mergeCounterEnd++;
      // } else {
      //   if (mergeCounterEnd !== mergeCounterStart) {
      //     try {
      //       ws.mergeCells(`A${mergeCounterStart}:A${mergeCounterEnd}`);
      //     } catch {
      //       console.log("Merged");
      //     }
      //   }
      //   mergeCounterEnd++;
      //   mergeValue = row[0];
      //   mergeCounterStart = mergeCounterEnd;
      // }

      if (rowIndex === 0 || headerRows.includes(rowIndex)) configParameters = row;
      if (rowIndex >= headerRows[1] - 1) {
        isConfig1 = false;
        validationCol2 = validationCol;
      } else if (headerRows.length > 1 && rowIndex >= headerRows[2] - 1) {
        isConfig2 = false;
        validationCol3 = validationCol2;
      } else {
        validationCol = "A";
      }
      row.forEach((cellValue, columnIndex) => {
        const cell = ws.getCell(rowIndex + 1, columnIndex + 1);
        if (isConfig1 && !(rowIndex === 0 || headerRows.includes(rowIndex))) {
          const foundObject = config1[destination].find(
            (obj) => obj.v === configParameters[columnIndex]
          );
          if (foundObject && foundObject.dropdpwnOptions) {
            addDropdownToRangeCells(
              ws,
              destination + " Lookup",
              `${validationCol}2:${validationCol}40`,
              cell,
              cellValue
            );
            validationCol = colNameIncrement(validationCol);
          }
        } else if (!isConfig1 && !(rowIndex === 0 || headerRows.includes(rowIndex))) {
          const foundObject = config2[destination].find(
            (obj) => obj.v === configParameters[columnIndex]
          );
          if (foundObject && foundObject.dropdpwnOptions) {
            addDropdownToRangeCells(
              ws,
              destination + " Lookup",
              `${validationCol2}2:${validationCol2}40`,
              cell,
              cellValue
            );
            validationCol2 = colNameIncrement(validationCol2);
          }
        } else if (!isConfig2 && !(rowIndex === 0 || headerRows.includes(rowIndex))) {
          const foundObject = config3[destination].find(
            (obj) => obj.v === configParameters[columnIndex]
          );
          if (foundObject && foundObject.dropdpwnOptions) {
            addDropdownToRangeCells(
              ws,
              destination + " Lookup",
              `${validationCol3}2:${validationCol3}40`,
              cell,
              cellValue
            );
            validationCol3 = colNameIncrement(validationCol3);
          }
        }

        cell.value = cellValue;
        cell.alignment = { vertical: "middle", horizontal: "center" };
        if (rowIndex === 0 || headerRows.includes(rowIndex))
          cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "EBF1DE" } };
      });
    });

    // if (mergeCounterEnd !== mergeCounterStart) {
    //   ws.mergeCells(`A${mergeCounterStart}:A${mergeCounterEnd}`);
    // }

    setColumnWidths(ws);
    ws.getColumn(1).width = 60;
    ws.getColumn(2).width = 60;
    wrapTextInColumn(ws, 0);
    wrapTextInColumn(ws, 1);

    boldRow(ws, 0);
    for (const rowNumber of headerRows) boldRow(ws, rowNumber);
  }
  const buffer = await wb.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, "CMTM Final Preview Sheet.xlsx");

  setLoading(false);
};

const setColumnWidths = (worksheet) => {
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const columnLength = cell.value ? cell.value.toString().length + 5 : 10; // Default width if cell is empty
      if (columnLength > maxLength) {
        maxLength = columnLength;
      }
    });
    column.width = maxLength < 10 ? 10 : maxLength; // Minimum width of 10
  });
};

export const generateCMTMConfigSheet = async (
  profileID,
  campaignId,
  supportedDestinations,
  currentUserId
) => {
  setLoading(true);

  let finalPackageData = [["Placement Group ID", "Placement Group Name"]];

  supportedDestinations.forEach((element) => {
    finalPackageData[0].push(element);
  });

  const response = await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/users/fetch_placementGroups/${profileID}/${campaignId}/${currentUserId}`,
    (status, data) => {
      data.forEach((element) => {
        finalPackageData.push([element.id, element.name]);
      });
      return data;
    }
  );

  const workbook = new ExcelJS.Workbook();

  // Populate Package - Destination Mapping sheet
  const ws = workbook.addWorksheet("Package - Destination Mapping");

  finalPackageData.forEach((row, rowIndex) => {
    const excelRow = ws.addRow(row);
    excelRow.commit();
  });

  const wsCols = [{ width: 20 }, { width: 50 }];
  wsCols.push(...new Array(13).fill({ width: 15 }));

  ws.columns = wsCols;
  wrapTextInColumn(ws, 1);
  boldRow(ws, 0);
  addDropdownToRange(
    ws,
    "C2",
    `${String.fromCharCode(66 + supportedDestinations.length)}${finalPackageData.length}`,
    ["FALSE", "TRUE"]
  );

  generateLookupSheets(supportedDestinations, workbook);

  supportedDestinations.map((sheetName) => {
    const finalConfigSheetMetadata = entityMapping;
    const ws = workbook.addWorksheet(sheetName);

    finalConfigSheetMetadata.forEach((row, rowIndex) => {
      const excelRow = ws.addRow(row);
      excelRow.commit();
    });

    configSheetMetadata[sheetName].forEach((value, index) => {
      const cell = ws.getCell(`A${8 + index}`);
      if (value.dropdpwnOptions) {
        addDropdownToRange(ws, `B${8 + index}`, `B${8 + index}`, value.dropdpwnOptions);
      }
      cell.value = value.v;
    });

    for (const cell in defaultMappings[sheetName]) {
      addDropdownToRange(ws, cell, cell, defaultMappings[sheetName][cell]);
    }

    if (config1[sheetName]?.length > 0) {
      let validationCol = "A";
      config1[sheetName].forEach((value, index) => {
        const cell = ws.getCell(`E${2 + index}`);
        if (value.dropdpwnOptions) {
          const dropdownCell = ws.getCell(`F${2 + index}`);
          addDropdownToRangeCells(
            ws,
            sheetName + " Lookup",
            `${validationCol}2:${validationCol}40`,
            dropdownCell,
            value.dropdpwnOptions[0]
          );
          validationCol = colNameIncrement(validationCol);
        }
        cell.font = { bold: true };
        if (value.v.endsWith("*")) {
          ws.getCell(`F${2 + index}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "F2DCDB" },
          };
          cell.value = {
            richText: [
              {
                text: value.v.slice(0, -1),
              },
              {
                text: "*",
                font: {
                  color: {
                    argb: "00FF0000",
                    theme: 1,
                  },
                },
              },
            ],
          };
        } else {
          cell.value = value.v;
        }
        if (index == 0) {
          cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "DCE6F1" } };
        } else {
          cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "EBF1DE" } };
        }
      });
      ws.getColumn(5).width = 50;
      ws.getColumn(6).width = 60;
      if (config2[sheetName]?.length > 0) {
        config2[sheetName].forEach((value, index) => {
          const cell = ws.getCell(`H${2 + index}`);
          if (value.dropdpwnOptions) {
            const dropdownCell = ws.getCell(`I${2 + index}`);
            addDropdownToRangeCells(
              ws,
              sheetName + " Lookup",
              `${validationCol}2:${validationCol}40`,
              dropdownCell,
              value.dropdpwnOptions[0]
            );
            validationCol = colNameIncrement(validationCol);
          }
          if (value.v.endsWith("*")) {
            ws.getCell(`I${2 + index}`).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "F2DCDB" },
            };
            cell.value = {
              richText: [
                {
                  text: value.v.slice(0, -1),
                },
                {
                  text: "*",
                  font: {
                    color: {
                      argb: "00FF0000",
                      theme: 1,
                    },
                  },
                },
              ],
            };
          } else {
            cell.value = value.v;
          }
          cell.font = { bold: true };
          if (index == 0) {
            cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "DCE6F1" } };
          } else {
            cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "E4DFEC" } };
          }
        });
        ws.getColumn(8).width = 50;
        ws.getColumn(9).width = 60;
      }
      if (config3[sheetName]?.length > 0) {
        config3[sheetName].forEach((value, index) => {
          const cell = ws.getCell(`K${2 + index}`);
          if (value.dropdpwnOptions) {
            const dropdownCell = ws.getCell(`L${2 + index}`);
            addDropdownToRangeCells(
              ws,
              sheetName + " Lookup",
              `${validationCol}2:${validationCol}40`,
              dropdownCell,
              value.dropdpwnOptions[0]
            );
            validationCol = colNameIncrement(validationCol);
          }
          if (value.v.endsWith("*")) {
            ws.getCell(`L${2 + index}`).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "F2DCDB" },
            };
            cell.value = {
              richText: [
                {
                  text: value.v.slice(0, -1),
                },
                {
                  text: "*",
                  font: {
                    color: {
                      argb: "00FF0000",
                      theme: 1,
                    },
                  },
                },
              ],
            };
          } else {
            cell.value = value.v;
          }
          cell.font = { bold: true };
          if (index == 0) {
            cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "DCE6F1" } };
          } else {
            cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "DDD9C4" } };
          }
        });
        ws.getColumn(11).width = 50;
        ws.getColumn(12).width = 60;
      }
    }

    boldCol(ws, 0);
    ws.getCell("A2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "DCE6F1" },
    };
    ws.getCell("A8").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "DCE6F1" },
    };
    ws.getColumn(1).width = 40;
    ws.getColumn(2).width = 40;
    ws.mergeCells("E2:F2");
    ws.mergeCells("H2:I2");
    ws.mergeCells("K2:L2");
    ws.mergeCells("A2:B2");
    ws.mergeCells("A8:B8");
  });

  // Write the workbook to a buffer
  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, "CMTM Config Sheet.xlsx");

  setLoading(false);
};

function generateLookupSheets(supportedDestinations, workbook) {
  supportedDestinations.map((sheetName) => {
    const ws = workbook.addWorksheet(sheetName + " Lookup");
    ws.state = "hidden";
    let colCounter = "A";
    if (config1[sheetName]?.length > 0) {
      config1[sheetName].forEach((value, index) => {
        let rowCounter = 1;
        if (value.dropdpwnOptions) {
          const cell = ws.getCell(`${colCounter}${rowCounter}`);
          cell.value = value.v;
          cell.font = { bold: true };
          rowCounter++;
          value.dropdpwnOptions.forEach((option) => {
            const cell = ws.getCell(`${colCounter}${rowCounter}`);
            cell.value = option;
            rowCounter++;
          });
          colCounter = colNameIncrement(colCounter);
        }
      });
    }
    if (config2[sheetName]?.length > 0) {
      config2[sheetName].forEach((value, index) => {
        let rowCounter = 1;
        if (value.dropdpwnOptions) {
          const cell = ws.getCell(`${colCounter}${rowCounter}`);
          cell.value = value.v;
          cell.font = { bold: true };
          rowCounter++;
          value.dropdpwnOptions.forEach((option) => {
            const cell = ws.getCell(`${colCounter}${rowCounter}`);
            cell.value = option;
            rowCounter++;
          });
          colCounter = colNameIncrement(colCounter);
        }
      });
    }
    if (config3[sheetName]?.length > 0) {
      config3[sheetName].forEach((value, index) => {
        let rowCounter = 1;
        if (value.dropdpwnOptions) {
          const cell = ws.getCell(`${colCounter}${rowCounter}`);
          cell.value = value.v;
          cell.font = { bold: true };
          rowCounter++;
          value.dropdpwnOptions.forEach((option) => {
            const cell = ws.getCell(`${colCounter}${rowCounter}`);
            cell.value = option;
            rowCounter++;
          });
          colCounter = colNameIncrement(colCounter);
        }
      });
    }
  });
}

function boldCol(worksheet, colIndex) {
  worksheet.getColumn(colIndex + 1).eachCell((cell) => {
    cell.font = { bold: true };
  });
}

function wrapTextInColumn(worksheet, colIndex) {
  worksheet.getColumn(colIndex + 1).eachCell((cell) => {
    cell.alignment = { wrapText: true };
  });
}

function boldRow(worksheet, rowIndex) {
  const row = worksheet.getRow(rowIndex + 1);
  row.eachCell((cell) => {
    cell.font = { bold: true };
  });
}

function addDropdownToRange(worksheet, startCell, endCell, dropdownValues) {
  const dataValidation = {
    type: "list",
    allowBlank: false,
    formulae: [`"${dropdownValues.join(",")}"`],
  };

  const start = worksheet.getCell(startCell);
  const end = worksheet.getCell(endCell);

  for (let row = start.row; row <= end.row; row++) {
    for (let col = start.col; col <= end.col; col++) {
      const cell = worksheet.getCell(row, col);
      cell.dataValidation = dataValidation;
      cell.value = dropdownValues[0];
      cell.alignment = { vertical: "middle", horizontal: "center" };
    }
  }
}

function addDropdownToRangeCells(worksheet, lookupSheet, cellRange, cell, defaultValue) {
  const dataValidation = {
    type: "list",
    allowBlank: false,
    formulae: [`='${lookupSheet}'!${cellRange}`],
  };

  cell.dataValidation = dataValidation;
  cell.value = defaultValue;
  cell.alignment = { vertical: "middle", horizontal: "center" };
}

function colNameIncrement(temp) {
  let arr = temp.split("");
  for (let i = arr.length - 1; i >= 0; i--) {
    if (arr[i] === "Z") {
      arr[i] = "A";
      if (i === 0) arr.unshift("A");
    } else {
      arr[i] = String.fromCharCode(arr[i].charCodeAt(0) + 1);
      return arr.join("");
    }
  }
  return arr.join("");
}
