import React, { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const MultiSelect = ({
  width = "w-1/2",
  options,
  selected,
  setSelected,
  onChange,
  placeholderText,
}: any) => {
  const [open, setOpen] = useState(false);
  // const [selected, setSelected] = useState(selectedGroups);

  return (
    <>
      <div
        onClick={() => setOpen(!open)}
        className={`flex flex-row min-h-max max-h-32 justify-between items-center cursor-pointer text-primary text-sm border ${
          open
            ? "rounded-[0.5rem_0.5rem_0_0] border-x-gray-300 border-t-gray-300 border-b-[#B2B2B2]"
            : "rounded-lg border-gray-300"
        }
    p-2.5 ${width}`}
      >
        {selected.length > 0 ? (
          <div className="flex flex-row flex-wrap min-h-max">
            {selected?.map((select, i) => (
              <div
                key={i}
                className="rounded-3xl py-1 px-5 border border-[#979797] text-center mr-2	"
              >
                <p key={i} className="text-[#696969] text-xs">
                  {select}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <>
            <p>{placeholderText}</p>
          </>
        )}
        <KeyboardArrowDownIcon />
      </div>
      {open ? (
        options?.map((option, i) => (
          <div
            onClick={() => {
              var array = [...selected];
              if (!array.includes(option)) array.push(option);
              else {
                const index = array.indexOf(option);
                array.splice(index, 1);
              }
              setSelected(array);
              onChange(array);
            }}
            key={i}
            className={`flex flex-row items-center border-[0px_1px_1px_1px] border-x-gray-300 text-primary text-sm
          p-2.5 ${width} h-10 cursor-pointer focus:bg-gray ${
              options.length === i + 1
                ? "rounded-[0_0_0.5rem_0.5rem] border-b-gray-300"
                : "border-y-[#B2B2B2]"
            }`}
          >
            <input
              type="checkbox"
              readOnly
              checked={selected.includes(option)}
              className="form-checkbox text-primary mr-8"
            ></input>
            {option}
          </div>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default MultiSelect;
