import ApiOperations from "../ApiOperations";
import config from "components/Common/config";

const listRoles = async () => {
  let x = await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/roles`,
    (status, data) => {
      return data;
    }
  );
  return x;
};

const createRole = async (payload: any) => {
  let res = await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/roles/`,
    payload,
    (status, data) => {
      return data.id;
    }
  );

  return res;
};

const updateRole = async (id: any, payload: any) => {
  await ApiOperations.patch(
    `${config.apiGateway.tenantURL}/api/v1/users/roles/` + id,
    payload,
    (status, data) => {}
  );

  return;
};

const deleteRole = async (id: any) => {
  await ApiOperations.delete(
    `${config.apiGateway.tenantURL}/api/v1/users/roles/` + id,
    (status, data) => {}
  );

  return;
};

const getRole = async (id: any) => {
  let response = await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/roles/` + id,
    (status, data) => {
      data.resources = JSON.parse(data.resources);
      return data;
    }
  );

  return response;
};

const roleService = {
  listRoles,
  deleteRole,
  getRole,
  createRole,
  updateRole,
};

export default roleService;
