import React, { useEffect, useState } from "react";
import { ArrowDown } from "assets/icons/Icons";
import Dropdown from "components/Recipes/RecipeSummary/Dropdown";
import _ from "lodash";
import TextField from "components/Recipes/RecipeSummary/TextField";
import {
  loadCmCampaigns,
  loadCmSites,
  loadDvAdvertisers,
  loadDvElements,
  uploadCmCreatives,
  loadDvLIElements,
  generateRTMappingSheet,
} from "./RTFunctions";
import { DafaultButton } from "components/Common/Button";
import MultiSelect from "components/Common/MultiSelect";
import { Dropzone, FileMosaic } from "@files-ui/react";
import JSZip from "jszip";
import Select from "react-select";
import RTFinalMapping from "./RTFinalMapping";
import RTMapped from "./RTMapped";
import { useSelector } from "react-redux";

const RTMain = ({ recipe, fieldValues, setFieldValues, is_mapped }: any) => {
  const currentUserID = useSelector((state: any) => state.auth.user.userID);

  const [open, setOpen] = useState(true);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [campaignMetadata, setCampaignMetadata] = useState({});
  const [dvAdvertiserOptions, setDvAdvertiserOptions] = useState([]);
  const [dvLIOptions, setDvLIOptions] = useState([]);
  const [selectedDvLIOptions, setSelectedDvLIOptions] = useState(
    fieldValues[0]["DvOptions"] ? fieldValues[0]["DvOptions"] : []
  );
  const [files, setFiles] = React.useState([]);
  const [filestoUpload, setFilesToUpload] = useState([]);
  const [fileTree, setFileTree] = useState({});
  const [fileMetadata, setFileMetadata] = useState({});
  const [uploadedCreatives, setUploadedCreatives] = useState([]);
  const [sites, setSites] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [isTable, setIsTable] = useState(false);
  const [finalSheet, setFinalSheet] = useState(
    fieldValues[0]["sheetUrl"] ? fieldValues[0]["sheetUrl"] : ""
  );

  let existingRecipe =
    fieldValues[0]["mappings"]?.[0] !== undefined
      ? "placementID" in fieldValues[0]["mappings"][0]
      : false;

  useEffect(() => {
    let finalMetadata = [];
    let tempFieldValues = _.cloneDeep(fieldValues);

    for (const element in metadata) {
      // if (metadata[element].check) {
      finalMetadata.push(metadata[element]);
      // }
    }
    tempFieldValues[0]["mappings"] = finalMetadata;
    setFieldValues(tempFieldValues);
  }, [metadata]);

  useEffect(() => {
    if (
      fieldValues[0]["userProfile"] !== "" &&
      fieldValues[0]["partnerID"] !== "" &&
      fieldValues[0]["campaign"]
    ) {
      loadCmCampaigns(
        fieldValues[0]["profileID"],
        setCampaignOptions,
        setCampaignMetadata,
        currentUserID
      );
      loadCmSites(fieldValues[0]["profileID"], setSites, currentUserID);
      loadDvAdvertisers(fieldValues[0]["partnerID"], setDvAdvertiserOptions, currentUserID);

      let tempUploadedCreatives = [];
      let tempSelectedDVElements = [];
      let tempCampaignID = fieldValues[0]["campaign"].split(" : ")[0];
      let tempCampaignMetadata = {};

      tempCampaignMetadata[tempCampaignID] = {
        id: fieldValues[0]["mappings"]["campaignID"],
        startDate: fieldValues[0]["mappings"]["startDate"],
        endDate: fieldValues[0]["mappings"]["endDate"],
      };

      fieldValues[0]["mappings"].forEach((element) => {
        tempUploadedCreatives.push({
          id: element.creativeID,
          name: element.creativeName,
          size: element.size,
        });
        tempSelectedDVElements.push(element.lineItemID);
      });

      loadDvLIElements(
        fieldValues[0]["dvAdvertiser"],
        setDvLIOptions,
        tempSelectedDVElements,
        setSelectedDvLIOptions,
        currentUserID
      );

      setCampaignMetadata(tempCampaignMetadata);
      setUploadedCreatives(tempUploadedCreatives);
      setMetadata(fieldValues[0]["mappings"]);
    }
  }, []);

  const updateFiles = async (incommingFiles) => {
    setFiles(incommingFiles);
    let tempFileTree: any = {};
    let tempFilestoUpload: any = [];
    for (const file of incommingFiles) {
      if (file["type"] === "application/zip") {
        const zip = new JSZip();
        await zip.loadAsync(file.file).then(function (zip) {
          let tempArray: any = [];
          for (const childFile in zip.files) {
            let fileName = zip.files[childFile]["name"].split("/");

            if (
              ["png", "jpg", "jpeg"].some((char) => fileName[fileName.length - 1].endsWith(char)) &&
              !fileName.includes("__MACOSX")
            ) {
              tempArray.push({
                value: zip.files[childFile]["name"],
                label: fileName[fileName.length - 1],
              });
            }
          }
          tempFileTree[file["name"]] = tempArray;
        });
      }
      tempFilestoUpload.push(file);
    }
    setFileTree(tempFileTree);
    setFilesToUpload(tempFilestoUpload);
  };

  const loadCmDv = () => {
    loadCmCampaigns(
      fieldValues[0]["profileID"],
      setCampaignOptions,
      setCampaignMetadata,
      currentUserID
    );
    loadCmSites(fieldValues[0]["profileID"], setSites, currentUserID);
    loadDvAdvertisers(fieldValues[0]["partnerID"], setDvAdvertiserOptions, currentUserID);
  };

  const loadDvLI = () => {
    loadDvElements(fieldValues[0]["dvAdvertiser"], setDvLIOptions, currentUserID);
  };

  const handleUploadCmCreatives = () => {
    let selectedCampaign = fieldValues[0]["campaign"].split(" : ")[0];
    let finalMetadata = fileMetadata;
    finalMetadata["profile_id"] = fieldValues[0]["profileID"];
    finalMetadata["advertiser_id"] = campaignMetadata[selectedCampaign]["advertiserId"];
    finalMetadata["defaultLandingPageId"] =
      campaignMetadata[selectedCampaign]["defaultLandingPageId"];

    uploadCmCreatives(filestoUpload, finalMetadata, setUploadedCreatives, currentUserID);

    if (uploadedCreatives.length > 0 && selectedDvLIOptions.length > 0) {
      let temp = {};

      uploadedCreatives?.forEach((creative) => {
        selectedDvLIOptions.forEach((li) => {
          let creativeId = creative["id"];
          let lineItemID = li.split(" : ")[0];
          let index = creativeId + "_" + lineItemID;

          temp[index] = {
            check: false,
            name: "",
            creativeID: creative.id,
            creativeName: creative.name,
            lineItemID: lineItemID,
            size: creative.size,
            startDate: campaignMetadata[fieldValues[0]["campaign"].split(" : ")[0]].startDate,
            endDate: campaignMetadata[fieldValues[0]["campaign"].split(" : ")[0]].endDate,
            campaignID: campaignMetadata[fieldValues[0]["campaign"].split(" : ")[0]].id,
          };
        });
      });
      setMetadata(temp);
    }
  };

  const generateMappingSheet = async (uploadedCreatives, selectedDvLIOptions) => {
    let res = await generateRTMappingSheet(currentUserID, uploadedCreatives, selectedDvLIOptions);
    console.log("URL: ", res);
    window.open(res, "_blank");
    setFinalSheet(res);
    let tempFieldValues = _.cloneDeep(fieldValues);
    tempFieldValues[0]["sheetUrl"] = res;
    setFieldValues(tempFieldValues);

    if (uploadedCreatives.length > 0 && selectedDvLIOptions.length > 0) {
      let temp = {};

      uploadedCreatives?.forEach((creative) => {
        selectedDvLIOptions.forEach((li) => {
          let creativeId = creative["id"];
          let lineItemID = li.split(" : ")[0];
          let lineItemName = li.split(" : ")[1];
          let index = creativeId + "_" + lineItemID;

          temp[index] = {
            check: false,
            name: "",
            creativeID: creative.id,
            creativeName: creative.name,
            lineItemID: lineItemID,
            lineItemName: lineItemName,
            size: creative.size,
            startDate: campaignMetadata[fieldValues[0]["campaign"].split(" : ")[0]].startDate,
            endDate: campaignMetadata[fieldValues[0]["campaign"].split(" : ")[0]].endDate,
            campaignID: campaignMetadata[fieldValues[0]["campaign"].split(" : ")[0]].id,
          };
        });
      });
      setMetadata(temp);
    }
  };

  return (
    <div className="rounded-[10px] shadow-ingreSummaryBox bg-white py-5 px-7">
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <div className="flex gap-16">
          <p className="font-bold text-lg">{recipe.title}</p>
        </div>
        <ArrowDown />
      </div>
      {open && (
        <div className="text-primary mt-3 my-6">
          <p className="font-normal text-sm">{recipe.description}</p>
          <div className="flex flex-col justify-center w-full border border-primary rounded-xl p-3 my-8">
            {recipe?.instructions?.map((instruction: any, index: any) => (
              <div key={index} className="flex flex-row items-center my-2">
                <div className="flex justify-center items-center w-8 h-8 rounded-full bg-slate-300 mr-5">
                  <p>{index}</p>
                </div>
                <div
                  className="[&_a]:text-[#003366] [&_a]:font-medium"
                  dangerouslySetInnerHTML={{ __html: `${instruction}` }}
                />
              </div>
            ))}
          </div>
          {existingRecipe ? (
            <RTMapped mapping={fieldValues[0]["mappings"]} is_mapped={is_mapped} />
          ) : (
            <>
              <TextField
                type={"string"}
                label={"CM360 User Profile ID"}
                fieldData={{
                  fieldValues: _.cloneDeep(fieldValues),
                  setFieldValues: setFieldValues,
                  index: 0,
                  fieldName: "profileID",
                  description: "CM360 User Profile ID to Load",
                }}
              />
              <TextField
                type={"string"}
                label={"DV360 Partner ID"}
                fieldData={{
                  fieldValues: _.cloneDeep(fieldValues),
                  setFieldValues: setFieldValues,
                  index: 0,
                  fieldName: "partnerID",
                  description: "DV360 Partner ID to Load",
                }}
              />
              <DafaultButton
                text={"Load CM and DV Elements"}
                color={"info"}
                textColor={"#FFFFFF"}
                width={"14rem"}
                radius={"6px"}
                onClick={() => loadCmDv()}
                disabled={false}
              />
              {fieldValues[0]["campaign"] !== undefined || campaignOptions?.length > 0 ? (
                <>
                  <Dropdown
                    options={campaignOptions}
                    label={"CM360 Campaign"}
                    fieldData={{
                      fieldValues: _.cloneDeep(fieldValues),
                      setFieldValues: setFieldValues,
                      index: 0,
                      fieldName: "campaign",
                      description: "CM360 Cmapaign to load",
                    }}
                  />
                  <Dropdown
                    options={sites}
                    label={"CM360 Site"}
                    fieldData={{
                      fieldValues: _.cloneDeep(fieldValues),
                      setFieldValues: setFieldValues,
                      index: 0,
                      fieldName: "siteID",
                      description: "CM360 Site ID",
                    }}
                  />
                  <Dropdown
                    options={dvAdvertiserOptions}
                    label={"DV360 Advertiser"}
                    fieldData={{
                      fieldValues: _.cloneDeep(fieldValues),
                      setFieldValues: setFieldValues,
                      index: 0,
                      fieldName: "dvAdvertiser",
                      description: "DV360 Advertiser ID",
                    }}
                  />
                  <DafaultButton
                    text={"Load DV Line Items"}
                    color={"info"}
                    textColor={"#FFFFFF"}
                    width={"14rem"}
                    radius={"6px"}
                    onClick={() => loadDvLI()}
                    disabled={false}
                    margin={"1rem 0 0 0"}
                  />{" "}
                </>
              ) : null}
              {dvLIOptions.length > 0 || fieldValues[0]["mappings"].length > 0 ? (
                <>
                  <p className="font-semibold mr-2 mt-4 min-w-max text-sm">DV360 Line Items</p>
                  <MultiSelect
                    options={dvLIOptions}
                    selected={selectedDvLIOptions}
                    onChange={(e) => {
                      let tempFieldValues = _.cloneDeep(fieldValues);
                      tempFieldValues[0]["DvOptions"] = e;
                      setFieldValues(tempFieldValues);
                    }}
                    setSelected={setSelectedDvLIOptions}
                    width={"w-full"}
                  />
                  <div className="flex flex-row my-4">
                    <Dropzone
                      style={{ height: "60%", flexBasis: "25%" }}
                      onChange={updateFiles}
                      value={files}
                    >
                      {files.map((file, i) => (
                        <FileMosaic key={i} {...file} preview />
                      ))}
                    </Dropzone>
                    <div className="basis-3/4 ml-8">
                      {files.length > 0 ? (
                        <table className="min-w-full divide-y divide-gray-200 table-fixed shadow  border">
                          <thead className="bg-secondary/90 shadow">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-md w-1/2 font-bold text-center text-black uppercase  "
                              >
                                File Name
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-md w-1/2 font-bold text-center text-black uppercase"
                              >
                                Backup Image
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {files.map((file: any, index: any) => {
                              if (file["type"] === "application/zip") {
                                return (
                                  <tr key={index}>
                                    <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                                      {file.file.name}
                                    </td>
                                    <td className="min-w-[12.5rem] px-5">
                                      <Select
                                        // disabled={uploadedFiles[index].isUploaded}
                                        placeholder="Backup Image"
                                        options={fileTree[file["name"]]}
                                        onChange={(selected: any) => {
                                          let fileName = file.file.name.replace(/\.[^/.]+$/, "");
                                          let tempMetadata: any = _.cloneDeep(fileMetadata);
                                          tempMetadata[fileName] = selected.value;
                                          setFileMetadata(tempMetadata);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </table>
                      ) : null}
                    </div>
                  </div>
                  <DafaultButton
                    text={"Upload creatives to CM360"}
                    color={"info"}
                    textColor={"#FFFFFF"}
                    width={"14rem"}
                    radius={"6px"}
                    onClick={() => handleUploadCmCreatives()}
                    disabled={fieldValues[0]["mappings"].length > 0}
                  />
                </>
              ) : null}
              {/* {(uploadedCreatives.length > 0 && selectedDvLIOptions.length > 0) || fieldValues[0]["mappings"].length > 0 ? ( */}
              {uploadedCreatives.length > 0 && selectedDvLIOptions.length > 0 ? (
                <div className="mt-5">
                  <div className="flex items-center justify-start gap-x-2 ml-8 mb-5">
                    <span className="text-sm">Google Sheet</span>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={isTable}
                        onChange={(e) => {
                          setIsTable(!isTable);
                        }}
                      />
                      <div
                        className={`w-10 h-6 border-2 rounded-full left-2
                    peer-checked:after:translate-x-full after:absolute after:top-1 after:left-[5px]
                    after:bg-primary after:rounded-full after:h-4 after:w-4 after:transition-all`}
                      />
                    </label>
                    <span className="text-sm">Mapping Table</span>
                  </div>
                  {!isTable ? (
                    <div>
                      <DafaultButton
                        text={"Generate Speadsheet for Mapping"}
                        color={"info"}
                        textColor={"#FFFFFF"}
                        width={"17rem"}
                        radius={"6px"}
                        onClick={() => generateMappingSheet(uploadedCreatives, selectedDvLIOptions)}
                        // disabled={fieldValues[0]["mappings"].length > 0}
                      />
                      {finalSheet !== "" ? (
                        <a href={finalSheet} className="ml-5 font-bold">
                          Trafficking Spreadsheet Link
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <div>
                      <RTFinalMapping
                        creatives={uploadedCreatives}
                        dvElements={selectedDvLIOptions}
                        metadata={metadata}
                        setMetadata={setMetadata}
                        campaignMetadata={
                          campaignMetadata[fieldValues[0]["campaign"].split(" : ")[0]]
                        }
                      />
                    </div>
                  )}
                </div>
              ) : null}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RTMain;
