import React from "react";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import PauseIcon from "@mui/icons-material/Pause";
// import FlagIcon from "@mui/icons-material/Flag";
// import WarningIcon from "@mui/icons-material/Warning";
// import BuildIcon from "@mui/icons-material/Build";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import QueueIcon from "@mui/icons-material/Queue";
import { DafaultButton } from "./Button";
import { finalSheetDownloader, uploadEntitiesToCM } from "components/TrafickingSheet/TSFunctions";
import { useSelector } from "react-redux";
import { cmtmFinalSheetDownloader } from "components/CMToMany/CMToManyFunction";
// import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";

const LogsPopup = ({ setOpenModal, text, status, is_rt_tool, tasks, recipe }: any) => {
  const currentUserID = useSelector((state: any) => state.auth.user.userID);
  let ad_ops = tasks[0]?.tag === "ad_ops";
  let cm_to_many = tasks[0]?.tag === "cm_to_many";

  const returnIcon = (status) => {
    if (status === "FINISHED") {
      return (
        <CheckCircleOutlineOutlinedIcon
          style={{
            color: "#44D397",
            fontSize: "40px",
          }}
        ></CheckCircleOutlineOutlinedIcon>
      );
    } else if (status === "ERROR") {
      return (
        <ErrorIcon
          style={{
            color: "red",
            fontSize: "40px",
          }}
        ></ErrorIcon>
      );
    } else if (status === "NEW") {
      return (
        <AddCircleOutlineIcon
          style={{
            color: "#007CDC",
            fontSize: "40px",
          }}
        ></AddCircleOutlineIcon>
      );
    } else if (status === "TIMEOUT") {
      return (
        <QueryBuilderIcon
          style={{
            color: "#007CDC",
            fontSize: "40px",
          }}
        ></QueryBuilderIcon>
      );
    } else if (status === "RUNNING") {
      return (
        <DirectionsRunIcon
          style={{
            color: "#007CDC",
            fontSize: "40px",
          }}
        ></DirectionsRunIcon>
      );
    } else if (status === "STOPPED") {
      return (
        <PauseCircleOutlineIcon
          style={{
            color: "#007CDC",
            fontSize: "40px",
          }}
        ></PauseCircleOutlineIcon>
      );
    } else if (status === "QUEUED") {
      return (
        <QueueIcon
          style={{
            color: "#007CDC",
            fontSize: "40px",
          }}
        ></QueueIcon>
      );
    }
  };

  return (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto h-[90%] top-[5%]">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
          onClick={() => setOpenModal(false)}
        ></div>
        <div className="flex items-center min-h-screen">
          <div className="flex flex-col justify-center relative w-full max-w-7xl p-8 mx-auto bg-white rounded-lg shadow-[2px_4px_9px_rgba(0,0,0,0.2)]">
            <div className="flex flex-row items-center mb-5">
              {returnIcon(status)}
              <p className="text-3xl text-primary text-left">{status}</p>
            </div>
            <div className="flex flex-row min-w-full">
              <p className="basis-1/4 text-[0.85rem] text-primary font-medium">Task</p>
              <p className="basis-3/4 text-[0.85rem] text-primary font-medium">Output/Error</p>
            </div>
            <div className="my-5 h-0.5 w-full bg-slate-300 opacity-50" />
            {text?.map((task, i) => {
              return (
                <div key={i}>
                  <div key={i} className="flex flex-row">
                    <div className="basis-1/4 mt-10">
                      <p className="text-[0.85rem] text-primary font-medium mb-5">{task.script}</p>
                      <p className="text-[0.85rem] text-primary font-medium mb-5">{task.event}</p>
                      <p className="text-[0.85rem] text-primary font-medium">{task.ago}</p>
                    </div>
                    <div className="basis-3/4 overflow-hidden">
                      {task.stdout.split("\n").map((item, key) => (
                        <p className="text-[0.85rem] break-words text-primary mb-5" key={key}>
                          {item}
                        </p>
                      ))}
                      {task.stderr.split("\n").map((item, key) => (
                        <p className="text-[0.85rem] break-words text-primary mb-5" key={key}>
                          {item}
                        </p>
                      ))}
                      {ad_ops && status === "FINISHED" ? (
                        <>
                          <DafaultButton
                            text={"Download final Traficking Sheet"}
                            color={"info"}
                            textColor={"#FFFFFF"}
                            radius={"6px"}
                            onClick={() =>
                              finalSheetDownloader(currentUserID, task.stdout.split("\n"))
                            }
                          />
                          <div className="my-4" />
                          <DafaultButton
                            text={"Upload Traficking Sheet to CM360"}
                            color={"info"}
                            textColor={"#FFFFFF"}
                            radius={"6px"}
                            onClick={() =>
                              uploadEntitiesToCM(currentUserID, task.stdout.split("\n"))
                            }
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {cm_to_many && status === "FINISHED" ? (
                        <DafaultButton
                          text={"Download Final Preview Sheet"}
                          color={"info"}
                          textColor={"#FFFFFF"}
                          radius={"6px"}
                          onClick={() =>
                            cmtmFinalSheetDownloader(currentUserID, task.stdout.split("\n"), tasks[0]['values']['supportedDestinations'])
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="my-5 h-0.5 w-full bg-slate-300 opacity-50" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default LogsPopup;
