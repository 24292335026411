import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useNavigate } from "react-router-dom";
import PageHeader from "components/PageHeader/PageHeader";
import viewer from "assets/images/viewer.png";
import manager from "assets/images/manager.png";
import admin from "assets/images/admin.png";

const RoleOptions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const currentUser = useSelector((state: any) => state.auth.user);

  const onClick = (role: any, roleImg: any) => {
    if (role) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'select_role_options',
        'role_option': role,
        'user_id': currentUser.userID,
        'tenant_id': currentUser.tenant
      });
      navigate("/role_summary", {
        state: { predefined: role, predefinedImg: roleImg },
      });
    } else {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'select_role_options',
        'role_option': 'custom_role',
        'user_id': currentUser.userID,
        'tenant_id': currentUser.tenant
      });
      navigate("/user_control");
    }
  };

  return (
    <div className="p-[0_2.5%] w-[82%] ml-[18%]">
      <PageHeader title="Role Options" />
      <div className="w-[90%] ml-[5%] mt-24">
        <div className="grid grid-cols-4 gap-20 mr-20 h-64">
          <button
            onClick={() => onClick("Viewer", viewer)}
            className="flex flex-col items-center justify-center shadow-ingredientCardBoxshadowGray border-ingredientCardBorder border-[0px] 
          rounded-[10px] p-6 min-h-full min-w-full cursor-pointer"
          >
            <img style={{ height: "5.5rem" }} src={viewer} alt="" />
            <p className="text-primary font-bold text-lg mt-3">Viewer</p>
          </button>
          <button
            onClick={() => onClick("Manager", manager)}
            className="flex flex-col items-center justify-center shadow-ingredientCardBoxshadowGray border-ingredientCardBorder border-[0px] 
          rounded-[10px] p-6 min-h-full min-w-full cursor-pointer"
          >
            <img style={{ height: "5.5rem" }} src={manager} alt="" />
            <p className="text-primary font-bold text-lg mt-3">Manager</p>
          </button>
          <button
            onClick={() => onClick("Admin", admin)}
            className="flex flex-col items-center justify-center shadow-ingredientCardBoxshadowGray border-ingredientCardBorder border-[0px] 
          rounded-[10px] p-6 min-h-full min-w-full cursor-pointer"
          >
            <img style={{ height: "5.5rem" }} src={admin} alt="" />
            <p className="text-primary font-bold text-lg mt-3">Admin</p>
          </button>
          <button
            onClick={() => onClick(false, null)}
            className="flex flex-col items-center justify-center shadow-ingredientCardBoxshadowGray border-ingredientCardBorder 
            border-[0px] rounded-[10px] p-6 min-h-full min-w-full bg-[rgba(1,119,251,0.72)] cursor-pointer"
          >
            <p className="text-white font-bold text-lg">Create new Role</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoleOptions;
