import ApiOperations from "../ApiOperations";
import config from "components/Common/config";


// Login user
const login = async (payload) => {
  let res = ApiOperations.post(
    `${config.apiGateway.authURL}/api/v1/auth/login`,
    payload,
    (status, data) => {
      let credentials = [data?.token, data?.refresh]
      if (status > 200) {
        credentials = null;
      }
      return credentials;
    }
  );
  return res;
};

// Logout user
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("Authorization");
};

const authService = {
  logout,
  login,
};

export default authService;
