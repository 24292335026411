import React, { useEffect, useState } from "react";
import {
  allHours,
  DayFirstHours,
  DaySecondHours,
  RecipeSummaryData,
  Weekdays,
} from "components/Common/staticData";
import PageHeader from "components/PageHeader/PageHeader";
import PageSubHeader from "components/IngredientView.tsx/PageSubHeader";
import MultiSelect from "components/Common/MultiSelect";
import { DafaultButton } from "components/Common/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Repeat from "components/Recipes/RecipeSummary/Repeat";
import TimePicker from "components/Recipes/RecipeSummary/TimePicker";
import Calendarrr from "components/Recipes/RecipeSummary/Calendar";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import IngredientsBox from "components/Recipes/RecipeSummary/IngredientsBox";
import CustomTimeSelection from "components/Recipes/RecipeSummary/CustomTimeSelection";
import _ from "lodash";
import Select from 'react-select'
import {
  CreateManualRecipe,
  CreateScheduledRecipe,
  checkPermission,
  editManualRecipe,
  editScheduledRecipe,
  getRecipe,
  runRecipe,
  setAllRecipeDetails,
} from "features/recipe/recipeSlice";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import setLoading from "features/helper";
import { listGroups } from "features/groups/groupSlice";
import { useGoogleLogin } from "@react-oauth/google";
import { login } from "features/auth/authSlice";
import Popup from "components/Common/Popup";
import { toast } from "react-toastify";
import RTMain from "components/RTTool/RTMain";
import TrafickingSheetMain from "components/TrafickingSheet/TrafickingSheetMain";
import CMToManyMain from "components/CMToMany/CMToManyMain";

const RecipeSummary = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const recipe = useSelector((state: any) => state.recipe.recipe);
  const groupOptions = useSelector((state: any) => state.auth.user.groups);
  const currentUser = useSelector((state: any) => state.auth.user);

  const groupOptionsFiltered = groupOptions.map((groups) => {
    return {value: groups.id, label: groups.name};
  });

  let location: any = useLocation();
  const recipeId = location?.state?.id ? location?.state?.id : null;
  const [recipeName, setRecipeName] = useState(recipe?.name);
  const [recipeGroups, setRecipeGroups] = useState(recipe?.groups ? recipe?.groups.map((groups) => {
    return {value: groups.id, label: groups.name};
  }) : []);
  const [recipeDesc, setRecipeDesc] = useState(recipe?.description);

  const [edit, setEdit] = useState(location?.state?.edit ? true : false);
  const [enabled, setEnabled] = useState(((recipe?.week && recipe?.hour) ? recipe?.week.length !== 0 && recipe?.hour.length !== 0 : false) ? true : false);
  const [startTimeOpen, setStartTimeOpen] = useState(false);
  const [repeatOpen, setRepeatOpen] = useState(false);
  const [timeOpen, setTimeOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState(
    recipe?.start_date
      ? moment(recipe?.start_date, "YYYY-MM-DD HH:mm:ss").format("hh:00 A")
      : "09:00 AM"
  );
  const [selectedDate, setSelectedDate] = useState(
    recipe?.start_date ? new Date(recipe?.start_date) : new Date()
  );
  const [selectedRepeat, setSelectedRepeat] = useState(
    recipe?.schedule_type ? recipe?.schedule_type : "Hourly"
  );
  const [customDateSelection, setCustomDateSelection] = useState(
    recipe?.schedule_type === "Custom" ? recipe?.week : []
  );
  const [customTimeSelection, setCustomTimeSelection] = useState(
    recipe?.schedule_type === "Custom" ? recipe?.hour.map(Number) : []
  );
  const [fieldValues, setFieldValues] = useState(recipe?.solutions);
  const [EnablePermissionModal, setenablePermissionModal] = useState(false);

  const containerStyle =
    "p-[0_2.5%] mb-[2.5%] w-[82%] m-0 float-right text-primary";
  const subHeaderStyle =
    "border-[1px] border-[#667080] rounded-[10px] my-4 min-w-full";
  const label = "block mb-2 text-base font-bold text-primary";
  const grid = `grid grid-cols-12 gap-x-2 gap-y-4 items-center text-[#B2B2B2] font-bold my-4 whitespace-nowrap`;
  const scheduleDropdown = `border primary bg-white rounded-[10px] text-sm ${
    enabled ? "cursor-pointer" : ""
  }`;

  useEffect(() => {
    setFieldValues(recipe?.solutions);
  }, [recipe]);

  useEffect(() => {
    if (location?.state?.edit) {
      setLoading(true);
      dispatch(checkPermission(recipeId)).then((res) => {
        setLoading(false);
      })};
  }, []);

  const setFullRecipeDetails = () => {
    let recipedetails = {};

    recipedetails["name"] = recipeName;
    recipedetails["description"] = recipeDesc;
    recipedetails["groups"] = recipeGroups;
    recipedetails["ingredientId"] = recipe?.ingredientId;
    recipedetails["order"] = recipe?.order;
    recipedetails["solutions"] = fieldValues;
    recipedetails["manual"] = recipe?.manual;
    recipedetails["active"] = recipe?.active;
    recipedetails["permissions_enabled"] = recipe?.permissions_enabled;
    recipedetails["week"] = customDateSelection;
    recipedetails["hour"] = customTimeSelection;
    recipedetails["schedule_type"] = selectedRepeat;
    recipedetails["start_date"] = moment(selectedDate, "YYYY-MM-DD").format(
      "YYYY-MM-DD HH:mm:ss"
    );
    recipedetails["active"] = recipe?.active ? recipe?.active : false;

    dispatch(setAllRecipeDetails(recipedetails));
  };

  const CreateRecipe = () => {
    let startTime = parseInt(selectedTime.substring(0, 2));
    let schedulingHour =
      selectedTime.substring(selectedTime.length - 2) === "PM"
        ? (startTime % 12) + 12
        : startTime;

    let temp = selectedDate;
    temp.setHours(schedulingHour, 0, 0);
    setSelectedDate(temp);

    let datalayerObject;

    let finalRecipe = {};
    finalRecipe["name"] = recipeName;
    finalRecipe["group"] = recipeGroups;
    finalRecipe["description"] = recipeDesc;
    finalRecipe["manual"] = recipe?.manual;
    finalRecipe["project_id"] = currentUser.tenant;
    finalRecipe["timezone"] = "America/New_York";
    finalRecipe["solutions"] = fieldValues;
    if (!recipe?.manual) {
      finalRecipe["start_date"] = moment(temp, "YYYY-MM-DD").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      finalRecipe["active"] = recipe?.active ? recipe?.active : false;
      finalRecipe["schedule_type"] = selectedRepeat;
      if (!enabled) {
        finalRecipe["week"] = [];
        finalRecipe["hour"] = [];
      } else if (selectedRepeat == "Hourly") {
        finalRecipe["week"] = Weekdays;
        finalRecipe["hour"] = allHours;
      } else if (selectedRepeat == "Daily") {
        finalRecipe["week"] = Weekdays;
        finalRecipe["hour"] = [schedulingHour];
      } else if (selectedRepeat == "Weekly") {
        finalRecipe["week"] = [moment(temp, "YYYY-MM-DD").format("ddd")];
        finalRecipe["hour"] = [schedulingHour];
      } else if (selectedRepeat == "Custom") {
        finalRecipe["week"] = customDateSelection;
        finalRecipe["hour"] = customTimeSelection;
      }
    }

    let ingredientTitleArr = [];
    for (let index = 0; index < recipe.order.length; index++) {
      ingredientTitleArr.push(recipe.ingredientId[index][recipe.order[index]].title);
    }
    setLoading(true);
    if (recipe?.manual) {
      if (location?.state?.edit) {
        dispatch(editManualRecipe({ finalRecipe, recipeId })).then((res) => {
          dispatch(getRecipe(recipeId)).then((res) => {
            dispatch(checkPermission(recipeId)).then((res) => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': 'edit_recipe',
                "recipe_id": recipeId,
                "recipe_name": recipeName,
                "group_name": recipeGroups,
                "ingredient": ingredientTitleArr,        
                "recipe_type": recipe.manual ? 'manual' : 'schedule',
                'user_id': currentUser.userID,
                'tenant_id': currentUser.tenant
              });

              navigate("/recipe_summary/", { state: { id: recipeId, edit: true } });
              toastOnSave(res)
              setLoading(false);
            });
          });
        });
      } else {                
        let rt_tool = finalRecipe['solutions'][0]['form_prefix'] === "rt_tool_0" ? true : false;
        finalRecipe['rt_tool'] = rt_tool;
        dispatch(CreateManualRecipe(finalRecipe)).then((res) => {
          if (rt_tool) {
            navigate("/recipes");
          } else {
            let newRecipeId = res.payload.data.recipe_id
            dispatch(getRecipe(newRecipeId)).then((res) => {
              dispatch(checkPermission(newRecipeId)).then((res) => {       
                datalayerObject = {
                  'event': 'new_recipe_created',
                  'recipe_name': recipeName,
                  'group_name': recipeGroups,
                  'description': recipeDesc,
                  'scheduling_status': enabled,
                }
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(datalayerObject);

                navigate("/recipe_summary/", { state: { id: newRecipeId, edit: true } });                           
                toastOnSave(res)
                setLoading(false);
              });
            });
          }
        });
      }
    } else {
      if (location?.state?.edit) {
        dispatch(editScheduledRecipe({ finalRecipe, recipeId })).then((res) => {     
          dispatch(getRecipe(recipeId)).then((res) => {
            dispatch(checkPermission(recipeId)).then((res) => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': 'edit_recipe',
                "recipe_id": recipeId,
                "recipe_name": recipeName,
                "group_name": recipeGroups,
                "ingredient": ingredientTitleArr,        
                "recipe_type": recipe.manual ? 'manual' : 'schedule',
                'user_id': currentUser.userID,
                'tenant_id': currentUser.tenant
              });

              navigate("/recipe_summary/", { state: { id: recipeId, edit: true } });
              toastOnSave(res)
              setLoading(false);
            });
          });
        });
      } else {
        dispatch(CreateScheduledRecipe(finalRecipe)).then((res) => {
          let newRecipeId = res.payload.data.recipe_id
          dispatch(getRecipe(newRecipeId)).then((res) => {            
            dispatch(checkPermission(newRecipeId)).then((res) => {
              datalayerObject = {
                'event': 'new_recipe_created',
                'recipe_name': recipeName,
                'group_name': recipeGroups,
                'description': recipeDesc,
                'scheduling_status': enabled,
                'start_date': temp,
                'start_time': selectedTime,
                'repeat': selectedRepeat,
                'timezone': "America/New_York",
                'user_id': currentUser.userID,
                'tenant_id': currentUser.tenant
              }
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push(datalayerObject);

              navigate("/recipe_summary/", { state: { id: newRecipeId, edit: true } });
              toastOnSave(res)
              setLoading(false);
            });
          });
        });
      }
    }
  };

  const toastOnSave = (res) => {
    if(res.payload.status >= 400) {
      toast.error(res.payload.data, {
        position: "bottom-right",
        autoClose: 8080,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.success(location?.state?.edit ? "Successfully saved changes" : "Successfully created Recipe", {
        position: "bottom-right",
        autoClose: 8080,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const EnablePermissionText = () => {
    return (
      <div className="flex flex-col text-primary mb-10">
        <p className="text-center mb-5">Please Enable the following permissions to run <strong>{recipe?.name}</strong></p>
        <ul className="flex flex-col list-inside	list-disc">
        {recipe?.permissionsReq?.map((data, index) => (
          <li key={index} className="ml-5">
            {data}
          </li>
        ))}
        </ul>
      </div>
    )
  }

  const handleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setLoading(true);
      dispatch(
        login({ code: codeResponse.code, isIncrementalPermissions: true, recipe_id: recipeId, scopes: codeResponse.scope })
      ).then(() => {
        dispatch(checkPermission(recipeId)).then((res) => {
          setLoading(false);
          navigate("/auth", { state: { id: recipeId } });
        })
      });
    },
    scope: recipe?.permissionsReq ? recipe?.permissionsReq.join(' ') : '',
    flow: "auth-code",
    redirect_uri: "https://developers.google.com/oauthplayground",
  });

  return (
    <div className={containerStyle}>
      <PageHeader title={RecipeSummaryData.RecipeSummary} />
      <div className="m-8 mr-32">
        {EnablePermissionModal &&  <Popup 
          setOpenModal={setenablePermissionModal} 
          text={<EnablePermissionText />} 
          redirect={handleLogin} />}
        {/* Section 1 */}
        <div className="flex flex-row flex-wrap">
          <div className="basis-1/2 flex flex-col">
            <div className="px-4">
              <label className={label}>{RecipeSummaryData.Name}:</label>
              <input
                type="text"
                id="first_name"
                className={subHeaderStyle}
                placeholder="Untitled"
                value={recipeName}
                onChange={(e) => {
                  setRecipeName(e.target.value);
                }}
                required
              />
            </div>
            <div className="px-4">
              <label className={label}>{RecipeSummaryData.Groups}:</label>
              {/* <MultiSelect
                width="w-full"
                options={groupOptionsFiltered}
                selected={recipeGroups}
                setSelected={setRecipeGroups}
              /> */}
              <Select 
              defaultValue={recipeGroups}
                options={groupOptionsFiltered} 
                isMulti 
                className="w-1/2" 
                onChange={(e) => {setRecipeGroups(e)}} 
              />
              {/* <MultiSelect width="w-full" /> */}
            </div>
          </div>
          <div className="basis-1/2 px-4">
            <label className={label}>{RecipeSummaryData.Description}:</label>
            {/* <div className={subHeaderStyle}> */}
            <textarea
              rows={3}
              className={subHeaderStyle}
              value={recipeDesc}
              onChange={(e) => {
                setRecipeDesc(e.target.value);
              }}
            />
            {/* </div> */}
          </div>
        </div>
        {/* Scheduling */}
        <div className="mt-20 mb-2">
          <PageSubHeader title={RecipeSummaryData.Scheduling} />
          {/* Start date */}
          <div className={`${grid} mt-8`}>
            <label
              className={`${
                enabled ? "text-primary" : "text-[#B2B2B2]"
              } font-bold`}
            >
              {RecipeSummaryData.StartDate}:
            </label>
            <div
              className={`relative col-start-2 col-span-3 ${
                enabled ? "border-primary" : ""
              } ${scheduleDropdown}`}
              onClick={() => {
                if (enabled) setStartTimeOpen(!startTimeOpen);
              }}
            >
              <p className={`mx-4 my-2 ${enabled ? "text-primary" : ""}`}>
                {moment(selectedDate).format("dddd, MMMM DD, YYYY")}
              </p>
            </div>
            <div className="col-start-10 col-span-3 text-gray">
              <label className="inline-flex relative items-center mr-5 cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                  checked={enabled}
                  onChange={() => setEnabled(!enabled)}
                  disabled={recipe?.manual}
                />
                <div
                  className={`w-11 h-6 border-2 rounded-full peer peer-focus:ring-4 peer-focus:ring-white 
                  peer-checked:after:translate-x-full after:absolute after:top-1 after:left-[5px] 
                  peer-checked:bg-primary
                  ${enabled ? "after:bg-white" : "after:bg-primary"} 
                  ${
                    recipe?.manual
                      ? "border-[#B2B2B2] after:bg-[#B2B2B2]"
                      : "border-primary"
                  }
                   after:border-gray-300 after:rounded-full after:h-4 after:w-4 after:transition-all `}
                ></div>
                <span
                  className={`ml-3 text-sm font-medium 
                  ${!enabled ? "text-[#B2B2B2]" : "text-[#111827]"}`}
                >
                  Turn ON/OFF Scheduling
                </span>
              </label>
            </div>
            {startTimeOpen && enabled && (
              <Calendarrr
                onChange={(date) => {
                  setSelectedDate(date);
                  setStartTimeOpen(!startTimeOpen);
                }}
              />
            )}
          </div>
          {/* Start time */}
          <div className={grid}>
            <label
              className={`${
                enabled ? "text-primary" : "text-[#B2B2B2]"
              } font-bold`}
            >
              {RecipeSummaryData.StartTime}:
            </label>
            <div
              className={`col-start-2 col-span-2 ${
                enabled ? "border-primary" : ""
              } ${scheduleDropdown}`}
              onClick={() => {
                if (enabled) setTimeOpen(!timeOpen);
              }}
            >
              <p className={`mx-4 my-2 ${enabled ? "text-primary" : ""}`}>
                {selectedTime}
              </p>
            </div>
            {timeOpen && enabled && (
              <div>
                <TimePicker
                  onChange={(selected) => {
                    setSelectedTime(selected);
                    setTimeOpen(!timeOpen);
                  }}
                />
              </div>
            )}
          </div>
          {/* Repeat */}
          <div className={grid}>
            <label
              className={`${
                enabled ? "text-primary" : "text-[#B2B2B2]"
              } font-bold`}
            >
              {RecipeSummaryData.Repeat}:
            </label>
            <div
              className={`flex flex-row items-center col-start-2 col-span-2 min-w-fit ${
                enabled ? "border-primary" : ""
              } ${scheduleDropdown}`}
              onClick={() => {
                if (enabled) setRepeatOpen(!repeatOpen);
              }}
            >
              <p className={`ml-4 my-2 ${enabled ? "text-primary" : ""}`}>
                {selectedRepeat}
              </p>
            </div>
            {repeatOpen && enabled && (
              <div>
                <Repeat
                  setRepeatOpen={setRepeatOpen}
                  setSelectedRepeat={setSelectedRepeat}
                />
              </div>
            )}
          </div>
          {/* Timezone */}
          {/* <div className={grid}>
            <label
              className={`${
                enabled ? "text-primary" : "text-[#B2B2B2]"
              } font-bold`}
            >
              {RecipeSummaryData.Timezone}:
            </label>
            <div
              className={`col-start-2 col-span-2 ${
                enabled ? "border-primary" : ""
              } ${scheduleDropdown}`}
            >
              <p className={`mx-4 my-2 ${enabled ? "text-primary" : ""}`}>
                Select...
              </p>
            </div>
          </div> */}
          {selectedRepeat === "Custom" && enabled ? (
            <CustomTimeSelection
              customDateSelection={customDateSelection}
              setCustomDateSelection={setCustomDateSelection}
              customTimeSelection={customTimeSelection}
              setCustomTimeSelection={setCustomTimeSelection}
            />
          ) : null}
        </div>
        {/* Ingredients */}
        <div className="mt-10 mb-4 flex flex-row items-center justify-between">
          <PageSubHeader title={RecipeSummaryData.Ingredients} />
          {location?.state?.edit ? (
            <AddCircleIcon
              style={{ fontSize: "2rem", cursor: "pointer" }}
              onClick={() => {
                setFullRecipeDetails();
                navigate("/recipe_ingredients", { state: { id: recipeId } });
              }}
            />
          ) : null}
        </div>        
        <hr className="border-b border-primary opacity-40 mb-4 relative" />
        <div className="mt-8 mb-10 grid gap-y-4">
          {recipe?.order[0] === 'rt_tool' 
          ? <RTMain recipe={recipe?.ingredientId[0][recipe.order[0]]} fieldValues={fieldValues} setFieldValues={setFieldValues} is_mapped={recipe?.rt_tool_mapped} /> 
          : recipe?.order[0] === 'ad_ops' 
          ? <TrafickingSheetMain recipe={recipe} metadata={recipe?.ingredientId[0][recipe.order[0]]} fieldValues={fieldValues} setFieldValues={setFieldValues} />
          : recipe?.order[0] === 'cm_to_many'
          ? <CMToManyMain recipe={recipe} metadata={recipe?.ingredientId[0][recipe.order[0]]} fieldValues={fieldValues} setFieldValues={setFieldValues} /> 
          : recipe?.ingredientId.map((ingredient: any, index: any) => (
            <IngredientsBox
              key={index}
              fields={ingredient[recipe.order[index]]}
              setFieldValues={setFieldValues}
              fieldValues={_.cloneDeep(fieldValues)}
              index={index}
            />
          ))}
        </div>
        <div className={"flex justify-between"}>
          <div>
            <DafaultButton
              text={"Back"}
              outlined
              startIcon={<ArrowBackIcon />}
              radius={"6px"}
              onClick={() => {
                setFullRecipeDetails();
                navigate(-1);
              }}
            />
          </div>
          <div className={"flex items-center gap-4"}>
            <DafaultButton
              text={RecipeSummaryData.EnablePermission}
              color={"info"}
              textColor={"#FFFFFF"}
              width={"15rem"}
              radius={"6px"}
              onClick={() => setenablePermissionModal(true)}
              disabled={!(recipe?.permissionsReq?.length !== 0 && (location?.state?.edit))}
            />
            <DafaultButton
              text={RecipeSummaryData.Save}
              color={"info"}
              textColor={"#FFFFFF"}
              width={"7rem"}
              radius={"6px"}
              // disabled={edit}
              onClick={() => CreateRecipe()}
            />
            {/* <DafaultButton
              text={RecipeSummaryData.ActiveRecipe}
              endIcon={<ArrowForwardIcon />}
              color={"success"}
              textColor={"#FFFFFF"}
              width={"12rem"}
              radius={"6px"}
              onClick={() => {
                setLoading(true);
                CreateRecipe();
                dispatch(runRecipe(recipeId)).then(() => {
                  navigate("/recipes");
                  toastOnSave("Successfully ran recipe");
                  setLoading(false);
                });
              }}
              disabled={!(location?.state?.edit && recipe?.permissions_enabled)}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeSummary;
