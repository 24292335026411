import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import roleService from "./roleService";
// import { toast } from "react-toastify";

const initialState = {
  rolesList: [],
  role: {
    newRole: "",
    name: "",
    resources: {},
  },
};

export const createRole = createAsyncThunk(
  "roles/createRole",
  async (payload: any, thunkAPI) => {
    try {
      return await roleService.createRole(payload);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const updateRole = createAsyncThunk(
  "roles/updateRole",
  async ({ id, payload }: any, thunkAPI) => {
    try {
      return await roleService.updateRole(id, payload);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const resetRole = createAsyncThunk(
  "roles/resetRole",
  async (thunkAPI) => {
    try {
      return;
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const listRoles = createAsyncThunk("roles/list", async (thunkAPI) => {
  try {
    return await roleService.listRoles();
  } catch (error: any) {
    const message: any =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return message;
  }
});

export const deleteRole = createAsyncThunk(
  "roles/deleteRole",
  async (id: any, thunkAPI) => {
    try {
      return await roleService.deleteRole(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const getRole = createAsyncThunk(
  "roles/getRole",
  async (id: any, thunkAPI) => {
    try {
      return await roleService.getRole(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const setRoleDetail = createAsyncThunk(
  "roles/setRoleDetail",
  async (payload: any, thunkAPI) => {
    try {
      return payload;
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const setRoleResources = createAsyncThunk(
  "roles/setRoleResources",
  async (payload: any, thunkAPI) => {
    try {
      return payload;
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const setNewRoleName = createAsyncThunk(
  "roles/setNewRoleName",
  async (payload: any, thunkAPI) => {
    try {
      return payload;
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    reset: (state) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRole.fulfilled, (state, action) => {
        state.role = initialState.role;
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.role = initialState.role;
      })
      .addCase(resetRole.fulfilled, (state, action) => {
        state.role = initialState.role;
      })
      .addCase(listRoles.fulfilled, (state, action) => {
        state.rolesList = action.payload;
      })
      .addCase(setRoleDetail.fulfilled, (state, action) => {
        state.role.resources = action.payload;
      })
      .addCase(setRoleResources.fulfilled, (state, action) => {
        for (const tempRole in action.payload) {
          state.role.resources[tempRole] = action.payload[tempRole];
        }
      })
      .addCase(getRole.fulfilled, (state, action) => {
        state.role = action.payload;
      })
      .addCase(setNewRoleName.fulfilled, (state, action) => {
        state.role.newRole = action.payload;
      });
  },
});

export const { reset } = roleSlice.actions;
export default roleSlice.reducer;
