import React, { useCallback, useEffect, useState } from "react";
import {
  IngredientViewData,
  RecipeIngredientsData,
} from "components/Common/staticData";
import PageHeader from "components/PageHeader/PageHeader";
import SearchSubHeader from "components/Recipes/RecipeIngredients/SearchSubHeader";
import SummaryHeader from "components/Recipes/RecipeIngredients/SummaryHeader";
import SearchBar from "components/Common/SearchBar";
import { DafaultButton } from "components/Common/Button";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { listIngredients } from "features/ingredients/ingredientSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { Grid } from "@mui/material";
import AddedIngredients from "components/Recipes/RecipeIngredients/AddedIngredients";
import _ from "lodash";
import IngredientCard from "components/Common/IngredientCard";
import {  useLocation, useNavigate } from "react-router-dom";
import { viewIngredientFields } from "features/recipe/recipeSlice";
import setLoading from "features/helper";

const RecipeIngredients = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  let location: any = useLocation();
  const recipeId = location?.state?.id ? location?.state?.id : null;

  const ingredientList = useSelector(
    (state: any) => state?.ingredient?.ingredientList
  );
  const currentUser = useSelector((state: any) => state.auth.user);
  const recipe = useSelector((state: any) => state?.recipe?.recipe);

  const [filteredIngredients, setFilteredIngredients] =
    useState(ingredientList);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [selectedIngredientsIDs, setSelectedIngredientsIDs] = useState([]);

  const [typeFilter, setTypeFilter] = useState("All");
  const [platformFilter, setPlatformFilter] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [isNext, setIsNext] = useState(false);
  const [selectedIngredientType, setSelectedIngredientType] = useState(
    recipe.manual !== null ? (recipe.manual ? "Manual" : "Scheduled") : "All"
  );

  const onNext = () => {
    setIsNext(true);
    if (selectedIngredientsIDs.length > 0) {
      setLoading(true);
      dispatch(
        viewIngredientFields({ selectedIngredientsIDs, selectedIngredientType })
      ).then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'select_recipe_ingredient',
          "ingredient": selectedIngredientsIDs,
          'user_id': currentUser.userID,
          'tenant_id': currentUser.tenant
        });
        setLoading(false);
        navigate(`/recipe_summary`, {
          state: { id: recipeId, edit: recipeId ? true : false },
        });
      });
    }
  };

  const onIngredientTypeChange = (selected) => {
    setTypeFilter(selected.value);
    filteredArray(selected.value, platformFilter, searchFilter);
  };

  const onSearch = (value) => {
    setSearchFilter(value);
    filteredArray(typeFilter, platformFilter, value);
  };

  const onIngredientPlatformChange = (selected) => {
    let array = selected.map(item => item.value)
    setPlatformFilter(array);
    filteredArray(typeFilter, array, searchFilter);
  };

  const filteredArray = (type, platforms, search) => {
    let array = [...ingredientList];

    if (type === IngredientViewData.Manual) {
      array = array.filter((ingredient)=> ingredient.manual);
    } else if (type === IngredientViewData.Scheduled) {
      array = array.filter((ingredient)=> !ingredient.manual);
    }

    platforms.forEach((element) => {
      array = array.filter(function (ingredient) {
        return (
          ingredient.platform_from.includes(element) ||
          ingredient.platform_to.includes(element)
        );
      });
    });

    if (search !== "") {
      array = array.filter(function (ingredient) {
        return ingredient.title.toLowerCase().includes(search.toLowerCase());
      });
    }

    setFilteredIngredients(array);
  };

  const onIngredientSelect = (title, id, isManual) => {
    let array = [...selectedIngredients];
    let arrayIds = [...selectedIngredientsIDs];
    let index = array.indexOf(title);

    if (index === -1) {
      array.push(title);
      arrayIds.push(id);
      setSelectedIngredientType(isManual ? "Manual" : "Scheduled");
    } else {
      for (let i = 0; i < array.length; i++) {
        if (array[i] === title) {
          array.splice(i, 1);
          arrayIds.splice(i, 1);
          i--;
        }
      }
    }
    if (array.length === 0) setSelectedIngredientType("All");
    setSelectedIngredients(array);
    setSelectedIngredientsIDs(arrayIds);
  };

const removeIngredient = useCallback((index) => {
  let array = [...selectedIngredients];
  let arrayIds = [...selectedIngredientsIDs];
  array.splice(index, 1);
  arrayIds.splice(index, 1);
  if (array.length < 1) setSelectedIngredientType("All");
  setSelectedIngredients(array);
  setSelectedIngredientsIDs(arrayIds);
},[selectedIngredients,selectedIngredientsIDs, setSelectedIngredients, setSelectedIngredientsIDs])

  useEffect(() => {
    setLoading(true);
    dispatch(listIngredients()).then((res) => {
      setFilteredIngredients(res.payload)
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    let array = [];
    for (let index = 0; index < recipe.order.length; index++) {
      array.push(recipe.ingredientId[index][recipe.order[index]].title);
    }
    if(array.length > 0) {
      setSelectedIngredients(array);
      setSelectedIngredientsIDs(recipe.order);
    }    
  }, [recipe]);

  useEffect(() => {
    setFilteredIngredients(filteredIngredients);
  }, [ingredientList]);

  return (
    <div className="w-[82%] mb-[2.5%] m-0 float-right">
      <div className="mb-2 mr-10">
        <PageHeader title={RecipeIngredientsData.Ingredients} extraClassName="px-[2.5%]" >
        <div className="w-[71%]">
              <SearchSubHeader
                title={RecipeIngredientsData.IngredientLists}
                onIngredientTypeChange={onIngredientTypeChange}
                onIngredientPlatformChange={onIngredientPlatformChange}
              />
              <div className={"flex justify-end"}>
                <SearchBar margin={"mt-2"} width={"[22%]"} onSubmit={onSearch} />
              </div>
        </div>

        <div className="w-[22%]">
          <SummaryHeader title={RecipeIngredientsData.IngredientSummary} />
        </div>
        </PageHeader>


        <div className={"flex justify-between px-[2.5%]"}>
          {/* left side - Ingredient List */}
          <div className="w-[71%]">
            <Grid container spacing={3}>
              {filteredIngredients?.map((cardList, i) => (
                <Grid item xs={4} key={i}>
                  <IngredientCard
                    id={cardList?.id}
                    name={cardList?.title}
                    desc={cardList?.description}
                    manual={cardList?.manual}
                    platformsFrom={cardList?.platform_from}
                    platformsTo={cardList?.platform_to}
                    check
                    readmore
                    ready={cardList?.ready}
                    disabled={
                      ((!cardList?.manual &&
                        selectedIngredientType == "Manual") ||
                      (cardList?.manual &&
                        selectedIngredientType == "Scheduled"))
                      || (!cardList?.ready && currentUser.tenant !== '1')
                    }
                    counter={selectedIngredients.reduce(
                      (a, v) => (v === cardList?.title ? a + 1 : a),
                      0
                    )}
                    onIngredientSelect={() => {
                      onIngredientSelect(
                        cardList?.title,
                        cardList?.id,
                        cardList?.manual
                      );
                      setIsNext(false);
                    }}
                    onIngredientAdd={() => {
                      let array = [...selectedIngredients];
                      let arrayIds = [...selectedIngredientsIDs];
                      array.push(cardList?.title);
                      arrayIds.push(cardList?.id);

                      setSelectedIngredients(array);
                      setSelectedIngredientsIDs(arrayIds);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </div>

          {/* right side - Ingredient Summary */}
          <div className="w-[22%]">
          <div className="sticky top-[17rem]">
              <AddedIngredients
                selected={selectedIngredients}
                removeIngredient={removeIngredient}
              />
              {isNext && selectedIngredientsIDs.length === 0 ? (
                <span className="flex items-center font-medium tracking-wide text-red text-base mb-7 ml-1">
                  Please select at least 1 Ingredient
                </span>
              ) : null}
              <div className={"flex justify-between"}>
                <DafaultButton
                  startIcon={<ArrowBack />}
                  text={IngredientViewData.Back}
                  color={"primary"}
                  outlined
                  onClick={() => navigate(-1)}
                />
                <DafaultButton
                  endIcon={<ArrowForward />}
                  text={IngredientViewData.NextStep}
                  color={IngredientViewData.success}
                  textColor={"#FFF"}
                  onClick={() => onNext()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeIngredients;
