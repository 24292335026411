import React from "react";

export const ArrowDown = ({ onClick }: any) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L7 7L13 1"
          stroke="#40506A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const Checkbox = ({ onClick }: any) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="2" width="20" height="20" rx="3" fill="white" />
        <rect
          x="3"
          y="3"
          width="18"
          height="18"
          rx="2"
          stroke="#667080"
          strokeOpacity="0.3"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};

export const CheckboxChecked = ({ onClick }: any) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1710_12426)">
          <rect x="2" y="2" width="20" height="20" rx="3" fill="#40506A" />
          <path
            d="M10.5001 14.3791L17.3941 7.48438L18.4553 8.54488L10.5001 16.5001L5.72705 11.7271L6.78755 10.6666L10.5001 14.3791Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_1710_12426">
            <rect x="2" y="2" width="20" height="20" rx="3" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
