import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";

const SearchBar = ({ width, onSubmit, margin }: any) => {
  const [value, setValue] = useState("");

  const onChange = (event) => {
    setValue(event.target.value);
    onSubmit(event.target.value);
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      onSubmit(e.target.value);
    }
  };

  return (
    <div
      className={`flex items-center justify-between text-primary text-sm text-primary-900 rounded-3xl border relative w-${width} ${margin}`}
      id={"Search box"}
    >
      <div className={`flex items-center text-primary`}>
        <input
          type="text"
          className={`text-base text-primary-900 bg-none border-none rounded-3xl my-1 mx-3.5 p-0 focus:ring-0 w-9/12`}
          placeholder="Search"
          onChange={onChange}
          onKeyDown={keyPress}
        />
        <SearchIcon
          onClick={() => onSubmit(value)}
          className="mx-3 my-1.5 cursor-pointer absolute right-0"
        />
      </div>
    </div>
  );
};

export default SearchBar;
