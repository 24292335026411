export const filterList = {
  Status: [
    { value: "ERROR", label: "ERROR" },
    { value: "FINISHED", label: "FINISHED" },
    // { value: "MANUAL", label: "MANUAL" },
    { value: "STOPPED", label: "STOPPED" },
    { value: "RUNNING", label: "RUNNING" },
    { value: "QUEUED", label: "QUEUED" },
  ],
  SortBy: [
    { value: "Author", label: "Author" },
    { value: "Status", label: "Status" },
    { value: "Date Created", label: "Date Created" },
    { value: "Last Run", label: "Last Run" },
  ],
  ByIngredients: [
    { value: "All", label: "All" },
    { value: "Scheduled", label: "Scheduled" },
    { value: "Manual", label: "Manual" },
  ],
  ByDestination: [
    { value: "CM360", label: "CM360" },
    { value: "DV360", label: "DV360" },
    { value: "GoogleAds", label: "GoogleAds" },
    { value: "GA360", label: "GA360" },
    { value: "BigQuery", label: "BigQuery" },
    { value: "DataStudio", label: "DataStudio" },
    { value: "Spreadsheet", label: "Spreadsheet" },
    { value: "SA360", label: "SA360" },
    { value: "Drive", label: "Drive" },
    { value: "Twitter", label: "Twitter" },
    { value: "Storage", label: "Storage Bucket" },
    { value: "Salesforce", label: "Salesforce" },
    { value: "GoogleAPI", label: "GoogleAPI" },
  ],
  ByType: [
    { value: "", label: "" },
    { value: "", label: "" },
    { value: "", label: "" },
  ],
};

export const enum LeftNavItems {
  UserMangement = "User Management",
  Ingredients = "Ingredients",
  Dashboard = "Dashboard",
  Recipes = "Recipes",
  Roles = "Roles",
  Groups = "Groups",
  Users = "Users",
  ServiceAccounts = "Service Accounts",
  Billings = "Billings",
}

export const enum IngredientViewData {
  Description = "Description",
  Instructions = "Instructions",
  Details = "Details",
  OpenSource = "Open Source",
  Age = "Age",
  ScheduleDays = "Schedule Days",
  Back = "Back",
  ScheduledRecipe = "Scheduled recipe",
  ManualRecipe = "Manual recipe",
  yes = "yes",
  ConfiguredByUser = "Configured by user",
  Manual = "Manual",
  Scheduled = "Scheduled",
  NextStep = "Next step",
  success = "success",
}

export const ingredientPlatforms = {
  bigquery: "BigQuery",
  cm: "CM360",
  cm360: "CM360",
  storage: "Storage Bucket", //
  salesforce: "Salesforce",
  datastudio: "DataStudio",
  sa360: "SA360",
  youtube: "YouTube", //
  sheets: "Spreadsheet",
  GA360: "GA360",
  drive: "Drive",
  "google ads": "GoogleAds",
  "Google Ads": "GoogleAds",
  "airflow/composer": "Airflow Composer",
  google_api: "GoogleAPI",
  twitter: "Twitter",
  dv360: "DV360",
  "sheets, GA360": "GA360",
  smartsheet: "SmartSheet",
  gmail: "Gmail",
  video: "Video", //
  calendar: "Calendar", //
  weather: "Weather", //
  fred: "Fred", //
  plx: "PLX", //
  internet: "Internet", //
  vision_api: "Vision API",
  "apps script": "AppsScript", //
  dynamite: "Dynamite", //
  python: "Python",
  census: "Census", //
  email: "Email",
  iam: "IAM",
};

export const enum RecipeIngredientsData {
  Ingredients = "Ingredients",
  IngredientLists = "Ingredient List",
  IngredientSummary = "Ingredient Summary",
  ByCategory = "By category",
  ByDestination = "By destination",
  ByIngredients = "By ingredients type",
  ByPlatform = "By platform",
  Manual = "Manual",
  Scheduled = "Scheduled",
  All = "All",
  SelectAll = "Select All",
}

export const RecipeSummaryData = {
  RecipeSummary: "Recipe Summary",
  Name: "Name",
  Description: "Description",
  Groups: "Groups",
  ServiceAccount: "Service Account",
  GroupsValue: [
    { value: "Marketing", label: "Marketing" },
    { value: "Accounting", label: "Accounting" },
    { value: "Finance", label: "Finance" },
  ],
  Scheduling: "Scheduling",
  StartDate: "Start date",
  StartTime: "Start time",
  Repeat: "Repeat",
  Timezone: "Timezone",
  Ingredients: "Ingredients",
  TurnONOFFScheduling: "Turn ON/OFF scheduling",
  EnablePermission: "Enable permissions",
  Save: "Save",
  ActiveRecipe: "Run Recipe",
  Hourly: "Hourly",
  Daily: "Daily",
  Weekly: "Weekly",
  Monthly: "Monthly",
  Annually: "Annually",
  Custom: "Custom",
  RepeatOn: "Repeat on",
  Ends: "Ends",
  Item: "Item",
  DatasetName: "Dataset name",
  AccountID: "Account ID",
  WebPropertyID: "Web Property ID",
  CredientialsUsedForReadingData: "Crediential used for reading data",
  User: "User",
  Service: "Service",
  ErrorMsg: "Error: Enter a valid dataset name",
  InputType: {
    text: "text",
    email: "email",
    number: "number",
    password: "password",
  },
};

export const Weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const DayFirstHours = [1, 2, 3, 4, 5, 6];
export const DaySecondHours = [7, 8, 9, 10, 11, 12];

export const allHours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

export const viewerRole = { View: ["Users", "Groups"] };

export const managerRole = {
  View: ["Users", "Groups", "Organization"],
  Create: ["Create", "Edit", "Duplicate"],
  "Run ad hoc recipes": ["Users own recipes"],
  "Activate scheduled recipes": ["Users own recipes"],
  "Delete Recipes": ["Users own recipes"],
  "Deactive scheduled recipes": ["Users own recipes"],
};

export const adminRole = {
  Organization: ["Add users", "Edit users", "Delete users"],
  "User Groups": ["Add users", "Edit users", "Delete users"],
  Groups: ["Add groups", "Edit groups", "Delete groups"],
  View: ["Users", "Groups", "Organization"],
  Create: ["Create", "Edit", "Duplicate"],

  "Run ad hoc recipes": [
    "Users own recipes",
    "Groups recipes",
    "Organization recipes",
  ],

  "Activate scheduled recipes": [
    "Users own recipes",
    "Groups recipes",
    "Organization recipes",
  ],

  "Delete Recipes": [
    "Users own recipes",
    "Groups recipes",
    "Organization recipes",
  ],

  "Deactive scheduled recipes": [
    "Users own recipes",
    "Groups recipes",
    "Organization recipes",
  ],
};
