import ApiOperations from "features/ApiOperations";
import config from "components/Common/config";
import setLoading from "features/helper";

export const loadDvElements = async (advertiser, setDvLIOptions, currentUser) => {
  setLoading(true);
  let final_advertiser = advertiser.split(" : ")[0];
  // advertisers.map((element: any) => final_advertisers.push(element.split(' : ')[0]));

  await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/users/get_dv_LI/${final_advertiser}/${currentUser}`,
    (status, data) => {
      setDvLIOptions(data);
      setLoading(false);
    }
  );
};

export const loadDvLIElements = async (
  advertiser,
  setDvLIOptions,
  tempSelectedDVElements,
  setSelectedDvLIOptions,
  currentUser
) => {
  setLoading(true);
  let final_advertiser = advertiser.split(" : ")[0];

  await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/users/get_dv_LI/${final_advertiser}/${currentUser}`,
    (status, data) => {
      let finalArray = [];
      setDvLIOptions(data);
      tempSelectedDVElements.forEach((element) => {
        finalArray.push(data.find((str) => str.includes(element)));
      });
      // setSelectedDvLIOptions(finalArray);
      setLoading(false);
    }
  );
};

export const loadCmCampaigns = async (
  userProfile,
  setCampaignOptions,
  setCampaignMetadata,
  currentUser
) => {
  setLoading(true);
  await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/users/get_campaign/${userProfile}/${currentUser}`,
    (status, data) => {
      let campaignArray = [];
      let campaignLandingPage = {};
      data?.campaigns?.map((campaign: any) => {
        campaignLandingPage[campaign["id"]] = {
          id: campaign["id"],
          name: campaign["name"],
          defaultLandingPageId: campaign["defaultLandingPageId"],
          advertiserId: campaign["advertiserId"],
          startDate: campaign["startDate"],
          endDate: campaign["endDate"],
        };
        campaignArray.push(campaign["id"] + " : " + campaign["name"]);
      });
      setCampaignOptions(campaignArray);
      setCampaignMetadata(campaignLandingPage);
      setLoading(false);
    }
  );
};

export const loadCmSites = async (userProfile, setSites, currentUser) => {
  setLoading(true);
  await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/users/get_cm_sites/${userProfile}/${currentUser}`,
    (status, data) => {
      let siteArray = [];
      data?.sites?.map((site: any) => {
        siteArray.push(site["id"] + " : " + site["name"]);
      });
      setSites(siteArray);
      setLoading(false);
    }
  );
};

export const loadDvAdvertisers = async (partnerID, setDvAdvertiserOptions, currentUser) => {
  setLoading(true);
  await ApiOperations.get(
    `${config.apiGateway.tenantURL}/api/v1/users/users/get_dv_advertisers/${partnerID}/${currentUser}`,
    (status, data) => {
      let advertiserArray = [];
      data?.advertisers?.map((advertiser: any) => {
        advertiserArray.push(advertiser["advertiserId"] + " : " + advertiser["displayName"]);
      });
      setDvAdvertiserOptions(advertiserArray);
      setLoading(false);
    }
  );
};

export const uploadCmCreatives = async (
  filePayload,
  metadataPayload,
  setUploadedCreatives,
  currentUser
) => {
  setLoading(true);
  const json = JSON.stringify(metadataPayload);
  let filesToUpload: any = [];

  const payload = new FormData();
  payload.append("metadata", json);

  filePayload.forEach((element: any) => {
    payload.append("files", element["file"]);

    if ("backupImageFile" in element) {
      payload.append("files", element["backupImageFile"]);
    }
  });

  const response = await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/users/upload_cm_creatives/${currentUser}`,
    payload,
    (status, data) => {
      let tempData = [];
      data?.map((element: any) => {
        tempData.push(element.data);
      });
      setUploadedCreatives(tempData);
      // console.log(tempData);
      setLoading(false);
    }
  );
};

export const generateRTMappingSheet = async (
  currentUser,
  uploadedCreatives,
  selectedDvLIOptions
) => {
  setLoading(true);
  let finalUploadedCreatives = [];
  let finalSelectedDvLIOptions = [];

  uploadedCreatives.forEach((element) => {
    finalUploadedCreatives.push(element.name)
  })

  selectedDvLIOptions.forEach((element) => {
    finalSelectedDvLIOptions.push(element.split(" : ")[1])
  })

  let payload = { uploadedCreatives: finalUploadedCreatives, selectedDvLIOptions: finalSelectedDvLIOptions };
  const response = await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/users/generateMappingSheet/${currentUser}`,
    payload,
    (status, data) => {
      return data;
    }
  );
  setLoading(false);
  return response;
};
